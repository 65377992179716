
const HipDesignTable = {
    header: [
        { name: "Company Name", key: "companyName" },
        { name: "Type", key: "type" },
        { name: "Model Name", key: "modelName" },
       
        { name: "Edit", key: "edit"},
     
        { name: "Delete", key: "delete"},

    ],
    data: [
    ],
};
export default  HipDesignTable;











