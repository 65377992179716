<div *ngIf="formParam.errorMessage" class="alert alert-danger">
    {{formParam.errorMessage}}
</div>
<form *ngIf="formParam" class="example-form main-form-component" [ngClass]="formParam.class">
    <div class="row">
        <ng-container *ngIf="formParam && formParam.fields">
            <ng-container *ngFor="let field of formParam.fields">
                <!-- {{field.isDisplay ? field.name : ""}} -->
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.AUTOCOMPLETE && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-autocomplete #fieldautocompleteComponent (onClickPostFix)="onClickPostFix($event)" (onEnterChange)="onEnterChange($event)"
                            (onValueChange)="onValueChange($event)" [field]="field"></app-autocomplete>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.BUTTON && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-button #fieldbuttonComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-button>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.CHECKBOX && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-checkbox #fieldcheckboxComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-checkbox>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.DATEPICKER && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-datepicker #fielddatepickerComponent (onValueChange)="onValueChange($event)"
                            [field]="field">
                        </app-datepicker>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.TIMEPICKER && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-timepicker #fieldtimepickerComponent (onValueChange)="onValueChange($event)"
                            [field]="field">
                        </app-timepicker>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.TIMEPICKER_DURATION && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-timepickerduration #fieldtimepickerdurationComponent
                            (onValueChange)="onValueChange($event)" [field]="field">
                        </app-timepickerduration>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.EMAIL && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-email #fieldemailComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-email>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.FILE && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-file #fieldfileComponent (onFileChange)="onFileChange($event)" [field]="field"></app-file>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.NOTE && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-note (onClickEvent)="onClickEvent($event)" #fieldnoteComponent
                            (onValueChange)="onValueChange($event)" [field]="field"></app-note>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.NUMBER && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-number #fieldnumberComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-number>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.PASSWORD && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-password #fieldpasswordComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-password>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.RADIO && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-radio #fieldradioComponent (onValueChange)="onValueChange($event)" [formParam]="formParam" [field]="field">
                        </app-radio>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.SELECT && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-select #fieldselectComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.TEXT && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-text #fieldtextComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-text>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.SELECT_TEXT && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-name #fieldnameComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-name>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.GRID && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-grid-box #fieldgridboxComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-grid-box>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.TEXTAREA && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-textarea #fieldtextareaComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-textarea>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.SPECIAL_OPD_CHARGE && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-special-opd-charge #fieldsocComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-special-opd-charge>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.PAYMENT_BOX_WITH_AMOUNT && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-payment-box-with-amount #fieldpbwaComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-payment-box-with-amount>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.BIRTHDATE && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-birthdate #fieldbirthdateComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-birthdate>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.IDPROOF && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-idproof #fieldidproofComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-idproof>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.ADDRESS && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-address #fieldaddressComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-address>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.MOBILE && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-mobile #fieldmobileComponent (onValueChange)="onValueChange($event)" [field]="field">
                        </app-mobile>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.NOOFPERSON && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-noofperson #fieldnoofpersonComponent (onValueChange)="onValueChange($event)"
                            [field]="field"></app-noofperson>
                    </div>
                </ng-container>
                <ng-container *ngIf="field.type == _shared._config.FORM.FIELD.DATEANDTIME && field.isDisplay">
                    <div [ngClass]="formParam.fieldClass.mainDivClass">
                        <app-dateandtime #fielddateandtimeComponent (onValueChange)="onValueChange($event)"
                            [field]="field"></app-dateandtime>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="formParam.isSubmitButton">
        <div>
            <app-button #submitButton (onButtonClick)="onFormButton($event)" (onButtonSubmit)="onFormSubmit($event)"
                [field]="formParam.submitButton"></app-button>
        </div>
    </ng-container>

</form>