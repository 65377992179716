import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment} from 'moment';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DatepickerComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  isDisplayValidation = false;

  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {

    this.field.value = (typeof this.field.value != "undefined" ? ( this.field.value ? moment(new Date(this.field.value)) : "" ) : moment());
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name,
      new FormControl( this.field.value )
    );
    if(this.field.validation && this.field.validation.required) {
      this.form.controls[this.field.name].setValidators(Validators.required);
    }
    // console.log(this.field.value);
    setTimeout(() => {
      // this.field.value = new Date();
      this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name], isInit: true});
    })
  }

  isRequired() {
    return (this.field.validation && this.field.validation.required);
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.field.value = new Date(`${event.value}`);
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
  }

  setFormFieldValue(name, value) {
    value = moment(value);
    this.field.value = value;
    this.form.controls[this.field.name].setValue(value);
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name], isInit: false});
  }

  isFieldValid() {
    this.isDisplayValidation = true;
    if(this.form.controls[this.field.name].errors) {
      return {
        [this.field.name]: this.form.controls[this.field.name].errors
      };
    }
    return null;
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  
  get _form () {
    return this.form.controls;
  }

}
