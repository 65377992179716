import { configuration } from 'src/app/configuration';

const FormKneePrimaryKujalaScore = {
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '1. Limp',
      name: 'limp',
      options: {
        data: [
          {
            op_key: "5",
            op_value: 'None (5)',
          },
          {
            op_key: "3",
            op_value: 'Slight or periodical (3)',
          },
          {
            op_key: "0",
            op_value: 'Constant (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '2. Support',
      name: 'support',
      options: {
        data: [
          {
            op_key: "5",
            op_value: 'Full support without pain (5)',
          },
          {
            op_key: "3",
            op_value: 'Painful (3)',
          },
          {
            op_key: "0",
            op_value: 'Weight bearing impossible (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '3. Walking',
      name: 'walking',
      options: {
        data: [
          {
            op_key: "5",
            op_value: 'Unlimited (5)',
          },
          {
            op_key: "3",
            op_value: 'More than 2 km (3)',
          },
          {
            op_key: "2",
            op_value: '1-2 km (2)',
          },
          {
            op_key: "0",
            op_value: 'Unable(0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '4. Stairs',
      name: 'stairs',
      options: {
        data: [
          {
            op_key: "10",
            op_value: 'No difficulty (10)',
          },
          {
            op_key: "8",
            op_value: 'Slight pain when descending (8)',
          },
          {
            op_key: "5",
            op_value: 'Pain both when descending and ascending (5)',
          },
          {
            op_key: "0",
            op_value: 'Unable(0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '5. Squatting',
      name: 'squatting',
      options: {
        data: [
          {
            op_key: "5",
            op_value: 'No difficulty (5)',
          },
          {
            op_key: "4",
            op_value: 'Repeated squatting painful (4)',
          },
          {
            op_key: "3",
            op_value: 'Painful each time (3)',
          },
          {
            op_key: "2",
            op_value: 'Possible with partial weight bearing (2)',
          },
          {
            op_key: "0",
            op_value: 'Unable (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '6. Running',
      name: 'running',
      options: {
        data: [
          {
            op_key: "10",
            op_value: 'No difficulty (10)',
          },
          {
            op_key: "8",
            op_value: 'Pain after more than 2 km (8)',
          },
          {
            op_key: "6",
            op_value: 'Slight pain from start (6)',
          },
          {
            op_key: "3",
            op_value: 'Severe pain (3)',
          },
          {
            op_key: "0",
            op_value: 'Unable (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '7. Jumping',
      name: 'jumping',
      options: {
        data: [
          {
            op_key: "10",
            op_value: 'No difficulty (10)',
          },
          {
            op_key: "7",
            op_value: 'Slight difficulty (7)',
          },
          {
            op_key: "2",
            op_value: 'Constant pain (2)',
          },

          {
            op_key: "0",
            op_value: 'Unable (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '8. Prolonged sitting with the knees flexed',
      name: 'prolonged_sitting_with_the_knees_flexed',
      options: {
        data: [
          {
            op_key: "10",
            op_value: 'No difficulty (10)',
          },
          {
            op_key: "8",
            op_value: 'Pain after exercise (8)',
          },
          {
            op_key: "6",
            op_value: 'Constant pain (6)',
          },
          {
            op_key: "4",
            op_value: 'Pain forces to extend knees temporarily (4)',
          },
          {
            op_key: "0",
            op_value: 'Unable (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '9. Pain',
      name: 'pain',
      options: {
        data: [
          {
            op_key: "10",
            op_value: 'None (10)',
          },
          {
            op_key: "8",
            op_value: 'Slight and occasional (8)',
          },
          {
            op_key: "6",
            op_value: 'Interferes with sleep (6)',
          },
          {
            op_key: "3",
            op_value: 'Occasionally severe (3)',
          },
          {
            op_key: "0",
            op_value: 'Constant and severe (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '10. Swelling',
      name: 'swelling',
      options: {
        data: [
          {
            op_key: "10",
            op_value: 'None (10)',
          },
          {
            op_key: "8",
            op_value: 'After severe exertion (8)',
          },
          {
            op_key: "6",
            op_value: 'After daily activities (6)',
          },
          {
            op_key: "4",
            op_value: 'Every evening (4)',
          },
          {
            op_key: "0",
            op_value: 'Constant (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '11. Abnormal painful kneecap (patellar) movements (subluxations)',
      name: 'abnormal_painful_kneecap_movements_',
      options: {
        data: [
          {
            op_key: "10",
            op_value: 'None (10)',
          },
          {
            op_key: "6",
            op_value: 'Occasionally in sports activities (6)',
          },
          {
            op_key: "4",
            op_value: 'Occasionally in daily activities (4)',
          },
          {
            op_key: "2",
            op_value: 'At least one documented dislocation (2)',
          },
          {
            op_key: "0",
            op_value: 'More than two dislocations (0)',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '12. Atrophy of thigh',
      name: 'atrophy_of_thigh',
      options: {
        data: configuration.POSITION
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '13. Flexion deficiency',
      name: 'flexion_deficiency',
      options: {
        data: configuration.POSITION
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: '14. Total Kujala Score',
      name: 'total_Kujala_Score',
    },
  ],
  // isSubmitButton: true,
  // isDisplayInlineError: true,
  // submitButton: {
  //   type: configuration.FORM.FIELD.BUTTON,
  //   label: 'Submit',
  //   name: 'submit',
  //   attr: {
  //     type: 'submit',
  //     color: configuration.FORM.BUTTON.COLOR.PRIMARY,
  //   },
  //   buttons: [],
  // },
};
export default FormKneePrimaryKujalaScore;

