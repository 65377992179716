import { Injectable } from '@angular/core';
import { TableRowFunctionItem } from './table-row-function-item';
import { TableRowFunctionLinkComponent } from './table-row-function-link/table-row-function-link.component';
import { TableRowFunctionPrintComponent } from './table-row-function-print/table-row-function-print.component';

@Injectable({
  providedIn: 'root'
})
export class TableRowFunctionService {

  components = {
    print: TableRowFunctionPrintComponent,
    link: TableRowFunctionLinkComponent,
  };
  
  constructor() { }

  getComponent(key) {
    if(this.components[key]) {
      return new TableRowFunctionItem(this.components[key], {});
    }
  }
}
