import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormNewPatient from '../helpers/forms/new-patient-form';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-knee-primary',
  templateUrl: './knee-primary.component.html',
  styleUrls: ['./knee-primary.component.css']
})
export class KneePrimaryComponent implements OnInit {

  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  _formParam = JSON.parse(JSON.stringify(FormNewPatient));
  url = "";

  labels = {
    "/knee-primary": { name: "Knee Primary Patient", patient_type: 0, patient_sub_type: 0 },
    "/knee-revision": { name: "Knee Revision Patient", patient_type: 0, patient_sub_type: 1 },
    "/hip-primary": { name: "Hip Primary Patient", patient_type: 1, patient_sub_type: 0 },
    "/hip-revision": { name: "Hip Revision Patient", patient_type: 1, patient_sub_type: 1 },
  };

  hospital_id = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.HOSPITAL_ID, null);

  constructor(private _apiService: ApiService, private router: Router, public _shared: SharedService) {
    this.url = this.router.url;
    this._shared.currentPage = "knee";
    this.getStudyId();
  }

  ngOnInit(): void {
    if (this.url == "/hip-primary" || this.url == "/hip-revision") {
      this._shared.currentPage = "hip";
    }
  }

  submitForm(type) {
    setTimeout(async () => {
      const formComponent: any = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        let errors = formComponent[0].runValidation();
        if (errors.length == 0) {
          console.log("formComponent", formComponent[0]);
          let formValues = formComponent[0].getFormValue();
          if (formValues.form.value) {
            let values = JSON.parse(JSON.stringify(formValues.form.value));
            values.surgery_side = type;
            values.patient_type = this.labels[this.url].patient_type;
            values.patient_sub_type = this.labels[this.url].patient_sub_type;
            let nullColumns = ["dob", "age", "height", "weight"]
            for (let n of nullColumns) {
              if (n == 'dob') {
                values.dob = moment(formValues.form.value.dob).format("YYYY-MM-DD")
              }
              if (!values[n]) {
                delete values[n];
              }
            }
            values.add_hospital_id = this.hospital_id;
            values.is_active = 1
            values.date = moment(new Date()).format("YYYY-MM-DD")
            console.log("values", values)
            let res = await this._apiService.COMMON_API_insertSingleRecord(configuration.TABLE.PATIENT, values);
            if (res && res.data) {
              if (this._shared.currentPage = 'knee') {
                window.location.href = "/knee-preop-clinical/" + res.data.id;
              }
              if (this._shared.currentPage = 'hip') {
                window.location.href = '/hip-preop-clinical/' + res.data.id
              }
            }
          }
          console.log(formValues.form.value);
        }
      }
    });
  }

  async getStudyId() {
    let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.GET_STUDY_ID, {});
    if (res && res.data) {
      const formComponent: any = this.formComponent.toArray();
      console.log("formComponent", formComponent[0]);
      let formValues = formComponent[0].getFormValue();
      formComponent[0].setFormValue("study_id", res.StudyId)
      console.log(formValues.form.value);
      console.log(formComponent)
    }
    console.log(res)
  }
}




