
const TableDashboard = {
    header: [
        { name: "Date of surgery", key: "date", function: "systemToUserDate" },
        { name: "Patient Name", key: "first_name", function: "getPatientName" },
        { name: "Surgery", key: "surgery", function: "getPatientType" },
        { name: "Side", key: "side", function: "getSurgerySide" },
        { name: "Demo graphy", key: "demography", function: "getSubFormLink", class: "text-center" },
        { name: "Preoop", key: "preop", function: "getSubFormLink", class: "text-center" },
        { name: "Radiology", key: "radiology", function: "getSubFormLink", class: "text-center" },
        { name: "Intraop", key: "intraop", function: "getSubFormLink", class: "text-center" },
        { name: "Postop", key: "postop", function: "getSubFormLink", class: "text-center" },
        { name: "Postop scores", key: "postopscore", function: "getSubFormLink", class: "text-center" },
        { name: "Status", key: "status" },
    ],
    data: [
    ],
};
export default TableDashboard;











