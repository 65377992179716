import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { configuration } from 'src/app/configuration';
import FormHospital from 'src/app/helpers/forms/hospital';
import { ApiService } from 'src/app/service/api.service';
import { SharedService } from 'src/app/service/shared.service';
import { FormComponent } from '../../common/form/form.component';

@Component({
  selector: 'app-hospital-formfill',
  templateUrl: './hospital-formfill.component.html',
  styleUrls: ['./hospital-formfill.component.css']
})
export class HospitalFormfillComponent implements OnInit {
  _formHospital = FormHospital;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  id = "";
  currentData = null;

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    console.log(this.id)
  }

  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    if (this.id) {
      let res = await this.apiService.COMMON_API_getSingleRecord(configuration.TABLE.HOSPITAL, this.id);
      console.log(res)
      if (res && res.data && res.data) {
        this.currentData = res.data;
        setTimeout(async () => {
          let formComponent = this.formComponent.toArray();
          if (formComponent && formComponent[0]) {
            formComponent[0].setAllFormFieldValue(this.currentData);
          }
        });
      }
    }
  }

  async onSubmit() {
    // console.log(this.formComponent)
    let formComponent = this.formComponent.toArray();
    // console.log(formComponent)
    if (formComponent && formComponent[0]) {
      let values = formComponent[0].getFormValue();
      values = JSON.parse(JSON.stringify(values.form.value));
      // console.log(values)
      this.shared.isLoader = true;
      if (this.currentData) {
        await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.HOSPITAL, values, this.id);
      } else {
        await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.HOSPITAL, values);
      }
      this.shared.isLoader = false;
      window.location.href = "/hospital-management";
    }
  }

}
