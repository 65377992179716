<form [formGroup]="form">
    <div class="form-group row">
        <div [ngClass]="field.class.labelDivClass">
            <label *ngIf="mField.isDisplayLabel">{{mField.label}}</label>
        </div>
        <div [ngClass]="field.class.inputDivClass">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text mobile-input-group-text">
                        <input type="text" class="form-control" [value]="ccField.value" [formControlName]="ccField.name" [placeholder]="ccField.placeholder" />
                    </div>
                </div>
                <input type="text" class="form-control" [value]="mField.value" [formControlName]="mField.name" [placeholder]="mField.placeholder" />
                <i class="form-group__bar"></i>
            </div>
        </div>    
    </div>
</form>

