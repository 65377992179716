import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { TextComponent } from '../text/text.component';

@Component({
  selector: "app-noofperson",
  templateUrl: "./noofperson.component.html",
  styleUrls: ["./noofperson.component.css"],
})
export class NoofpersonComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  field1 = null;
  field2 = null;
  field3 = null;
  noofperson = 0;
  noofmale = 0;
  nooffemale = 0;
  @ViewChildren(TextComponent) fieldtextpComponent: QueryList<TextComponent>;
  @ViewChildren(TextComponent) fieldtextmComponent: QueryList<TextComponent>;
  @ViewChildren(TextComponent) fieldtextfComponent: QueryList<TextComponent>;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.field1 = JSON.parse(JSON.stringify(this.field));
    this.field2 = JSON.parse(JSON.stringify(this.field));
    this.field3 = JSON.parse(JSON.stringify(this.field));

    this.field1.isDisplayLabel = false;
    this.field2.isDisplayLabel = false;
    this.field3.isDisplayLabel = false;

    this.field1.name = this.field1.name+"_p";
    this.field2.name = this.field2.name+"_m";
    this.field3.name = this.field3.name+"_f";

    this.field1.class = {
      inputDivClass: "col-md-12",
    };
    this.field2.class = {
      inputDivClass: "col-md-12",
    };
    this.field3.class = {
      inputDivClass: "col-md-12",
    };
    this.field1.prefix = {
      class: "fa fa-user",
    };
    this.field2.prefix = {
      class: "fa fa-male",
    };
    this.field3.prefix = {
      class: "fa fa-female",
    };
    this.field2.placeholder = "No. of Male";
    this.field3.placeholder = "No. of Female";

    this.field3.isDisabled = true;
    this.form = this.formBuilder.group({});
    // this.form.addControl(this.field.name, new FormControl(this.field.value));
    // this.form.controls[this.field.name].valueChanges.subscribe((val) => {
    //   this.field.value = val;
    //   this.onValueChange.emit({
    //     field: this.field,
    //     form: this.form,
    //     control: this.form.controls[this.field.name],
    //   });
    // });
  }

  onValueChangeP(e) {
    this.noofperson = parseInt(e.field.value);
    if(isNaN(this.noofperson)) {
      this.noofperson = 0;
    }
    this.setFemaleValue();
  }

  onValueChangeM(e) {
    this.noofmale = parseInt(e.field.value);
    if(isNaN(this.noofmale)) {
      this.noofmale = 0;
    }
    if(this.noofmale > this.noofperson) {
      this.noofmale = this.noofperson;
      console.log("this.noofperson", this.noofperson);
      const formComponent:any = this.fieldtextmComponent.toArray();
      formComponent[1].setFormFieldValue(this.field.name + "_m", this.noofperson);
    }
    this.setFemaleValue();
  }

  setFemaleValue() {
    const formComponent:any = this.fieldtextfComponent.toArray();
    this.nooffemale = this.noofperson - this.noofmale;
    this.nooffemale = this.nooffemale < 0 ? 0 : this.nooffemale;
    formComponent[2].setFormFieldValue(this.field.name + "_f", this.nooffemale);
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
}
