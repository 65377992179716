<div class="row">
    <div class="col-8">
        <h1>Change Password</h1>
        <div class="alert alert-danger" *ngIf="errorMessage != ''">
            {{errorMessage}}
        </div>
        <div class="alert alert-success" *ngIf="successMessage != ''">
            {{successMessage}}
        </div>        
        <app-form [formParam]="_changePassword" #formComponent (onSubmit)="submitForm($event)"></app-form>
    </div>
</div>
