import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormLogin from '../helpers/forms/login';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  _formlogin = FormLogin;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  constructor(public shared: SharedService, public _apiService: ApiService) { 
    const token = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_TOKEN, null);
    if (token) {
      window.location.href = "/dashboard";
    }
  }

  ngOnInit(): void {
  }

  async onSubmit(e) {
    console.log(e.form.value);
    this.shared.isLoader = true;

    let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.LOGIN, e.form.value);
    if(res.error) {
      this.shared.isLoader = false;
      this.shared.displayErrorMessage({
        msg: res.message
      })
      const formComponent: any = this.formComponent.toArray();
      if(formComponent && formComponent[0]) {
        formComponent[0].resetSubmitButton();
      }
    } else {
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.USER_TOKEN, res.data.token);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.USER_NAME, res.data.userData.name);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.USER_ID, res.data.userData.user_id);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.HOSPITAL_ID, res.data.userData.add_hospital_id);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.HOSPITAL_NAME, res.data.userData.hospital_name);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.USER_GROUP_ID, res.data.userData.user_group_id);
      if(res.data.userData.user_group_id == 1) {
        window.location.href = "/hospital-management";
      } else {
        window.location.href = "/dashboard";
      }

    }
    console.log(res);
  }

}



