
const TableViewHipPatient = {
    header: [
        { name: "Date of surgery", key: "date_of_surgery" },
        { name: "Patient Name", key: "patient_name" },
        { name: "Region", key: "region" },
        { name: "Side", key: "side" },
        { name: "Demo graphy", key: "demo_graphy" },
        { name: "Preoop", key: "preoop" },
        { name: "Radiology", key: "radiology" },
        { name: "Intraop", key: "intraop" },
        { name: "Postop", key: "postop" },
        { name: "Postop scores", key: "postop_scores" },
        { name: "Status", key: "status" },
    ],
    data: [
    ],
};
export default TableViewHipPatient;











