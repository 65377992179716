import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import FormRadiologicalEvaluations from '../helpers/forms/knee-radiological-evaluations-form';
import { FormComponent } from '../common/form/form.component';
import { SharedService } from '../service/shared.service';
import { ActivatedRoute } from '@angular/router';
import { configuration } from '../configuration';
import { ApiService } from '../service/api.service';



@Component({
  selector: 'app-knee-radiological-evaluations',
  templateUrl: './knee-radiological-evaluations.component.html',
  styleUrls: ['./knee-radiological-evaluations.component.css']
})
export class KneeRadiologicalEvaluationsComponent implements OnInit {
  _formRadiologicalEvaluations = FormRadiologicalEvaluations;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  currentData = null;
  patientData = null;

  id = "";
  vitamin_d_level = "";
  vitamin_b_level = "";
  arrayColumns = ["implant_in_situ", "deformity", "stress_fracture"];
  side = 0;

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    this.shared.currentPage = "knee";
  }


  ngOnInit(): void {
    this.getPatientData();
  }
  async getPatientData() {
    console.log(this.router.url);
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    if (res1 && res1.data && res1.data[0]) {
      this.patientData = res1.data[0];
      this.side = this.patientData.surgery_side;
    }

    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.KNEE_RADIOLOGY, json);
    if (res && res.data && res.data[0]) {
      this.currentData = res.data[0];
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          this.currentData = this.shared.parseRequest(this.currentData, this.arrayColumns);
          this.vitamin_d_level = this.currentData.vitamin_d_level;
          this.vitamin_b_level = this.currentData.vitamin_b_level;
          formComponent[0].setAllFormFieldValue(this.currentData);
          this.showHideRevisionFields(formComponent[0]);
        }
      });
    } else {
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          this.showHideRevisionFields(formComponent[0]);
        }
      });
    }
  }

  showHideRevisionFields(formComponent) {
    console.log("formComponent", formComponent.formParam);
    if(formComponent.formParam && formComponent.formParam.fields) {
      for(let f of formComponent.formParam.fields) {
        if(f.is_revision && this.patientData.patient_sub_type == 0) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
        if(f.is_primary && this.patientData.patient_sub_type == 1) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
      }
    }
  }

  submitForm() {
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        let values = formComponent[0].getFormValue();
        values = JSON.parse(JSON.stringify(values.form.value));
        values.patient_id = this.id;
        values.vitamin_d_level = this.vitamin_d_level;
        values.vitamin_b_level = this.vitamin_b_level;
        values.side = this.side;
        values = this.shared.modifyRequest(values, this.arrayColumns);
        this.shared.isLoader = true;
        console.log("values",values)
        if (this.currentData) {
          await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.KNEE_RADIOLOGY, values, this.currentData.id);
        } else {
          await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.KNEE_RADIOLOGY, values);
        }
        this.shared.isLoader = false;
        window.location.href = '/knee-intraoperative/'+this.id;
      }
    });
  }
}
