
const TableSurgeon = {
    
    header: [
        { name: "Add Hospital Id", key: "add_hospital_id"},
        { name: "User Name", key: "username"},
        { name: "Secret Code", key: "secret_code"},
        { name: "Display Name", key: "display_name"},
        { name: "Admin Branch Id", key: "admin_branch_id"},
        { name: "Is Surgeon", key: "is_surgeon"},
        { name: "Update", key: "table-row-function", "component": "link", "row_type": "update_user"},
        // { name: "Branch", key: "branch"},
        // { name: "Delete", key: "delete"},
        { name: "Delete", key: "table-row-function", "component": "link", "row_type": "delete_user"},
        { name: "Change Password", key: "table-row-function", "component": "link", "row_type": "change_password_surgeon"},

    ],
    data: [
    ],
};
export default TableSurgeon;











