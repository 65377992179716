import { configuration } from "src/app/configuration";

const FormHipIntraoperative = {
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Procedure",
      name: "procedure",
      is_primary: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Total hip',
          },
          {
            op_key: "2",
            op_value: 'Bipolar',
          },
          {
            op_key: "3",
            op_value: 'BHR',
          },
          {
            op_key: "4",
            op_value: 'BMHR',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Surgical plan",
      name: "surgical_plan",
      is_revision: true,
      options: {
        data: [
          { op_key: "1", op_value: "One Stage" },
          { op_key: "2", op_value: "Two Stages" },
          { op_key: "3", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Procedure",
      name: "revision_procedure",
      is_revision: true,
      options: {
        data: [
          { op_key: "1", op_value: "Total revision" },
          { op_key: "2", op_value: "Acetabular revision" },
          { op_key: "3", op_value: "Femoral revision" },
          { op_key: "4", op_value: "Poly exchange" },
        ]
      }
    },
    
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Navigation",
      name: "navigation",
      is_primary: true,
      options: {
        data: configuration.YES_NO_STRING
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Navigation",
      name: "revision_navigation",
      is_revision: true,
      options: {
        data: configuration.YES_NO_STRING
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Drapes",
      name: "drapes",
      options: {
        data: configuration.USE
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Hoods",
      name: "hoods",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Prophylactic antibiotic",
      name: "prophylactic_antibiotic"
    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Tranexamic acid",
      name: "tranexamic_acid",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "",
      name: "tranexamic_acid_option",
      options: {
        data: configuration.LOCAL_IV_OPTIONS
      },

    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Anaesthesia",
      name: "anaesthesia",
      options: {
        data: [
          { op_key: "1", op_value: "Spinal" },
          { op_key: "2", op_value: "Epidural" },
          { op_key: "3", op_value: "GA" },
          { op_key: "4", op_value: "Spinal + Epidural" },
          { op_key: "5", op_value: "GA + Epidural" },
          { op_key: "6", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "ASA Grade",
      name: "asa_grade",
      options: {
        data: [
          { op_key: "1", op_value: "Grade |" },
          { op_key: "2", op_value: "Grade ||" },
          { op_key: "3", op_value: "Grade |||" },
          { op_key: "4", op_value: "Grade |V" },
          { op_key: "5", op_value: "Grade V" },
          { op_key: "6", op_value: "Grade E" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Position",
      name: "position",
      is_primary: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Supine',
          },
          {
            op_key: "2",
            op_value: 'Lateral',
          },
        ],
      },

    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Approach",
      name: "approach",
      options: {
        data: [
          { op_key: "1", op_value: "Anterior" },
          { op_key: "2", op_value: "Anterolateral" },
          { op_key: "3", op_value: "Posterior" },
          { op_key: "4", op_value: "Posterolateral" },
          { op_key: "5", op_value: "MIS" },
          { op_key: "6", op_value: "Trochanteric osteotomy" },
          { op_key: "7", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Status of prev. acetabular comp",
      name: "status_of_prev_acetabular_comp",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Well fixed',
          },
          {
            op_key: "2",
            op_value: 'Loose',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Status of prev. femoral comp",
      name: "status_of_prev_femoral_comp",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Well fixed',
          },
          {
            op_key: "2",
            op_value: 'Loose',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Status of retrieved PE",
      name: "status_of_retrieved_pe",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Worn',
          },
          {
            op_key: "2",
            op_value: 'Fragmented',
          },
          {
            op_key: "3",
            op_value: 'Pitting',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Use of Spacer(Infection)",
      name: "use_of_spacer",
      is_revision: true,
      options: {
        data: [
          { op_key: "1", op_value: "Not used" },
          { op_key: "2", op_value: "Articulating" },
          { op_key: "3", op_value: "Non Articulating" },
          { op_key: "4", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Defect Reconstruction",
      name: "defect_reconstruction",
      is_revision: true,
      class: {
        // offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Soft tissue release",
      name: "soft_tissue_release",
      is_primary: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Anterior capsule',
          },
          {
            op_key: "2",
            op_value: 'IT band',
          },
          {
            op_key: "3",
            op_value: 'Ilio Psoas',
          },
          {
            op_key: "4",
            op_value: 'Adductor',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "STSO",
      name: "stso",
      is_primary: true,
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      name: "bone_graft",
      label: "Bone Graft",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Impaction",
      name: "impaction",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Autograft',
          },
          {
            op_key: "2",
            op_value: 'Allograft',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Bulk",
      name: "bulk",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Autograft',
          },
          {
            op_key: "2",
            op_value: 'Allograft',
          },
        ],
      },
    },

    {
      type: configuration.FORM.FIELD.NOTE,
      name: "",
      label: "Acetabular Cup",

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Cemented",
      name: "cemented",

      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.CEMENTED
      },

    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Antibiotic",
      name: "antibiotic",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Viscosity",
      name: "viscosity",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VISCOSITY
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Uncemented",
      name: "uncemented",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Modular',
          },
          {
            op_key: "2",
            op_value: 'Monoblock',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Acetabular Screw",
      name: "screw",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "No. of screws",
      name: "no_of_screw",
      class: {
        offsetDivClass: "col-md-6",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-3",
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Bearing surface",
      name: "bearing_surface",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          { op_key: "1", op_value: "Poly" },
          { op_key: "2", op_value: "HCL Poly" },
          { op_key: "3", op_value: "Ceramic" },
          { op_key: "4", op_value: "Metal" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Acetabular Liner",
      name: "acetabular_liner",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Neutral',
          },
          {
            op_key: "2",
            op_value: 'Lip',
          },
          {
            op_key: "3",
            op_value: 'Constrained',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Company",
      name: "company",
      optionSelectValue: "-5",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        API: {
          URL: configuration.API_ENDPOINT.GET_COMPANY_OPTION+"?name="+configuration.TABLE.HIP_COMPANY,
        },
        op_key: "company_id",
        op_value: "company_name",        
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Model",
      name: "model",
      optionSelectValue: "-5",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        op_key: "model_id",
        op_value: "model_name",
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Size",
      name: "size",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Cemented",
      name: "fc_cemented",
      offsetLabel: "Femoral component",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.CEMENTED
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Antibiotic",
      name: "fc_antibiotic",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Viscosity",
      name: "fc_viscosity",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.VISCOSITY
      },


    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Uncemented",
      name: "fc_uncemented",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Modular',
          },
          {
            op_key: "2",
            op_value: 'Proximal fixation',
          },
          {
            op_key: "3",
            op_value: 'Distal fixation',
          },
        ],
      },
    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Bearing surface",
      name: "fc_bearing_surface",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Metal',
          },
          {
            op_key: "2",
            op_value: 'Oxinium',
          },
          {
            op_key: "3",
            op_value: 'Ceramic',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Company",
      name: "fc_company",
      optionSelectValue: "-5",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        API: {
          URL: configuration.API_ENDPOINT.GET_COMPANY_OPTION+"?name="+configuration.TABLE.HIP_COMPANY,
        },
        op_key: "company_id",
        op_value: "company_name",        
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Model",
      name: "fc_model",
      optionSelectValue: "-5",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        op_key: "model_id",
        op_value: "model_name",
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Size",
      name: "fc_size",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Posterior closure",
      name: "posterior_closure",
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Capsule to capsule',
          },
          {
            op_key: "2",
            op_value: 'Capsule to bone',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Operative time",
      name: "operative_time"
    },
    // {
    //   type: configuration.FORM.FIELD.TEXT,
    //   label: "HH :",
    //   name: "hh"
    // },  
    // {
    //   type: configuration.FORM.FIELD.TEXT,
    //   label: "MM",
    //   name: "mm"
    // },  
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Blood loss",
      name: "blood_loss"
    },
    // {
    //   type: configuration.FORM.FIELD.TEXT,
    //   label: "ml",
    //   name: "ml"
    // },  
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Drains",
      name: "drains",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Mechanical",
      name: "mechanical",
      offsetLabel: "Thrombo prophylaxis",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'TED Stockings',
          },
          {
            op_key: "2",
            op_value: 'Calf pump',
          },
        ],
      },
    },

    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Pharmacological",
      name: "pharmacological",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'LMWH',
          },
          {
            op_key: "2",
            op_value: 'Oral',
          },
          {
            op_key: "3",
            op_value: 'Aspirin',
          },
          {
            op_key: "4",
            op_value: 'Warfarin',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Postop LLD",
      name: "postop_lld"
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Prophylaxis for HO",
      name: "prophylaxis_for_ho",
      options: {
        data: configuration.YES_NO_STRING
      }
    }
  ]
};
export default FormHipIntraoperative;
