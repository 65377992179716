import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  value = [];

  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
    if(typeof this.field.attr != "undefined") {
      this.field.attr.isInline = 
        typeof this.field.attr.isInline != "undefined" ? this.field.attr.isInline : true;      
    } else {
      this.field.attr = {
        isInline: true
      }
    }
    if(typeof this.field.options == "undefined") {
      this.field.options = {
        data: [],
        op_key: "op_key",
        op_value: "op_value"
      }
    } else {
      this.field.options.op_key = this.field.options.op_key ? this.field.options.op_key : "op_key";
      this.field.options.op_value = this.field.options.op_value ? this.field.options.op_value : "op_value";
    }
  }

  setFormFieldValue(name, value) {
    if(value != null) {
      this.value = value;
      this.field.value = this.value;
    }

    // console.log("currentData", value);
//    this.form.controls[this.field.name] = this.value;
  }

  onCheckboxClick(e, options, option) {
    if(e.target.checked) {
      this.value.push(option[options.op_key]);
    } else {
      const pos = this.value.indexOf(option[options.op_key]);
      if(pos != -1) {
        this.value.splice(pos, 1);
      }
    }
    this.field.value = this.value;
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
  }

  // Set Select box options 
  setFormFieldOptions(name, value, attrname) {
    this.field.options[attrname] = value;
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
  
}
