import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-knee-revision',
  templateUrl: './knee-revision.component.html',
  styleUrls: ['./knee-revision.component.css']
})
export class KneeRevisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
