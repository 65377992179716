import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements OnInit {

  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  isDisplayValidation = false;
  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
    this.form.controls[this.field.name].valueChanges.subscribe(val => {
      this.field.value = val;
      this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
    });
  }

  setFormFieldValue(name, value) {
    console.log("value", value);
    this.field.value = value;
    this.form.controls[this.field.name] = value;
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  removeError() {
    this.isDisplayValidation = false;
  }
}
