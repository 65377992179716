import { configuration } from "src/app/configuration";

const FormSurgeonUpdate = {
  fields: [
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Add Hospital Id",
      name: "add_hospital_id"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Name",
      name: "name"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "User Name",
      name: "username"
    },
    // {
    //   type: configuration.FORM.FIELD.PASSWORD,
    //   label: "Password",
    //   name: "password"
    // },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Secret Code",
      name: "secret_code"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Display Name",
      name: "display_name"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Admin Branch Id",
      name: "admin_branch_id"
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      // label: "Surgeon",
      name: "surgeon_is",
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Surgeon',
          }
        ],
      },
    },
    
  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Update User",
    name: "updateUser",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default FormSurgeonUpdate;
