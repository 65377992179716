import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-knee-primary-tab',
  templateUrl: './knee-primary-tab.component.html',
  styleUrls: ['./knee-primary-tab.component.css']
})
export class KneePrimaryTabComponent implements OnInit {

  @Input() id; 
  @Input() name; 
  @Input() patientData;
  
  constructor() { }

  ngOnInit(): void {
  }

}
