import { configuration } from 'src/app/configuration';

const FormKneePrimarySocietyScoreDeductions = {
  name: "deduction",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Extension lag',
      name: 'extension_lag',
      offsetLabel: 'Deductions',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
        {
          op_key: "0",
          op_value: 'None 0',
        },
        {
          op_key: "-2",
          op_value: '<4 degrees -2',
        },
        {
          op_key: "-5",
          op_value: '5-10 degrees -5',
        },
        {
          op_key: "-10",
          op_value: '>11 degrees -10',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Flexion Contracture',
      name: 'flexion_contracture',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
        {
          op_key: "0",
          op_value: '< 5 degrees 0',
        },
        {
          op_key: "2",
          op_value: '6-10 degrees -3',
        },
        {
          op_key: "3",
          op_value: '11-20 degrees -5',
        },
        {
          op_key: "4",
          op_value: '> 20 degrees -10',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: 'Malalignment',
      name: 'malalignment',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
        {
          op_key: "1",
          op_value: 'Degree  Value: 0',
        },
        {
          op_key: "2",
          op_value: 'Above range: 11°-15° = -2; 16°-20° = -4; 21°-25° = -6',
        },
        {
          op_key: "3",
          op_value: 'Below range:; 0°-4° = -2; -5°-0° = -4; -10°-6° = -6',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Pain at rest',
      name: 'pain_at_rest',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
        {
          op_key: "-5",
          op_value: 'Mild -5',
        },
        {
          op_key: "-10",
          op_value: 'Moderate -10',
        },
        {
          op_key: "-15",
          op_value: 'Severe -15',
        },
        {
          op_key: "0",
          op_value: 'Symptomatic plus objective 0',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: 'Total Knee Score',
      name: 'total_Knee_score',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    }
  ],

};
export default FormKneePrimarySocietyScoreDeductions; 
  
