import { configuration } from 'src/app/configuration';

const FormKneePrimarySocietyScoreStability = {
  name: "stability",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Medial/Lateral',
      name: 'medial_lateral',
      offsetLabel: 'Stability 25',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
        {
          op_key: "15",
          op_value: '0-5 mm 15',
        },
        {
          op_key: "10",
          op_value: '5-10 mm 10',
        },
        {
          op_key: "5",
          op_value: '10 mm 5',
        },
      ],
    },
  },
  // show from reference its pending
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Anterior/Posterior',
      name: 'anterior_posterior',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
        {
          op_key: "10",
          op_value: '0-5 mm 10',
        },
        {
          op_key: "8",
          op_value: '5-10 mm 8',
        },
        {
          op_key: "5",
          op_value: '> 10 mm 5',
        },
     
      ],
    },
  },
 
  ],

};
export default FormKneePrimarySocietyScoreStability;
