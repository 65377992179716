import { configuration } from "src/app/configuration";

const FormHospital = {
  fields: [
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Hospital Id",
      name: "hospital_id"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Hospital Name",
      name: "hospital_name"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Registration Number",
      name: "registration_number"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Hospital Logo",
      name: "hospital_logo"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Number Of Branches",
      name: "number_of_branches"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Alias",
      name: "alias"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "City",
      name: "city"
    },
    
  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Create Hospital",
    name: "createHospital",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default FormHospital;
