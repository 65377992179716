import { Component, OnInit } from '@angular/core';
import FormSample from 'src/app/helpers/forms/sample-form';

@Component({
  selector: 'app-sample-form',
  templateUrl: './sample-form.component.html',
  styleUrls: ['./sample-form.component.css']
})
export class SampleFormComponent implements OnInit {
  _formParam = FormSample;
  constructor() { }

  ngOnInit(): void {
  }

}
