<ng-container *ngIf="isCrudRefreshed">
    <div class="row crud-main-div">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <!-- <h4>
                {{_crudConfig.master_name}}
            </h4> -->
                </div>
                <div class="col-md-6">
                    <button class="btn btn-primary btn-custom float-right"
                        *ngIf="_crudConfig.action.indexOf(_shared._config.CRUD_ACTION.ADD) >= 0"
                        (click)="addNewRecord()">
                        {{isAddNewFormDisplay ? "Back" : ( _crudConfig.label && _crudConfig.label.ADD ? _crudConfig.label.ADD : "Add New") }}
                    </button>
                </div>
            </div>

            <div class="mt-2">
                <app-table #tableComponent *ngIf="!isAddNewFormDisplay" (openDeleteForm)="openDeleteForm($event)"
                    (openEditForm)="openEditForm($event)" [tableParam]="_crudConfig.tableConfig.tableParam">
                </app-table>
            </div>

            <app-form #formComponent (onFormFieldOnChange)="onFormFieldOnChange($event)" *ngIf="isAddNewFormDisplay" (onSubmit)="onSubmit($event)"
                [formParam]="_formParam">
            </app-form>

            <ng-container *ngIf="isDeletePopupOpen">
                <div class="modal-backdrop fade show"></div>
                <div class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Confirmation</h5>
                                <button type="button" class="close" (click)="closeDeleteBox()" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>Are you sure want to delete this record?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="runDeleteAction()">Yes</button>
                                <button type="button" class="btn btn-secondary" (click)="closeDeleteBox()"
                                    data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>