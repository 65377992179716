<form [formGroup]="form">
    <div class="form-group row">
        <label *ngIf="field.class" [ngClass]="field.class.offsetDivClass">
            {{field.offsetLabel}}
        </label>
        <div [ngClass]="field.class.labelDivClass">
            <label *ngIf="field.isDisplayLabel">
                {{field.label}}
                <span *ngIf="isRequired()" class="text-danger">*</span>
            </label>
        </div>    
        <div  [ngClass]="field.class.inputDivClass">
            <ng-container *ngIf="field.image">
                <div>
                    <img [src]="field.image" />
                    <br />
                </div>
            </ng-container>
            <div class="select">
                <select class="form-control" [ngClass]="field.isControlBig ? 'form-control-lg' : ''" [name]="field.name" [formControlName]="field.name"  [attr.disabled]="field.isDisabled ? true : null">
                    <option [value]="field.optionSelectValue ? field.optionSelectValue : ''">{{field.selectLabel}}</option>
                    <ng-container *ngIf="field.options && field.options.data">
                        <ng-container *ngFor="let d of field.options.data">
                            <option [selected]="field.value == d[field.options.op_key]" [value]="d[field.options.op_key]">
                                {{getValue(d,field)}}
                            </option>
                        </ng-container>
                    </ng-container>
                </select>
                <i class="form-group__bar"></i>
             </div>   
        </div>
    </div>
    <ng-container *ngIf="field.isDisplayInlineError && isDisplayValidation && _form[field.name] && _form[field.name].errors">
        <ng-container *ngIf="_form[field.name].errors.required">
            <div class="row">
                <div [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel"></div>
                <div class="form-error" [ngClass]="field.class.inputDivClass">
                    <span class=" color-red">
                        This field is required!
                    </span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</form>