import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import FormPreopRadiographic from '../helpers/forms/knee-postop-radiographic-form';
import { FormComponent } from '../common/form/form.component';
import { SharedService } from '../service/shared.service';
import { ActivatedRoute } from '@angular/router';
import { configuration } from '../configuration';
import { ApiService } from '../service/api.service';
import * as _moment from 'moment';
const moment = _moment;


@Component({
  selector: 'app-knee-postop-radiographic',
  templateUrl: './knee-postop-radiographic.component.html',
  styleUrls: ['./knee-postop-radiographic.component.css']
})
export class KneePostopRadiographicComponent implements OnInit {
  _formPreopRadiographic = FormPreopRadiographic;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  currentData = null;
  patientData = null;
  id = "";
  side = 0;

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    this.shared.currentPage = "knee";
  }

  ngOnInit(): void {
    this.getPatientData();
  }
  async getPatientData() {
    console.log(this.router.url);
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    if (res1 && res1.data && res1.data[0]) {
      this.patientData = res1.data[0];
      this.side = this.patientData.surgery_side;
    }

    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.KNEE_POSTOP, json);
    if (res && res.data && res.data[0]) {
      this.currentData = res.data[0];
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          formComponent[0].setAllFormFieldValue(this.currentData);
          console.log("this.patientData.surgery_side", this.patientData.surgery_side)
          if (configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]) {
            formComponent[0].setFormFieldValue("side_of_knee", configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]);
          }
        }
      });
    } else {
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          console.log("this.patientData.surgery_side", this.patientData.surgery_side)
          if (configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]) {
            formComponent[0].setFormFieldValue("side_of_knee", configuration.OPERATED_KNEE_VALUE[this.patientData.surgery_side]);
          }
        }
      });
    }
  }

  submitForm() {
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        let values = formComponent[0].getFormValue();
        values = JSON.parse(JSON.stringify(values.form.value));
        values.patient_id = this.id;
        values.side = this.side
        // console.log("values",values)
        this.shared.isLoader = true;
        if (this.currentData) {
          await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.KNEE_POSTOP, values, this.currentData.id);
        } else {
          await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.KNEE_POSTOP, values);
        }
        this.shared.isLoader = false;
        window.location.href = '/knee-postop-score/'+this.id;
      }
    });
  }
}
