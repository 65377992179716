// tslint:disable: directive-selector
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[tableRowFunctionLoader]',
})

export class TableRowFunctionDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}
