import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-hip-primary-tab',
  templateUrl: './hip-primary-tab.component.html',
  styleUrls: ['./hip-primary-tab.component.css']
})
export class HipPrimaryTabComponent implements OnInit {
  @Input() id; 
  @Input() name; 
  @Input() patientData;

  constructor() {

  }

  ngOnInit(): void {
    console.log("patientData", this.patientData)
  }

}
