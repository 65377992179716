import { configuration } from "src/app/configuration";

const Button = {
  
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Create Hospital",
    name: "createHospital",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default Button;
