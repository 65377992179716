import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  ccField = null;
  mField = null;

  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.mField = JSON.parse(JSON.stringify(this.field));
    this.ccField = JSON.parse(JSON.stringify(this.field));
    this.ccField.name = "cc_"+this.ccField.name;
    this.ccField.placeholder = "+91";
    
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.mField.name,
      new FormControl(this.mField.value)
    );
    this.form.addControl(
      this.ccField.name,
      new FormControl(this.ccField.value)
    );
    this.form.controls[this.mField.name].valueChanges.subscribe(val => {
      this.mField.value = val;
      this.field.value = {
        mobile: val,
        code: this.ccField.value
      }
      this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
    });
    this.form.controls[this.ccField.name].valueChanges.subscribe(val => {
      this.ccField.value = val;
      this.field.value = {
        mobile: this.mField.value,
        code: val
      }
      this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});
    });
  }

  setFormFieldValue(name, value) {
    if(typeof value.mobile != "undefined") {
      this.field.value = value.mobile;
      this.form.controls[this.field.name].setValue(value.mobile);
    }
    if(typeof value.code != "undefined") {
      this.ccField.value = value.code;
      this.form.controls[this.ccField.name].setValue(value.code);
    }

  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
}
