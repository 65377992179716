import { configuration } from "src/app/configuration";

const FormPreopRadiographic = {
  fields: [

    {
      type: configuration.FORM.FIELD.DATEPICKER,
      label: "Date of input",
      name: "date_of_input"
    },
    {
      type: configuration.FORM.FIELD.NOTE,
      label: "Radiological evaluations",
      name: "",
      class: {
        labelDivClass: "col-md-12 form-inside-label"
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Side of the Knee",
      name: "side_of_knee",
      isDisabled: true,
      is_primary: true,
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Notes",
      name: "notes",
      is_revision: true,
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Posterior slope",
      name: "posterior_slope"
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Hip knee ankle axis",
      name: "hip_knee_ankle"
    },
  ],

};
export default FormPreopRadiographic;
