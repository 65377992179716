import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-table-row-function-print',
  templateUrl: './table-row-function-print.component.html',
  styleUrls: ['./table-row-function-print.component.css']
})
export class TableRowFunctionPrintComponent implements OnInit {
  @Input() h;
  @Input() data;
  isOpenPrint = false;

  constructor(public _shared: SharedService) { }

  ngOnInit(): void {
  }

  openPrint() {
    this._shared.openPrint(this.h, this.data);
  }

}
