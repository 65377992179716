
<app-form #formComponent (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySocietyScorePlain"></app-form>
<app-form #formComponent (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySocietyScoreStability"></app-form>
<app-form #formComponent (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySocietyScoreDeductions"></app-form>
<app-form #formComponent (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimarySocietyScoreFunction"></app-form>

<div class="row">
    <div class="col-md-12 text-center mb-4 mt-3">
        <button class="btn btn-primary mr-1" (click)="submit()">Submit</button>
    </div>
</div>