import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormRevisionSearch from '../helpers/forms/revision-search-form';
import TableRevisionSearch from '../helpers/tables/revision-search-table';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-revision-search',
  templateUrl: './revision-search.component.html',
  styleUrls: ['./revision-search.component.css']
})
export class RevisionSearchComponent implements OnInit {

  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  _FormRevisionSearch = FormRevisionSearch;
  _TableRevisionSearch = TableRevisionSearch;
  constructor(public _apiService: ApiService, public shared: SharedService) {
    this.shared.currentPage = "custom_search";
  }

  ngOnInit(): void {
  }

  async onSubmit(e) {
    console.log(e.form.value);
    const formComponent: any = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let errors = formComponent[0].runValidation();
      if (errors.length == 0) {
        console.log("formComponent", formComponent[0]);
        let formValues = formComponent[0].getFormValue();
        if (formValues.form.value) {
          let values = JSON.parse(JSON.stringify(formValues.form.value));
          let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.GET_REVISION_SEARCH, e.form.value);
          const formComponent: any = this.formComponent.toArray();
          if (formComponent && formComponent[0]) {
            formComponent[0].resetSubmitButton();
          }
          if (res && res.data) {
            this._TableRevisionSearch.data = res.data;
          }
          console.log(res)
        }
      } else {
        formComponent[0].resetSubmitButton();
      }
    }
  }
}
