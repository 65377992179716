<form [formGroup]="form">
    <div class="form-group row">
        <label [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel">
            {{field.label}}
            <span *ngIf="isRequired()" class="text-danger">*</span>
        </label>
        <div [ngClass]="field.class.inputDivClass">
            <div class="row">
                <div class="col-md-6">
                    <app-datepicker #fielddateComponent (onValueChange)="_onValueChange($event)" [field]="field1"></app-datepicker>
                </div>
                <div class="col-md-6">
                    <app-timepicker #fieldtimeComponent (onValueChange)="_onValueChange($event)" [field]="field2"></app-timepicker>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="field.isDisplayInlineError && isDisplayValidation && !field1.value">
            <div class="row">
                <div [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel"></div>
                <div [ngClass]="field.class.inputDivClass">
                    <span class="color-red">
                        This field is required!
                    </span>
                </div>
            </div>
    </ng-container>
</form>

