import { Component, OnInit } from '@angular/core';
import OptionTable from 'src/app/helpers/tables/option-table';
import AddOption from 'src/app/helpers/forms/add-options';

@Component({
  selector: 'app-revised-hip-options',
  templateUrl: './revised-hip-options.component.html',
  styleUrls: ['./revised-hip-options.component.css']
})
export class RevisedHipOptionsComponent implements OnInit {

  constructor() { }
  formArray = [
    { label: "Primary Diagnosis", form: OptionTable},
    { label: "AVN", form: OptionTable },
    { label: "Inflammatory Arthritis", form: OptionTable },
    { label: "Post infective Pathalogy", form: OptionTable },
    { label: "Cause of revision", form: OptionTable },
    { label: "Prev Prosthetic Type", form: OptionTable },
    { label: "Bearing surface", form: OptionTable },
    { label: "Company", form: OptionTable },
    { label: "Current Symptoms", form: OptionTable },
    { label: "Co morbidities", form: OptionTable },
    { label: "Surgical plan", form: OptionTable },
    { label: "Procedure", form: OptionTable },
    { label: "Anaesthesia", form: OptionTable },
    { label: "Approach", form: OptionTable },
    { label: "Use of Spacer", form: OptionTable },
    { label: "Acetabular Cup Antibiotic", form: OptionTable },
    { label: "Femoral Antibiotic", form: OptionTable },
    { label: "Mechanical", form: OptionTable },
    { label: "Pharmacological", form: OptionTable },

  ]
  _addOption = AddOption
  ngOnInit(): void {
  }

}
