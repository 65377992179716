import { configuration } from 'src/app/configuration';

const FormHipPrimaryHarrisSection2Score = {
  name: "harris_section_score_2",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Less than 30degrees of fixed flexion",
      name: "less_than_30degrees_of_fixed_flexion",
      options: {
        data: configuration.YES_NO
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Less than 10 degrees of fixed adduction",
      name: "less_than_10_degrees_of_fixed_adduction",
      options: {
        data: configuration.YES_NO
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Less than 10 degrees of fixed int rotation in extension",
      name: "less_than_10_degrees_of_fixed_int_rotation_in_extension",
      options: {
        data: configuration.YES_NO
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Limb length discrepancy less than 3.2 cm (1.5 inches)",
      name: "limb_length_discrepancy_less_than_32_cm_15_inches",
      options: {
        data: configuration.YES_NO
      },
    },

  ],

};
export default FormHipPrimaryHarrisSection2Score;

