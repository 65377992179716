import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormAdminLogin from '../helpers/forms/admin-login';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  _formAdminlogin = FormAdminLogin;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  constructor(public shared: SharedService, public _apiService: ApiService) { }

  ngOnInit(): void {
  }

  async onSubmit(e) {
    console.log(e.form.value);
    this.shared.isLoader = true;

    let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.ADMIN_LOGIN, e.form.value);
    if (res.error) {
      this.shared.isLoader = false;
      this.shared.displayErrorMessage({
        msg: res.message
      })
      const formComponent: any = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        formComponent[0].resetSubmitButton();
      }
    } else {
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.ADMIN_USER_NAME, res.data.adminData.admin_username);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.ADMIN_ID, res.data.adminData.admin_id);
      configuration.setLocalStorageValue(configuration.LOCAL_STORAGE.ADMIN_TOKEN, res.data.token);
      window.location.href = "/dashboard";
    }
    console.log(res);
  }

}
