<app-form (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimaryWomacScorePlain"></app-form>
<app-form (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimaryWomacScoreStiffness"></app-form>
<app-form (onFormFieldOnChange)="onFormFieldOnChange($event)" [formParam]="_formKneePrimaryWomacScorePhysicalFunction"></app-form>

<div class="row">
    <div class="col-md-12 text-center mb-4 mt-3">
        <button class="btn btn-primary mr-1" (click)="submit()">Submit</button>
    </div>
</div>



