
const TablePrimarySummary = {
    header: [
        { name: "Sl. No", key: "sl_no" },
        { name: "Name", key: "first_name",function: "getPatientName" },
        { name: "Age", key: "age" },
        { name: "Gender", key: "gender" },
        { name: "Email id", key: "email_id" },
        { name: "Phone", key: "phone" },
        { name: "Date of surgery", key: "date_of_surgery" },
        { name: "Cause of revision", key: "cause_of_revision" },
        { name: "Time from index surgery", key: "time_from_index_surgery" },
        { name: "Procedure", key: "procedure" },
        { name: "Prosthetic used", key: "prosthetic_used" },
        { name: "Company", key: "company" },
        { name: "Design", key: "design" },
    ],
    data: [
    ],
};
export default TablePrimarySummary;