<ng-container *ngIf="isOpenModal">
    <div class="modal-backdrop fade show"></div>
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="closeDeleteBox()" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Are you sure want to delete this record?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="onDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" (click)="closeDeleteBox()"
                        data-dismiss="modal">No</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>