import { Component, OnInit } from '@angular/core';
import TableViewHipPatient from '../helpers/tables/view-hip-patient-table';

@Component({
  selector: 'app-view-hip-patients',
  templateUrl: './view-hip-patients.component.html',
  styleUrls: ['./view-hip-patients.component.css']
})
export class ViewHipPatientsComponent implements OnInit {
  _tableViewHipPatient = TableViewHipPatient;
  constructor() { }

  ngOnInit(): void {
  }

}
