<form [formGroup]="form">
    <div class="row">
        <div [ngClass]="field.class.labelDivClass">
            <label *ngIf="field.isDisplayLabel">{{field.label}} 
                <span *ngIf="isRequired()" class="text-danger">*</span>
            </label>
        </div>
        <div [ngClass]="field.class.inputDivClass">
            <div class="row">
                <div class="col-md-2">
                    <app-select (onValueChange)="onValueChangeField($event,'salutation')" #fieldselectOneComponent [field]="fieldSelect"></app-select>
                </div>
                <div class="col-md-10">
                    <app-text (onValueChange)="onValueChangeField($event,'name')" #fieldtextComponent [field]="fieldText"></app-text>
                </div>
            </div>
        </div>
    </div>
</form>