import { configuration } from 'src/app/configuration';

const FormKneePrimarySocietyScorePlain = {
  name: "plain",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Walking',
      name: 'walking',
      offsetLabel: "Pain",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
        {
          op_key: "35",
          op_value: 'None 35',
        },
        {
          op_key: "30",
          op_value: 'Mild or occasional 30',
        },
        {
          op_key: "15",
          op_value: 'Moderate 15',
        },
        {
          op_key: "0",
          op_value: 'Severe 0',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Stairs',
      name: 'stairs',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
        {
          op_key: "15",
          op_value: 'None 15',
        },
        {
          op_key: "10",
          op_value: 'Mild or occasional 10',
        },
        {
          op_key: "5",
          op_value: 'Moderate 5',
        },
        {
          op_key: "0",
          op_value: 'Severe 0',
        },
      ],
    },
  },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Range Of Motion",
      name: "range_of_motion",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
  ],

};
export default FormKneePrimarySocietyScorePlain;
