<form [formGroup]="form">
    <div class="form-group row">

        <label [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel">
            {{field.label}}
            <span *ngIf="isRequired()" class="text-danger">*</span>
        </label>
        <div [ngClass]="field.class.inputDivClass" class="input-group note-field">
            <span class="cursor" (click)="onClick($event)" [innerHTML]="field.value">
            </span>
            <!-- <input type="text" (click)="onClick($event)" readonly class="form-control-plaintext" [formControlName]="field.name" [value]="field.value"> -->
        </div>
    </div>
</form>