import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from './common/form/form.component';
import { TextComponent } from './common/form/text/text.component';
import { MaterialModule } from './material.module';
import { ButtonComponent } from './common/form/button/button.component';
import { CheckboxComponent } from './common/form/checkbox/checkbox.component';
import { DatepickerComponent } from './common/form/datepicker/datepicker.component';
import { EmailComponent } from './common/form/email/email.component';
import { FileComponent } from './common/form/file/file.component';
import { NoteComponent } from './common/form/note/note.component';
import { NumberComponent } from './common/form/number/number.component';
import { PasswordComponent } from './common/form/password/password.component';
import { RadioComponent } from './common/form/radio/radio.component';
import { SelectComponent } from './common/form/select/select.component';
import { AutocompleteComponent } from './common/form/autocomplete/autocomplete.component';
import { TableComponent } from './common/table/table.component';
import { DataTablesModule } from 'angular-datatables';
import { CrudComponent } from './common/crud/crud.component';
import { BirthdateComponent } from './common/form/birthdate/birthdate.component';
import { AddressComponent } from './common/form/address/address.component';
import { NameComponent } from './common/form/name/name.component';
import { MobileComponent } from './common/form/mobile/mobile.component';
import { IdproofComponent } from './common/form/idproof/idproof.component';
import { TextareaComponent } from './common/form/textarea/textarea.component';
import { TimepickerComponent } from './common/form/timepicker/timepicker.component';
import { TimepickerdurationComponent } from './common/form/timepickerduration/timepickerduration.component';
import { NoofpersonComponent } from './common/form/noofperson/noofperson.component';
import { DateandtimeComponent } from './common/form/dateandtime/dateandtime.component';
import { RouterModule } from '@angular/router';
import { ChipsComponent } from './common/views/chips/chips.component';
import { TableRowFunctionComponent } from './common/table-row-function/table-row-function.component';
import { TableRowFunctionDirective } from './common/table-row-function.directive';
import { SpecialOpdChargeComponent } from './common/form/special-opd-charge/special-opd-charge.component';
import { PaymentBoxWithAmountComponent } from './common/form/payment-box-with-amount/payment-box-with-amount.component';
import { GridBoxComponent } from './common/form/grid-box/grid-box.component';
import { GridBoxInventoryComponent } from './common/form/grid-box-inventory/grid-box-inventory.component';
import { GridBoxStoreInventoryComponent } from './common/form/grid-box-store-inventory/grid-box-store-inventory.component';
import { GridBoxMedicineBillComponent } from './common/form/grid-box-medicine-bill/grid-box-medicine-bill.component';
import { GridBoxStoreRequestComponent } from './common/form/grid-box-store-request/grid-box-store-request.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TableRowFunctionLinkComponent } from './common/table-row-function-link/table-row-function-link.component';

@NgModule({
  declarations: [FormComponent, TableComponent, TableRowFunctionComponent, TableRowFunctionLinkComponent, CrudComponent, TextComponent, ButtonComponent, CheckboxComponent, DatepickerComponent, EmailComponent, FileComponent, NoteComponent, NumberComponent, PasswordComponent, RadioComponent, SelectComponent, AutocompleteComponent, BirthdateComponent, AddressComponent, NameComponent, MobileComponent, IdproofComponent, TextareaComponent, TimepickerComponent, TimepickerdurationComponent, NoofpersonComponent, DateandtimeComponent, ChipsComponent, TableRowFunctionDirective, SpecialOpdChargeComponent, PaymentBoxWithAmountComponent, GridBoxComponent, GridBoxInventoryComponent, GridBoxStoreInventoryComponent, GridBoxMedicineBillComponent, GridBoxStoreRequestComponent],
  imports: [FormsModule, ReactiveFormsModule, RouterModule, CommonModule, MaterialModule, DataTablesModule, NgxMaterialTimepickerModule],
  exports: [
    CommonModule,FormsModule, ReactiveFormsModule, NgxMaterialTimepickerModule,
    FormComponent, TextComponent, TableComponent, TableRowFunctionComponent, TableRowFunctionLinkComponent, CrudComponent, ChipsComponent
  ]
})
export class CommonAppModule { }
