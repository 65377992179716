
const TableSample = {
    header: [
        { name: "Name 1", key: "name_1" },
        { name: "Name 2", key: "name_2" },
    ],
    data: [
    ],
};
export default TableSample;











