import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormSummary from '../helpers/forms/summary-form';
import TableRevisionSummary from '../helpers/tables/revision-summary-table';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  _FormRevisionSummary = FormSummary;
  _TableRevisionSummary = TableRevisionSummary;

  constructor(public _apiService: ApiService, public shared: SharedService) {
    this.shared.currentPage = "report";
  }

  ngOnInit(): void {
  }

  async onSubmit(e) {
    console.log(e.form.value);
    const formComponent: any = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let errors = formComponent[0].runValidation();
      if (errors.length == 0) {
        console.log("formComponent", formComponent[0]);
        let formValues = formComponent[0].getFormValue();
        if (formValues.form.value) {
          let values = JSON.parse(JSON.stringify(formValues.form.value));
          let res = await this._apiService.POST_apiRequest(configuration.API_ENDPOINT.GET_SUMMMARY, values);
          if (res && res.data) {
            this._TableRevisionSummary.data = res.data;
          }
          console.log(res)
        }
      }
      formComponent[0].resetSubmitButton();      
    }
  }
}
