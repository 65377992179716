
const TableUploadImage = {
    header: [
        { name: "Thumbnail", key: "thumbnail", function: "displayImageFromBuffer" },
        { name: "File Name", key: "file_name" },
        { name: "Size", key: "size", function: "displayFileSize" },
        { name: "Progress", key: "progress" },
        { name: "Status", key: "status" },
        { name: "Tags", key: "tags", function: "textinput" },
        { name: "Action", key: "action" },
    
    ],
    data: [
    ],
};
export default TableUploadImage;











