import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SampleFormComponent } from './sample/sample-form/sample-form.component';
import { SampleTableComponent } from './sample/sample-table/sample-table.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { KneePreopClinicalComponent } from './knee-preop-clinical/knee-preop-clinical.component';
import { KneeIntraoperativeComponent } from './knee-intraoperative/knee-intraoperative.component';
import { KneeRadiologicalEvaluationsComponent } from './knee-radiological-evaluations/knee-radiological-evaluations.component';
import { KneePostopRadiographicComponent } from './knee-postop-radiographic/knee-postop-radiographic.component';
import { KneePostopScoreComponent } from './knee-postop-score/knee-postop-score.component';
import { HipPreopClinicalComponent } from './hip-preop-clinical/hip-preop-clinical.component';
import { HipRadiologicalEvaluationsComponent } from './hip-radiological-evaluations/hip-radiological-evaluations.component';
import { HipIntraoperativeComponent } from './hip-intraoperative/hip-intraoperative.component';
import { HipPostopScoreComponent } from './hip-postop-score/hip-postop-score.component';
import { KneePrimarySocietyScoreComponent } from './knee-primary/knee-primary-society-score/knee-primary-society-score.component';

import { KneePrimaryWomacScoreComponent } from './knee-primary/knee-primary-womac-score/knee-primary-womac-score.component';
import { KneePrimarySf36Component } from './knee-primary/knee-primary-sf36/knee-primary-sf36.component';
import { KneePrimaryKujalaScoreComponent } from './knee-primary/knee-primary-kujala-score/knee-primary-kujala-score.component';
import { KneePrimaryOxfordScoreComponent } from './knee-primary/knee-primary-oxford-score/knee-primary-oxford-score.component';
import { KneePrimaryPatientSatisfactionScoreComponent } from './knee-primary/knee-primary-patient-satisfaction-score/knee-primary-patient-satisfaction-score.component';
import { HipPrimaryHarrisScoreComponent } from './hip-primary/hip-primary-harris-score/hip-primary-harris-score.component';
import { HipPrimarySf36ScoreComponent } from './hip-primary/hip-primary-sf36-score/hip-primary-sf36-score.component';
import { HipPostopRadiographicComponent } from './hip-postop-radiographic/hip-postop-radiographic.component';
import { KneeDemographyComponent } from './knee-demography/knee-demography.component';
import { HipDemographyComponent } from './hip-demography/hip-demography.component';
import { KneePrimaryComponent } from './knee-primary/knee-primary.component';
import { KneePrimaryTabComponent } from './knee-primary-tab/knee-primary-tab.component';
import { HipPrimarySatisfactionScoreComponent } from './hip-primary/hip-primary-satisfaction-score/hip-primary-satisfaction-score.component';
import { RevisionSummaryComponent } from './revision-summary/revision-summary.component';
import { PrimarySummaryComponent } from './primary-summary/primary-summary.component';
import { PreOpScoresComponent } from './pre-op-scores/pre-op-scores.component';
import { PrimarySearchComponent } from './primary-search/primary-search.component';
import { RevisionSearchComponent } from './revision-search/revision-search.component';
import { SummaryComponent } from './summary/summary.component';
import { HospitalListComponent } from './admin/hospital-list/hospital-list.component';
import { HospitalFormfillComponent } from './newform/hospital-formfill/hospital-formfill.component';
import { KneeCompanyComponent } from './implants/knee-company/knee-company.component';
import { KneeDesignComponent } from './secondImplants/knee-design/knee-design.component';
import { HipCompanyComponent } from './thirdImplants/hip-company/hip-company.component';
import { HipDesignComponent } from './fourthImplants/hip-design/hip-design.component';
import { HospitalBranchComponent } from './branch/hospital-branch/hospital-branch.component';
import { PrimaryKneeOptionsComponent } from './additional-options/primary-knee-options/primary-knee-options.component';
import { RevisedKneeOptionsComponent } from './additional-options/revised-knee-options/revised-knee-options.component';
import { PrimaryHipOptionsComponent } from './additional-options/primary-hip-options/primary-hip-options.component';
import { RevisedHipOptionsComponent } from './additional-options/revised-hip-options/revised-hip-options.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { SurgeonCreateComponent } from './surgeon-create/surgeon-create.component';
import { SurgeonListComponent } from './surgeon-list/surgeon-list.component';
import { HospitalBranchListComponent } from './hospital-branch-list/hospital-branch-list.component';
import { BranchChangePasswordComponent } from './branch-change-password/branch-change-password.component';
import { BranchUpdateFormComponent } from './branch-update-form/branch-update-form.component';
import { DeleteHospitalComponent } from './delete-hospital/delete-hospital.component';
import { DeleteBranchComponent } from './delete-branch/delete-branch.component';
import { DeleteSugeonComponent } from './delete-sugeon/delete-sugeon.component';
import { UpdateSugeonComponent } from './update-sugeon/update-sugeon.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { BranchLoginComponent } from './branch-login/branch-login.component';
import { SurgeonChangePasswordComponent } from './surgeon-change-password/surgeon-change-password.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'sample-table',
    component: SampleTableComponent
  },
  {
    path: 'sample-form',
    component: SampleFormComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin-login',
    component: AdminLoginComponent
  },
  {
    path: 'branch-login',
    component: BranchLoginComponent
  },
  {
    path: 'knee-demography/:id',
    component: KneeDemographyComponent
  },
  {
    path: 'knee-preop-clinical/:id',
    component: KneePreopClinicalComponent
  },
  {
    path: 'knee-preop-clinical/:id/:side',
    component: KneePreopClinicalComponent
  },
  {
    path: 'knee-radiological/:id',
    component: KneeRadiologicalEvaluationsComponent
  },
  {
    path: 'knee-intraoperative/:id',
    component: KneeIntraoperativeComponent
  },
  {
    path: 'knee-postop/:id',
    component: KneePostopRadiographicComponent
  },
  {
    path: 'knee-postop-score/:id',
    component: KneePostopScoreComponent
  },
  {
    path: 'hip-demography/:id',
    component: HipDemographyComponent
  },
  {
    path: 'hip-preop-clinical/:id',
    component: HipPreopClinicalComponent
  },
  {
    path: 'hip-radiological-evaluation/:id',
    component: HipRadiologicalEvaluationsComponent
  },
  {
    path: 'hip-intraoperative/:id',
    component: HipIntraoperativeComponent
  },
  {
    path: 'hip-postop/:id',
    component: HipPostopRadiographicComponent
  },
  {
    path: 'hip-postop-score/:id',
    component: HipPostopScoreComponent
  },
  {
    path: 'knee-primary-society-score',
    component: KneePrimarySocietyScoreComponent
  },
  {
    path: 'knee-primary-womac-score',
    component: KneePrimaryWomacScoreComponent
  },
  {
    path: 'knee-primary-sf36',
    component: KneePrimarySf36Component
  },
  {
    path: 'knee-primary-kujala-score',
    component: KneePrimaryKujalaScoreComponent
  },
  {
    path: 'knee-primary-ocford-score',
    component: KneePrimaryOxfordScoreComponent
  },
  {
    path: 'knee-primary-satisfaction-score',
    component: KneePrimaryPatientSatisfactionScoreComponent
  },
  {
    path: 'hip-primary-harris-score',
    component: HipPrimaryHarrisScoreComponent
  },
  {
    path: 'hip-primary-sf36-score',
    component: HipPrimarySf36ScoreComponent
  },
  {
    path: 'hip-primary-satisfaction-score',
    component: HipPrimarySatisfactionScoreComponent
  },
  {
    path: 'knee-primary',
    component: KneePrimaryComponent
  },
  {
    path: 'knee-revision',
    component: KneePrimaryComponent
  },
  {
    path: 'hip-primary',
    component: KneePrimaryComponent
  },
  {
    path: 'hip-revision',
    component: KneePrimaryComponent
  },
  {
    path: 'knee-patients',
    component: DashboardComponent
  },
  {
    path: 'hip-patients',
    component: DashboardComponent
  },
  {
    path: 'knee-primary-tab',
    component: KneePrimaryTabComponent
  },
  {
    path: 'summary',
    component: SummaryComponent
  },
  {
    path: 'revision-summary',
    component: RevisionSummaryComponent
  },
  {
    path: 'primary-summary',
    component: PrimarySummaryComponent
  },
  {
    path: 'pre-op-scores',
    component: PreOpScoresComponent
  },
  {
    path: 'primary-search',
    component: PrimarySearchComponent
  },
  {
    path: 'revision-search',
    component: RevisionSearchComponent
  },
  {
    path: 'hospital-management',
    component: HospitalListComponent
  },
  {
    path: 'hospital-formfill',
    component: HospitalFormfillComponent
  },
  {
    path: 'hospital-formfill/update/:id',
    component: HospitalFormfillComponent
  },
  {
    path: 'delete-hospital/:id',
    component: DeleteHospitalComponent
  },
  {
    path: 'surgeon-create',
    component: SurgeonCreateComponent
  },
  {
    path: 'surgeon-update/:id',
    component: UpdateSugeonComponent
  },
  {
    path: 'surgeon-list',
    component: SurgeonListComponent
  },
  {
    path: 'surgeon-change-password/:id',
    component: SurgeonChangePasswordComponent
  },
  {
    path: 'surgeon-delete/:id',
    component: DeleteSugeonComponent
  },
  {
    path: 'knee-company',
    component: KneeCompanyComponent
  },
  {
    path: 'knee-design',
    component: KneeDesignComponent
  },
  {
    path: 'hip-company',
    component: HipCompanyComponent
  },
  {
    path: 'hip-design',
    component: HipDesignComponent
  },
  {
    path: 'hospital-branch/:id',
    component: HospitalBranchComponent
  },
  {
    path: 'update-branch/:id',
    component: BranchUpdateFormComponent
  },
  {
    path: 'delete-branch/:id',
    component: DeleteBranchComponent
  },
  {
    path: 'hospital-branch-list/:id',
    component: HospitalBranchListComponent
  },
  {
    path: 'branch-change-password/:id',
    component: BranchChangePasswordComponent
  },
  {
    path: 'additional-options/knee-primary-options',
    component: PrimaryKneeOptionsComponent
  },
  {
    path: 'additional-options/revised-knee-options',
    component: RevisedKneeOptionsComponent
  },
  {
    path: 'additional-options/primary-hip-options',
    component: PrimaryHipOptionsComponent
  },
  {
    path: 'additional-options/revised-hip-options',
    component: RevisedHipOptionsComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  } 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
