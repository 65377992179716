import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {
  @Input() field;
  form: FormGroup;
  @Output() onClickEvent = new EventEmitter();
  
  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
  }

  onClick(e) {
    this.onClickEvent.emit({ e, field: this.field, form: this.form });
  }

  setFormFieldValue(name, value) {
    console.log("Value", value);
    this.field.value = value;
    this.form.controls[this.field.name] = value;
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  // Set Select box options 
  setFormFieldOptions(name, value, attrname) {

  }

  isRequired() {
    return (this.field.validation && this.field.validation.required);
  }
}
