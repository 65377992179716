import { configuration } from 'src/app/configuration';

const FormKneePrimarySocietyScoreFunction = {
  name: "society_score",
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Walking',
      name: 'walking',
      offsetLabel: 'Function Score',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: "50",
            op_value: 'Unlimited 50',
          },
          {
            op_key: "40",
            op_value: '> 10 blocks 40',
          },
          {
            op_key: "30",
            op_value: '5-10 blocks 30',
          },
          {
            op_key: "20",
            op_value: '< 5 blocks 20',
          },
          {
            op_key: "10",
            op_value: 'Housebound 10',
          },
          {
            op_key: "0",
            op_value: 'Unable 0',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Stairs',
      name: 'stairs',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: "50",
            op_value: 'Normal up & down 50',
          },
          {
            op_key: "40",
            op_value: 'Normal up, down with rail 40',
          },
          {
            op_key: "30",
            op_value: 'Up & down with rail 30',
          },
          {
            op_key: "15",
            op_value: 'Up with rail; unable down 15',
          },
          {
            op_key: "0",
            op_value: 'Unable 0',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Functional Deductions',
      name: 'functional_deductions',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: "0",
            op_value: 'None used 0',
          },
          {
            op_key: "-5",
            op_value: 'Cane -5',
          },
          {
            op_key: "-10",
            op_value: 'Two canes -10',
          },
          {
            op_key: "-20",
            op_value: 'Crutches or walker -20',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: 'Total Function Score',
      name: 'total_function_score',
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },

  ],
  // isSubmitButton: true,
  // isDisplayInlineError: true,
  // submitButton: {
  //   type: configuration.FORM.FIELD.BUTTON,
  //   label: 'Submit',
  //   name: 'submit',
  //   attr: {
  //     type: 'submit',
  //     color: configuration.FORM.BUTTON.COLOR.PRIMARY,
  //   },
  //   buttons: [],
  // },
};
export default FormKneePrimarySocietyScoreFunction;

