import { Component, OnInit, QueryList, ViewChildren  } from '@angular/core';
import FormHipRadiologicalEvaluations from '../helpers/forms/hip-radiological-evaluations-form';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormPreopClinicalDetails from '../helpers/forms/knee-preop-clinical-details-form';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-hip-radiological-evaluations',
  templateUrl: './hip-radiological-evaluations.component.html',
  styleUrls: ['./hip-radiological-evaluations.component.css']
})
export class HipRadiologicalEvaluationsComponent implements OnInit {
  _formHipRadiologicalEvaluations = FormHipRadiologicalEvaluations;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  id = "";
  currentData = null;
  modal = "";
  patientData = null;
  arrayColumns = ["avn", "protrusio", "ddh"];

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    this.shared.currentPage = "hip";
  }

  ngOnInit(): void {
    this.getPatientData();
  }

  async getPatientData() {
    let json2 = {
      where: {
        id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json2);
    if (res && res.data && res.data[0]) {
      this.patientData = res.data[0];
      console.log(this.router.url);
      let json = {
        where: {
          patient_id: this.id
        }
      };
      res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.HIP_RADIOLOGY, json);
      if (res && res.data && res.data[0]) {
        this.currentData = {}; //res.data[0];
        // for(let r of res.data[0]) {
          for(let tdKey of Object.keys(res.data[0])) {
            if(this.arrayColumns.indexOf(tdKey) >= 0) {
              try {
                  let arrC = JSON.parse(res.data[0][tdKey]);
                  if(arrC.length != 0) {
                    this.currentData[tdKey] = arrC;
                  }
              } catch (e) {
                console.log("error", e);
              }
            } else {
              if(res.data[0][tdKey]) {
                this.currentData[tdKey] = res.data[0][tdKey];
              }
            }
          }
        // }
        setTimeout(async () => {
          let formComponent = this.formComponent.toArray();
          if (formComponent && formComponent[0]) {
            formComponent[0].setAllFormFieldValue(this.currentData);
            this.showHideRevisionFields(formComponent[0]);
          }
        });
      } else {
        setTimeout(async () => {
          let formComponent = this.formComponent.toArray();
          if (formComponent && formComponent[0]) {
            this.showHideRevisionFields(formComponent[0]);
          }
        });
      }
    }
  }

  showHideRevisionFields(formComponent) {
    console.log("formComponent", formComponent.formParam);
    if(formComponent.formParam && formComponent.formParam.fields) {
      for(let f of formComponent.formParam.fields) {
        if(f.is_revision && this.patientData.patient_sub_type == 0) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
        if(f.is_primary && this.patientData.patient_sub_type == 1) {
          console.log(f.name, f.type);
          formComponent.setFormFieldAttr(f.name, "isDisplay", false);
        }
      }
    }
  }

  submitForm() {
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        let values = formComponent[0].getFormValue();
        values = JSON.parse(JSON.stringify(values.form.value));
        values.patient_id = this.id;
        values.side = this.patientData.surgery_side
        this.shared.isLoader = true;
        if (this.currentData) {
          await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.HIP_RADIOLOGY, values, this.currentData.id);
        } else {
          await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.HIP_RADIOLOGY, values);
        }
        this.shared.isLoader = false;
        window.location.href = '/hip-intraoperative/'+this.id;
      }
    });
}
}
