import { environment } from 'src/environments/environment';
import { API_ENDPOINT } from './endpoint';

export class configuration {

    static API_URL = environment.API_URL;

    static IMAGE_URL = environment.IMAGE_URL;

    static SOFT_TYPE = 2;  // 1 = Clinic Soft, 2 = Other Soft, 3 = For resort
    static DOCTOR_DEPARTMENT_ID = 2;  // 1 = Clinic Soft, 2 = Other Soft, 3 = For resort

    static API_ENDPOINT = API_ENDPOINT;



    static FORM = {
        FIELD: {
            TEXT: "TEXT",
            SELECT_TEXT: "SELECT_TEXT",
            NOTE: "NOTE",
            SELECT: "SELECT",
            BUTTON: "BUTTON",
            CHECKBOX: "CHECKBOX",
            DATEPICKER: "DATEPICKER",
            TIMEPICKER: "TIMEPICKER",
            TIMEPICKER_DURATION: "TIMEPICKER_DURATION",
            EMAIL: "EMAIL",
            FILE: "FILE",
            NUMBER: "NUMBER",
            PASSWORD: "PASSWORD",
            RADIO: "RADIO",
            AUTOCOMPLETE: "AUTOCOMPLETE",
            BIRTHDATE: "BIRTHDATE",
            IDPROOF: "IDPROOF",
            ADDRESS: "ADDRESS",
            MOBILE: "MOBILE",
            TEXTAREA: "TEXTAREA",
            NOOFPERSON: "NOOFPERSON",
            DATEANDTIME: "DATEANDTIME",
            GRID: "grid",
            PAYMENT_BOX_WITH_AMOUNT: "PAYMENT_BOX_WITH_AMOUNT",
            SPECIAL_OPD_CHARGE: "SPECIAL_OPD_CHARGE",
        },
        BUTTON: {
            COLOR: {
                PRIMARY: "primary",
                SECONDARY: "secondary",
                SUCCESS: "success",
                DANGER: "danger",
                WARNING: "warning",
                INFO: "info",
                LIGHT: "light",
                DARK: "dark"
            }
        }
    }

    static previewFileExtensions = ['png', 'jpg', 'jpeg', 'gif', 'webp', 'mp4', 'mov', 'mpeg', 'mp3'];

    static fileExtensions = [
        ['png', 'jpg', 'jpeg', 'gif', 'webp'],
        ['mp4', 'mov', 'mpeg'],
        ['mp3'],
        ['pdf'],
    ];

    static DEFAULT = {
        COUNTRY_ID: 101,
        DATE_FORMAT: "dd/MM/YY"
    }

    static REQUEST = {
        POST: "post",
        PUT: "put",
        DELETE: "delete",
        GET: "get"
    }

    static SEX_NAME = {
        1: "Male",
        2: "Female"
    }

    static SALUTATION = [
        { op_key: "0", op_value: 'Mr.' },
        { op_key: "1", op_value: 'Mrs.' },
        { op_key: "2", op_value: 'Ms' },
        { op_key: "3", op_value: 'Miss' },
        { op_key: "4", op_value: 'Dr.' },
        { op_key: "10", op_value: 'Master' },
        { op_key: "11", op_value: 'Baby' },
    ];

    static SALUTATION_BY_ID = {
        "0": 'Mr.',
        "1": 'Mrs.',
        "2": 'Ms',
        "3": 'Miss',
        "4": 'Dr.',
        "10": 'Master',
        "11": 'Baby',
    };

    static APPOINTMENT_STATUS_CLASS = {
        1: { text: "text-info", bg: "bg-info text-white" },
        2: { text: "text-danger", bg: "bg-danger text-white" },
        3: { text: "text-warning", bg: "bg-warning text-white" },
        4: { text: "text-success", bg: "bg-success text-white" },
    }

    static TIME_SLOT = [{
        op_key: "5",
        op_value: '5 Min'
    }, {
        op_key: "10",
        op_value: '10 Min'
    }, {
        op_key: "15",
        op_value: '15 Min'
    }, {
        op_key: "20",
        op_value: '20 Min'
    }, {
        op_key: "30",
        op_value: '30 Min'
    }, {
        op_key: "60",
        op_value: '60 Min'
    }];

    static MONTH = [{
        op_key: "01",
        op_value: 'JAN'
    }, {
        op_key: "02",
        op_value: 'FEB'
    }, {
        op_key: "03",
        op_value: 'MAR'
    }, {
        op_key: "04",
        op_value: 'APR'
    }, {
        op_key: "05",
        op_value: 'MAY'
    }, {
        op_key: "06",
        op_value: 'JUN'
    }, {
        op_key: "07",
        op_value: 'JUL'
    }, {
        op_key: "08",
        op_value: 'AUG'
    }, {
        op_key: "09",
        op_value: 'SEP'
    }, {
        op_key: "10",
        op_value: 'OCT'
    }, {
        op_key: "11",
        op_value: 'NOV'
    }, {
        op_key: "12",
        op_value: 'DEC'
    }];

    static PAYMENT_MODE = [{
        op_key: 1,
        op_value: 'Cash'
    }, {
        op_key: 2,
        op_value: 'Card'
    }];
    static TEMPERATURE = [{
        op_key: 1,
        op_value: 'None'
    }, {
        op_key: 2,
        op_value: '0-10 °'
    },
    {
        op_key: 3,
        op_value: '10-20 °'
    },
    {
        op_key: 4,
        op_value: '20-30 °'
    },
    {
        op_key: 5,
        op_value: '>30 °'
    }];
    static APPOINTMENT_TYPE = [{
        op_key: 1,
        op_value: 'Consultation'
    }, {
        op_key: 2,
        op_value: 'Therapy'
    }];

    static BOOKING_STATUS = [{
        op_key: 1,
        op_value: 'Confirm'
    }, {
        op_key: 2,
        op_value: 'Tentative'
    }];

    static BOOKED_BY = [{
        op_key: 1,
        op_value: 'Self'
    }, {
        op_key: 2,
        op_value: 'Other'
    }];
    static DEGREE = [{
        op_key: 0,
        op_value: 'None',
    },
    {
        op_key: 0.1,
        op_value: '0 > 5',
    },
    {
        op_key: 0.2,
        op_value: '5 > 10',
    },
    {
        op_key: 0.3,
        op_value: '10 > 15',
    },];
    static DEGREE_0 = [{
        op_key: 0,
        op_value: 'None',
    },
    {
        op_key: 0.05,
        op_value: '0 > 5',
    },
    {
        op_key: 0.1,
        op_value: '5 > 10',
    },
    {
        op_key: 0.15,
        op_value: '10 > 15',
    },];
    static MODERATE = [{
        op_key: 3,
        op_value: 'None'
    }, {
        op_key: 2,
        op_value: 'Minimal'
    },
    {
        op_key: 1,
        op_value: 'Moderate'
    },
    {
        op_key: 0,
        op_value: 'Marked'
    }];

    static MODERATE_STRING = [{
        op_key: "1",
        op_value: 'None'
    }, {
        op_key: "2",
        op_value: 'Minimal'
    },
    {
        op_key: "3",
        op_value: 'Moderate'
    },
    {
        op_key: "4",
        op_value: 'Marked'
    }];

    static YES_NO = [{
        op_key: 1,
        op_value: 'Yes'
    }, {
        op_key: 0,
        op_value: 'No'
    }];

    static YES_NO_NUMBER = [{
        op_key: 1,
        op_value: 'Yes'
    }, {
        op_key: 2,
        op_value: 'No'
    }];

    static YES_NO_STRING = [{
        op_key: "1",
        op_value: 'Yes'
    }, {
        op_key: "2",
        op_value: 'No'
    }];

    static YES_NO_CEMENT = [{
        op_key: 5,
        op_value: 'Yes'
    }, {
        op_key: 4,
        op_value: 'No'
    },
    {
        op_key: 3,
        op_value: 'Cemented'
    },
    {
        op_key: 2,
        op_value: 'Uncemented'
    },
    {
        op_key: 1,
        op_value: 'Hybrid'
    },
    {
        op_key: 0,
        op_value: 'Off-set'
    }];

    static CEMENT_OPTIONS = [{
        op_key: "1",
        op_value: 'Cemented'
    },
    {
        op_key: "2",
        op_value: 'Uncemented'
    },
    {
        op_key: "3",
        op_value: 'Hybrid'
    },
    {
        op_key: "4",
        op_value: 'Off-set'
    }];

    static INTACT = [{

        op_key: 1,
        op_value: 'Intact',
    },
    {
        op_key: 2,
        op_value: 'Linear striation',
    },
    {
        op_key: 3,
        op_value: 'Ruptured',
    }];
    static PATHOLOGICAL = [{

        op_key: "1",
        op_value: 'Normal',
    },
    {
        op_key: "2",
        op_value: 'Pathological',
    }];
    static CEMENTED = [{
        op_key: "1",
        op_value: 'Plain',
    },
    {
        op_key: "2",
        op_value: 'Antibiotic',
    }];

    static VISCOSITY = [{
        op_key: "1",
        op_value: 'Low',
    },
    {
        op_key: "2",
        op_value: 'Medium',
    },
    {
        op_key: "3",
        op_value: 'High',
    }];
    static YES_NO_LOCAL = [{
        op_key: "4",
        op_value: 'Yes'
    }, {
        op_key: "3",
        op_value: 'No'
    },
    {
        op_key: "2",
        op_value: 'Local'
    },
    {
        op_key: "1",
        op_value: 'IV'
    },
    {
        op_key: "0",
        op_value: 'Both'
    }
    ];

    static LOCAL_IV_OPTIONS = [
    {
        op_key: "1",
        op_value: 'Local'
    },
    {
        op_key: "2",
        op_value: 'IV'
    },
    {
        op_key: "3",
        op_value: 'Both'
    }
    ];
    static YES_0_NO_100 = [{
        op_key: "0",
        op_value: 'Yes (0)',
        div_val : 4
    }, {
        op_key: "100",
        op_value: 'No (100)',
        div_val : 4
    }];
    static YES_0_NO_100_1 = [{
        op_key: "0",
        op_value: 'Yes (0)',
        div_val : 3
    }, {
        op_key: "100",
        op_value: 'No (100)',
        div_val : 3
    }];
    static LIMITED = [{
        op_key: "0",
        op_value: 'Yes, Limited a Lot (0)',
        div_val : 10
    }, {
        op_key: "50",
        op_value: 'Yes, Limited a Little (50)',
        div_val : 10
    },
    {
        op_key: "100",
        op_value: 'No, Not limited at All (100)',
        div_val : 10
    }];
    static POSITION = [{
        op_key: "5",
        op_value: 'None (5)'
    }, {
        op_key: "3",
        op_value: 'Slight (3)'
    },
    {
        op_key: "0",
        op_value: 'Severe (0)'
    }];
    static RATING = [{
        op_key: "100",
        op_value: 'Not at all (100)',
        div_val : 2
    }, {
        op_key: "75",
        op_value: 'Slightly (75)',
        div_val : 2
    },
    {
        op_key: "50",
        op_value: 'Moderately (50)',
        div_val : 2
    },
    {
        op_key: "25",
        op_value: 'Quite a bit (25)',
        div_val : 2
    },
    {
        op_key: "0",
        op_value: 'Extremely (0)',
        div_val : 2
    },];
    static ALL_TIME_100_0 = [{
        op_key: "100",
        op_value: 'All of the Time (100)',
        div_val : 4
    }, {
        op_key: "80",
        op_value: 'Most of the Time (80)',
        div_val : 4
    },
    {
        op_key: "60",
        op_value: 'A Good Bit of the Time (60)',
        div_val : 4
    },
    {
        op_key: "40",
        op_value: 'Some of the Time (40)',
        div_val : 4
    },
    {
        op_key: "20",
        op_value: 'A Little of the Time (20)',
        div_val : 4
    },
    {
        op_key: "0",
        op_value: 'None of the Time (0)',
        div_val : 4
    },
    ];
    static ALL_TIME_100_1 = [{
        op_key: "100",
        op_value: 'All of the Time (100)',
        div_val : 5
    }, {
        op_key: "80",
        op_value: 'Most of the Time (80)',
        div_val : 5
    },
    {
        op_key: "60",
        op_value: 'A Good Bit of the Time (60)',
        div_val : 5
    },
    {
        op_key: "40",
        op_value: 'Some of the Time (40)',
        div_val : 5
    },
    {
        op_key: "20",
        op_value: 'A Little of the Time (20)',
        div_val : 5
    },
    {
        op_key: "0",
        op_value: 'None of the Time (0)',
        div_val : 5
    },
    ];
    static ALL_TIME_0_100 = [{
        op_key: "0",
        op_value: 'All of the Time (0)',
        div_val : 5
    }, {
        op_key: "20",
        op_value: 'Most of the Time (20)',
        div_val : 5
    },
    {
        op_key: "40",
        op_value: 'A Good Bit of the Time (40)',
        div_val : 5
    },
    {
        op_key: "60",
        op_value: 'Some of the Time (60)',
        div_val : 5
    },
    {
        op_key: "80",
        op_value: 'A Little of the Time (80)',
        div_val : 5
    },
    {
        op_key: "100",
        op_value: 'None of the Time (100)',
        div_val : 5
    },
    ];
    static ALL_TIME_0_100_1 = [{
        op_key: "0",
        op_value: 'All of the Time (0)',
        div_val : 4
    }, {
        op_key: "20",
        op_value: 'Most of the Time (20)',
        div_val : 4
    },
    {
        op_key: "40",
        op_value: 'A Good Bit of the Time (40)',
        div_val : 4
    },
    {
        op_key: "60",
        op_value: 'Some of the Time (60)',
        div_val : 4
    },
    {
        op_key: "80",
        op_value: 'A Little of the Time (80)',
        div_val : 4
    },
    {
        op_key: "100",
        op_value: 'None of the Time (100)',
        div_val : 4
    },
    ];
    static ALL_TIME_0_100_2 = [{
        op_key: "0",
        op_value: 'All of the Time (0)',
        div_val : 2
    }, {
        op_key: "20",
        op_value: 'Most of the Time (20)',
        div_val : 2
    },
    {
        op_key: "60",
        op_value: 'Some of the Time (60)',
        div_val : 2
    },
    {
        op_key: "80",
        op_value: 'A Little of the Time (80)',
        div_val : 2
    },
    {
        op_key: "100",
        op_value: 'None of the Time (100)',
        div_val : 2
    },
    ];
    static TRUE_FALSE_0_100 = [{
        op_key: "0",
        op_value: 'Definitely true (0)',
        div_val : 5
    }, {
        op_key: "20",
        op_value: 'Mostly true (25)',
        div_val : 5
    },
    {
        op_key: "60",
        op_value: 'Dont know (50)',
        div_val : 5
    }, {
        op_key: "80",
        op_value: 'Mostly false (75)',
        div_val : 5
    }, {
        op_key: "100",
        op_value: 'Definitely false (100)',
        div_val : 5
    }];
    static TRUE_FALSE_0_100_1 = [{
        op_key: "0",
        op_value: 'Definitely true (0)',
        div_val : 5
    }, {
        op_key: "25",
        op_value: 'Mostly true (25)',
        div_val : 5
    },
    {
        op_key: "50",
        op_value: 'Dont know (50)',
        div_val : 5
    }, {
        op_key: "75",
        op_value: 'Mostly false (75)',
        div_val : 5
    }, {
        op_key: "100",
        op_value: 'Definitely false (100)',
        div_val : 5
    }];
    static TRUE_FALSE_100_0 = [{
        op_key: "100",
        op_value: 'Definitely true (100)',
        div_val : 5
    }, {
        op_key: "75",
        op_value: 'Mostly true (75)',
        div_val : 5
    },
    {
        op_key: "50",
        op_value: 'Dont know (50)',
        div_val : 5
    }, {
        op_key: "25",
        op_value: 'Mostly false (25)',
        div_val : 5
    }, {
        op_key: "0",
        op_value: 'Definitely false (0)',
        div_val : 5
    }];
    static TROUBLE = [{
        op_key: "1",
        op_value: 'No trouble at all'
    }, {
        op_key: "2",
        op_value: 'Very little trouble'
    },
    {
        op_key: "3",
        op_value: 'Moderate trouble'
    }, {
        op_key: "4",
        op_value: 'Extreme difficulty'
    }, {
        op_key: "5",
        op_value: 'Impossible to do'
    }];
    static RARELY = [{
        op_key: "1",
        op_value: 'Rarely / never'
    }, {
        op_key: "2",
        op_value: 'Sometimes or just at first'
    },
    {
        op_key: "3",
        op_value: 'Often, not just at first'
    }, {
        op_key: "4",
        op_value: 'Most of the time'
    }, {
        op_key: "5",
        op_value: 'All of the time'
    }];
    static DIFFICULTY = [{
        op_key: "1",
        op_value: 'Yes, easily'
    }, {
        op_key: "2",
        op_value: 'With little difficulty'
    },
    {
        op_key: "3",
        op_value: 'With moderate difficulty'
    }, {
        op_key: "4",
        op_value: 'With extreme difficulty'
    }, {
        op_key: "5",
        op_value: 'No, impossible'
    }];
    static VALUE = [{
        op_key: "0",
        op_value: '0'
    }, {
        op_key: "1",
        op_value: '1'
    },
    {
        op_key: "2",
        op_value: '2'
    }, {
        op_key: "3",
        op_value: '3'
    }, {
        op_key: "4",
        op_value: '4'
    }];
    static SATISFACTION_DATE = [{
        op_key: 1,
        op_value: 'Excellently'
    }, {
        op_key: 2,
        op_value: 'Very well'
    },
    {
        op_key: 3,
        op_value: 'Well'
    }, {
        op_key: 4,
        op_value: 'Fairly'
    }, {
        op_key: 5,
        op_value: 'Poorly'
    },
    {
        op_key: 6,
        op_value: 'Dont know'
    }];
    static SATISFACTION_PATIENT = [{
        op_key: 1,
        op_value: 'Definitely Yes'
    }, {
        op_key: 2,
        op_value: 'Possibly Yes'
    },
    {
        op_key: 3,
        op_value: 'Probably not'
    }, {
        op_key: 4,
        op_value: 'Certainly not'
    }, {
        op_key: 5,
        op_value: 'Not sure'
    }
    ];
    static NORMAL = [{
        op_key: 1,
        op_value: 'Normal'
    }, {
        op_key: 0,
        op_value: 'Dislocated'
    }];

    static USE = [{
        op_key: "1",
        op_value: 'Disposable'
    }, {
        op_key: "2",
        op_value: 'Reusable'
    },
    {
        op_key: "3",
        op_value: 'Both'
    }];
    static HOSPITAL_ACCOUNT = [{
        op_key: 1,
        op_value: 'Hospital'
    }, {
        op_key: 2,
        op_value: 'Trust'
    }];

    static MEDICINE_DAYS = [{
        op_key: 0,
        op_value: 'All'
    }, {
        op_key: 7,
        op_value: '7'
    }, {
        op_key: 14,
        op_value: '14'
    }, {
        op_key: 21,
        op_value: '21'
    }, {
        op_key: 28,
        op_value: '28'
    }];

    static MALE_FEMALE = [{
        op_key: 1,
        op_value: 'Male'
    }, {
        op_key: 2,
        op_value: 'Female'
    }];

    static ENABLE_DISABLE = [{
        op_key: 1,
        op_value: 'Enable'
    }, {
        op_key: 0,
        op_value: 'Disable'
    }];

    static AUTO_MANUAl = [{
        op_key: 1,
        op_value: 'Auto'
    }, {
        op_key: 0,
        op_value: 'Manual'
    }];

    static GST = [{
        op_key: 1,
        op_value: 'SGST(%)/CGST(%)'
    }, {
        op_key: 2,
        op_value: 'IGST(%)'
    }];


    static BOOKING_MODE = [{
        op_key: 1,
        op_value: 'Telephone'
    }, {
        op_key: 2,
        op_value: 'Walk In'
    }, {
        op_key: 3,
        op_value: 'Online'
    }, {
        op_key: 4,
        op_value: 'Email'
    }];

    static OPERATED = [{
        op_key: "left",
        op_value: 'Left'
    }, {
        op_key: "right",
        op_value: 'Right'
    }, {
        op_key: "bi",
        op_value: 'Bi-Lateral'

    }];

    static OPERATED_KNEE_VALUE = {
        1: "left",
        2: "right",
        3: "bi",
    };

    static APPROVAL_CHECKBOX = [{
        op_key: 1,
        op_value: 'N/A'
    }, {
        op_key: 2,
        op_value: 'Admin'
    }, {
        op_key: 3,
        op_value: 'Admin+Treasurer'

    }];

    static STORE_UNIT = [
        { op_key: 1, op_value: "nos" },
        { op_key: 6, op_value: "pkt" },
        { op_key: 7, op_value: "box" },
        { op_key: 8, op_value: "bottle" },
        { op_key: 9, op_value: "strip" },
        { op_key: 0, op_value: "gram" },
        { op_key: 2, op_value: "ltr" },
        { op_key: 3, op_value: "ml" },
        { op_key: 4, op_value: "mg" },
        { op_key: 5, op_value: "kg" },
        { op_key: 11, op_value: "inch" },
        { op_key: 12, op_value: "cm" },
        { op_key: 13, op_value: "mtr" },
        { op_key: 14, op_value: "ft" },
        { op_key: 15, op_value: "sq. mtr" },
        { op_key: 16, op_value: "sq. ft" }
    ];

    static APPOINTMENT_STATUS = {
        SCHEDULED: 1,
        WAITING: 2,
        ENGAGED: 3,
        CHECKOUT: 4
    }



    static APPOINTMENT_STATUS_COLOR = {
        1: 'color-info',
        2: 'color-red',
        3: 'color-yellow',
        4: 'color-green'
    }

    // Room Colors 
    static ROOM_COLOR = {
        SELECTED: "room-selected",
    }

    // CRUD Actions
    static CRUD_ACTION = {
        ADD: "add",
        EDIT: "edit",
        DELETE: "delete",
        VIEW: "view"
    }

    // Table Names
    static TABLE = {
        PATIENT: "patient",
        PATIENT_DOCUMENT: "patient_document",
        KNEE_PRE_OP: "knee_pre_op",
        HIP_PRE_OP: "hip_preop",
        HIP_POSTOP: "hip_postop",
        HIP_INTRAOP: "hip_intraop",
        HIP_RADIOLOGY: "hip_radiology",
        HIP_HARRIS_SCORE: "hip_harris_score",
        HIP_POSTOP_HARRIS_SCORE: "hip_postop_harris_score",
        HIP_POST_OP_SFTHREESIX: "hip_postop_sfthreesix",
        HIP_PRE_OP_SFTHREESIX: "hip_preop_sfthreesix",
        HIP_PRE_OP_SATISFACTION_SCORE: "hip_preop_satisfaction_score",
        HIP_POST_OP_SATISFACTION_SCORE: "hip_postop_satisfaction_score",
        HIP_POSTOP_SCORES: "hip_postop_scores",
        KNEE_RADIOLOGY: "knee_radiology",
        DOCTOR_LIST: "doctor_list",
        USER: "user",
        KNEE_PRE_OP_SOCIETY_SCORE: "knee_preop_society_score",
        KNEE_PRE_OP_WOMAC_SCORE: "knee_preop_womac_score",
        KNEE_PRE_OP_SFTHREESIX: "knee_preop_sfthreesix",
        KNEE_PRE_OP_KUJALA_SCORE: "knee_preop_kujala_score",
        KNEE_PRE_OP_OXFORD_SCORE: "knee_preop_oxford_score",
        KNEE_POST_OP_SOCIETY_SCORE: "knee_postop_society_score",
        KNEE_POST_OP_WOMAC_SCORE: "knee_postop_womac_score",
        KNEE_POST_OP_SFTHREESIX: "knee_postop_sfthreesix",
        KNEE_POST_OP_KUJALA_SCORE: "knee_postop_kujala_score",
        KNEE_POST_OP_OXFORD_SCORE: "knee_postop_oxford_score",
        KNEE_POST_OP_SATISFACTION_SCORE: "knee_postop_satisfaction_score",
        KNEE_POSTOP_SCORES: "knee_postop_scores",
        KNEE_INTRAOP: "knee_intraop",
        KNEE_POSTOP: "knee_postop",
        HIP_COMPANY: "siaa_hip_company",
        HIP_MODEL: "siaa_hip_model",
        HOSPITAL: "hospital",
        ADMIN_BRANCH:"siaa_admin_branch_details"
    }

    static NINDRA_OPTIONS = [
        "अल्प", "विषम", "विपयर्य", "स्वपनवत", "अत्यधिक", "तन्द्रायुक्त", "सम्यक्"
    ];

    static AATARV_OPTIONS = [
        "सम", "विषमप्रवृति", "अल्प रज : प्रवुति", "अति रज : प्रवुति", "रज :क्षय", "वेदनायुक्त", "ग्रथित"
    ];

    // Association
    static TABLE_ASSOCIATION = {
        ROOM_TO_ROOM_CATEGORY: "RoomToRoomCategory"
    }

    // Local storage names
    static LOCAL_STORAGE = {
        USER_TOKEN: "sims_siaa_user_token",
        USER_NAME: "sims_siaa_user_name",
        USER_DEPARTMENT: "sims_siaa_user_department",
        USER_FOLDER: "sims_siaa_user_folder",
        USER_COUNTRY: "sims_siaa_user_country",
        USER_ENV: "sims_siaa_user_env",
        HOSPITAL_ID : "sims_siaa_hospital_id",
        HOSPITAL_NAME: "sims_siaa_hospital_name",
        USER_GROUP_ID: "sims_siaa_user_group_id",
        USER_ID : "sims_user_id",
        BRANCH_TOKEN:"sims_siaa_branch_token",
        BRANCH_USER_NAME:"sims_siaa_branch_user_name",
        BRANCH_ID:"sims_siaa_branch_id",
        BRANCH_EMAIL:"sims_siaa_branch_email",
        ADMIN_USER_NAME:"sims_siaa_user_name",
        ADMIN_ID:"sims_siaa_admin_id",
        ADMIN_TOKEN:"sims_siaa_admin_token"
    }

    static API_TYPE = {
        COMMON: "common",
        COMMON_CLIENT: "common_client",
        CUSTOM: "custom"
    };

    // ROW ACTIONS
    static ROW_ACTION = {
        EDIT: "edit",
        DELETE: "delete"
    };

    static getAPIURL(url) {
        return this.API_URL + url;
    }

    static MESSAGE = {
        PLEASE_TRY_AGAIN: "Please try again."
    }

    public static getClientWiseData(data) {
        return data;
    }

    public static getLocalStorageValue(key, defaultVal: any = '', isObject = false) {
        const localVal = localStorage.getItem(key);
        if (localVal != null && localVal !== 'undefined') {
            if (isObject) {
                return JSON.parse(localVal);
            } else {
                return localVal;
            }
        } else {
            return defaultVal;
        }
    }

    public static setLocalStorageValue(key, val, isObject = false) {
        if (isObject) {
            val = JSON.stringify(val);
        }
        localStorage.setItem(key, val);
    }

    public static getErrorMessage(res) {
        if (res) {
            return res.message ? res.message : configuration.MESSAGE.PLEASE_TRY_AGAIN;
        }
        return configuration.MESSAGE.PLEASE_TRY_AGAIN;

    }

    public static getSessionStorageValue(key, defaultVal: any = '', isObject = false) {
        const localVal = sessionStorage.getItem(key);
        if (localVal != null && localVal !== 'undefined') {
            if (isObject) {
                return JSON.parse(localVal);
            } else {
                return localVal;
            }
        } else {
            return defaultVal;
        }
    }

    public static setSessionStorageValue(key, val, isObject = false) {
        if (isObject) {
            val = JSON.stringify(val);
        }
        sessionStorage.setItem(key, val);
    }

    public static getTokenValue() {
        const localToken = configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_TOKEN);
        if (localToken != null && localToken && configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.USER_TOKEN) !== 'undefined') {
            return localToken;
        } else {
            const localToken2 = configuration.getSessionStorageValue(configuration.LOCAL_STORAGE.USER_TOKEN);
            if (localToken2 != null && localToken2 && configuration.getSessionStorageValue(configuration.LOCAL_STORAGE.USER_TOKEN) !== 'undefined') {
                return localToken2;
            }
        }
        return false;
    }
}