
export const API_ENDPOINT = {

        CRUD: "common/record/",
        CRUD_QUERY: "common/record-query/",        
        AUTOCOMPLETE: "common/autocomplete/",
        COMMON_DOCTOR_SUB_DEPARTMENT: "common/doctor/sub/department",
        COMMON_DIET_CATEGORY: "common/diet/category",
        DASHBOARD_GETDATA: "dashboard/getData",
        DASHBOARD_INVESTIGATION_BY_ID_DATE: "dashboard/investigation",
        DASHBOARD_XRAY_BY_ID_DATE: "dashboard/xray",
        STORE_REQUEST_ITEMS: "dashboard/store/store_request_items",
        STORE_REQUEST_ITEMS_DISPATCH: "dashboard/store/store_request_items/dispatch",
        STORE_REQUEST_ITEMS_APPROVAL: "dashboard/store/store_request_items/approval",
        ANALYTICS_ACCOUNT_EXPENSE: "analytics/account/expense",
        ANALYTICS_ACCOUNT_INCOME: "analytics/account/income",
        LOGIN: "auth/user/login",
        BRANCH_LOGIN: "admin-branch/log-in",
        ADMIN_LOGIN: "admin/log-in",
        PATIENT_REGISTRATION_PAYMENT_MODELS: "patient/register/paymentmodels",
        PATIENT_REGISTRATION_MODELS: "patient/register/models",
        PATIENT_CHECK_OPD_PATIENT: "patient/check-opd-patient/",
        CLIENT_DELETE: "client/delete",
        CLIENT_GET_SETTINGS: "client/get/setting",
        CLIENT_GET_DOMAIN: "client/get/domain",
        CLIENT_GET_ROOMS: "client/get/rooms",
        CLIENT_GET_UNIT_MASTER: "client/get/unit_master",        
        CLIENT_GET_HISTORY_FORM_DATA: "client/get/historyFormData",
        DEPARTMENT_WISE_USERS: "auth/user/department",
        ACCOUNT_SUMMARY: "account/summary",
        ACCOUNT_ADD_INCOME: "account/add/income",
        ACCOUNT_ADD_EXPENSE: "account/add/expense",
        SAVE_PATIENT_CUSTOM_INVOICE: "account/save/patient/custom/invoice",
        SAVE_PATIENT_RECEIPT_INVOICE: "account/save/patient/receipt/invoice",
        SAVE_PATIENT_CUSTOM_INVOICE_TEMPLATE: "account/save/patient/custom/invoice/template",
        APPOINTMENT_LIST: "appointment/list",
        APPOINTMENT_SAVE: "appointment/create",
        APPOINTMENT_CHANGE_STATUS: "appointment/change/status",
        APPOINTMENT_CREATE_PATIENT: "appointment/create/patient/",
        APPOINTMENT_DELETE: "appointment/delete/",
        PATIENT_DIET: "patient/diet",
        PATIENT_LIST: "patient/list",
        PATIENT_CASE: "patient/case/",
        PATIENT_CASE_RECEIPT: "patient/case/receipt",
        PATIENT_CASE_REGISTER: "patient/case/register",
        PATIENT_CASE_IPD_REGISTER: "patient/register/ipd/patient",        
        PATIENT_CASE_COPY: "patient/case/copy",
        PATIENT_CASE_ADD_TO_WAITING_LIST: "patient/case/addToWaitingList",
        PATIENT_CASE_VISIT: "patient/case_visit/",
        PATIENT_CASE_VISIT_DATE: "patient/case_visit_date/",
        GET_PATIENT_CASE_META: "patient/case_meta/",
        PATIENT_DOCUMENT: "patient/document-upload",
        GET_PATIENT_DOCUMENT: "patient/get-document-list/",        
        PATIENT_GET_INVOICE: "patient/invoice/",
        PATIENT_ADD_REMARK: "patient/addRemark",
        PATIENT_GET_REMARK: "patient/getRemark",
        PATIENT_GET_RECEIPT: "patient/receipt/",
        PATIENT_GET_IPD: "patient/getIPDDetails",
        PATIENT_GET_PAYABLE_DETAILS: "patient/getPayableDetails",
        PATIENT_SAVE_IPD_DISCHARGE_SUMMARY: "patient/saveIPDDischargeSummary",
        PATIENT_GET_IPD_DISCHARGE_SUMMARY: "patient/getIPDDischargeSummary",
        PATIENT_SAVE_PRESCRIPTION: "patient/prescription",
        PATIENT_SAVE_INVESTIGATION: "patient/investigation",
        PATIENT_SAVE_XRAY: "patient/xray",
        PATIENT_UPDATE_IPD_DATE_DETAILS: "patient/updateArrDeptDetails",
        PATIENT_UPDATE_IPDDETAILS: "patient/updateIPDDetails",
        PATIENT_ACTION_CIN: "patient/action/cin",
        PATIENT_SAVE_TREATMENT: "patient/treatment",
        PATIENT_SAVE_PANCHAKARMA: "patient/panchakarma",
        PATIENT_SAVE_PHYSIOTHERAPY: "patient/physiotherapy",
        PATIENT_SAVE_SURGERY: "patient/surgery",
        PATIENT_SAVE_HISTORY: "patient/history",
        PATIENT_SAVE_RECEIPT: "patient/create/receipt",
        PATIENT_ADD_DEPOSIT: "patient/addDeposit",
        PATIENT_ADD_DEPOSIT_WITH_NEW_PATIENT: "patient/addDepositWithNewIPD",
        PATIENT_IPD_COUNT: "account/ipd/department/patient/count",
        PATIENT_IPD_BILL: "account/patient/bill/",
        SAVE_REFUND: "account/save/refund",
        SAVE_REFUND_INTERNAL_MEDICINE: "account/save/refund/internal_medicine",
        PATIENT_IPD_BILL_EDIT: "account/patient/editbill/",
        PATIENT_IPD_BILL_UPDATE: "account/patient/updatebill/",
        PATIENT_IPD_DEPOSIT: "account/patient/deposit/",
        PATIENT_SAVE_VACCINATION: "patient/vaccination",
        PDF_PATIENT_PRESCRIPTION: "pdf/patient/prescription/",
        PDF_COMMON: "pdf/common",
        INVENTORY_MEDICINE_SUGGESTION: "inventory/medicine/suggestion/",
        INVENTORY_STORE_ITEM_SUGGESTION: "inventory/store_item/suggestion/",
        INVENTORY_MEDICINE_TYPE: "inventory/medicine/type",
        INVENTORY_MEDICINE_FOR_COMPOSITION: "inventory/medicine/for/composition",
        INVENTORY_MEDICINE_ADD: "inventory/medicine/add",
        INVENTORY_MEDICINE_LIST: "inventory/medicine/list",
        INVENTORY_STORE_LIST: "inventory/main_store/item/list",
        UPDATE_PATIENT_CASE_META: "patient/updatePatientCaseMeta",
        INQUIRY_SAVE: "patient/inquiry/add",
        INQUIRY_LIST: "patient/inquiry/list",
        PATIENT_SEARCH: "patient/search/",
        VALIDATE_TOKEN: "auth/isTokenValid",
        ADD_STAFF: "client/add/staff",
        GET_STAFF: "client/get/staff",
        CHANGE_PASSWORD: "auth/password",
        EDIT_PROFILE: "client/profile",
        GET_STAFF_BY_SUBDEPT: "client/get/staffBySubDept/",
        GET_DOCTOR_LIST: "client/get/doctorList",
        GET_ACCOUNT_LIST: "client/get/getAccountList",
        GET_STAFF_OF_BALROG: "client/get/balrogDoctor",
        LAB_PATIENT_REPORT_SAVE: "department/lab/patient/report",
        LAB_PATIENT_REPORT_GET: "department/lab/patient/report/get",
        PDF_PATIENT_INVESTIGATION: "pdf/patient/investigation/",
        SMS_SETTING: "client/smsSetting",
        EMAIL_SETTING: "client/emailSetting",
        APOINTMENT_CALENDER: "client/calender/appointment",
        THERAPY_CALENDER: "client/calender/therapy",
        IPD_CALENDER: "client/calender/ipd",
        GET_CLIENT_SETTING: "client/setting/",
        REPORT_SEARCH: "report/search",
        KWATH_MEDICINE: "report/kwath/medicine",        
        REPORT_DOWNLOAD: "report/startDownload",
        REPORT_GET_DOWNLOAD: "report/get/downloads",
        USER_REGISTRATION: " ",
        ENABLE_LOGIN_ACCESS: "client/staff/enable/login",
        DISABLE_LOGIN_ACCESS: "client/staff/disable/login",
        GET_OPD_BILL_DATA: "account/opd/bill/data",
        SAVE_OPD_BILL_DATA: "account/opd/bill/save",
        PATIENT_WISE_IPD_DIET: "dashboard/kitchen/patient_wise_ipd_diet",
        ROOM_STATUS_LIST: "environment/list/room_status",
        ADD_DELIVERY_DETAIL: "patient/addDeliveryDetail",
        GET_DELIVERY_DETAIL: "patient/delivery/detail/",

        GET_PATIENT_SUVARNAPRASAN: "patient/suvarnaprashan/",
        ADD_PHYSICAL_MEASUREMENT_DATA: "patient/addPhysicalMeasurementData",
        GET_PHYSICAL_MEASUREMENT_DATA: "patient/getPhysicalMeasurementData",
        DELETE_PHYSICAL_MEASUREMENT_DATA: "patient/deletePhysicalMeasurementData",
        ADD_VITAL_DATA: "patient/addVitalData",
        GET_VITAL_DATA: "patient/getVitalData",
        DELETE_VITAL_DATA: "patient/deleteVitalData",
        SAVE_SERVICE_DATA: "patient/saveServiceData",
        GET_SERVICE_DATA: "patient/getServiceData",
        IPD_DIET_UPDATE: "patient/updateIPDDietBulk",
        GET_IPD_DIET: "patient/getIPDDiet",
        GET_MEDICINE_COMPOSITION_INFO: "inventory/medicine/composition/",
        GET_INWARD_INFO: "inventory/store/inward/",
        MEDICINE_BILL_INFO: "inventory/store/medicine_bill/",
        SEARCH_STORE_ITEM: "inventory/store/search/item/",
        SEARCH_STORE_ITEM_DEPARTMENT: "inventory/store/search/department_item/",
        INVENTORY_LIST_BY_DEPARTMENT: "inventory/store/item/list",
        STORE_ITEM_BILL: "inventory/store/bill/list",
        STORE_CREATE_ITEM: "inventory/store/create/item",
        STORE_CREATE_INWARD: "inventory/store/create_inward",
        MEDICINE_CREATE_INWARD: "inventory/medicine/create_inward",
        STORE_INWARD_LIST: "inventory/store/inward/list",
        MEDICINE_INWARD_LIST: "inventory/store/medicine/list",
        XRAY_UPLOAD: "dashboard/xray/upload",
        STORE_TICKET_REQUEST: "inventory/store/ticket/request",
        STORE_TICKET_REQUEST_ISSUED: "inventory/store/ticket/request/issued",
        STORE_TICKET_REQUEST_ISSUED_ACTION: "inventory/store/ticket/request/issued/action",
        GET_STORE_ITEM_PACK: "inventory/store/item/pack/",
        GET_STORE_ITEM_BATCH: "inventory/store/item/batch/",
        INVENTORY_STORE_CREATE_BILL: "inventory/store/create_bill",        
        GET_INVENTORY_STORE_BILL: "inventory/store/bill/",        
        GET_NEXT_PATIENTS: "dashboard/next_patient_list",
        GET_WAITING_LIST_ID: "dashboard/waiting_list_id/",
        IPD_ASSIGNED_THERAPY: "ipd/save/assign/therapy",
        IPD_GET_ASSIGNED_THERAPY: "ipd/get/assign/therapy",
        IPD_SAVE_SCHEDULE_THERAPY: "ipd/save/schedule/therapy",
        IPD_GET_SCHEDULE_THERAPY: "ipd/get/schedule/therapy",
        BULK_INSERT_MEDICINE_VALIDATE: "inventory/medicine/bulk/insert/validate",
        BULK_INSERT_MEDICINE: "inventory/medicine/bulk/insert",
        GET_MEDICINE_ITEM_PACK: "inventory/medicine/item/pack/",
        GET_MEDICINE_ITEM_BATCH: "inventory/medicine/item/batch/",
        ADD_MEDICINE_DISCARD: "inventory/medicine/item/discard/",
        MEDICINE_COMPOSITION_CREATE: "inventory/medicine/composition/create",
        GET_SUMMMARY :"patient/get-summary",
        GET_REVISION_SUMMMARY :"patient/get-revision-summary",
        GET_PRIMARY_SUMMMARY :"patient/get-primary-summary",
        GET_PRE_OP_SCORES : "patient/get-pre-op-scores",
        GET_PRIMARY_SEARCH : "patient/get-primary-search",
        GET_REVISION_SEARCH : "patient/get-revision-search",
        GET_STUDY_ID : "patient/get-study-id",        
        GET_COMPANY_OPTION : "common/get-options-company",
        GET_COMPANY_DESIGN : "common/get-options-company-design?id=",
        GET_COMMON_OPTIONS : "common/get-options?name=",

        CREATE_ADMIN_BRANCH:"admin-branch/register",
        CREATE_SURGEON:"auth/user/register",

        CHANGE_BRANCH_PASSWORD:"admin-branch/change-password",
        CHANGE_SURGEON_PASSWORD:"auth/password",
        DOWNLOAD_REVISION_DATA:"patient/get-revision-data"
}