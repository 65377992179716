import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SharedService } from './shared.service';
import { configuration } from '../configuration';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public headers = {};
  public methodType = configuration.REQUEST.POST;
  public URL = '';
  public isBlob = false;
  public isUploadImage = false;
  public isUploadfFavicon = false;

  constructor(public http: HttpClient, public shared: SharedService) { }

  public createAuthorizationHeaderFormData() {
    let headers;
    headers = {
      'Content-Type': 'application/json',
    };

    const token = configuration.getTokenValue();
    if (token !== '') {
      headers.Authorization = 'Bearer ' + token;    
    }

    return headers;
  }

  public handleErrorObservable(error: Response | any) {
    if (error.status && error.status == 401) {
      status = error.status;
      return null;
    } else {
      var response = error.message || error;
      let responseJson = { is_error: true, message: response };
      return responseJson;
    }
  }

  async makeRequest(url, data) {
    this.headers = {};
      this.headers = this.createAuthorizationHeaderFormData();
      this.URL = configuration.getAPIURL(url);


    if (this.isUploadImage) {
      delete this.headers["Content-Type"];
    }
    this.isUploadImage = false;
    switch (this.methodType) {
      case configuration.REQUEST.POST:
        return await this.post(data);
      case configuration.REQUEST.GET:
        return await this.get();
      case configuration.REQUEST.DELETE:
        return await this.delete();
      case configuration.REQUEST.PUT:
        return await this.put(data);
    }
  }

  public async post(data): Promise<any> {
    let requestParam;
    requestParam = {};
    requestParam.headers = this.headers;
    if (this.isBlob) {
      requestParam.responseType = 'blob';
    }
    this.isBlob = false;

    const request = await this.http.post(this.URL, data, requestParam).toPromise().then(
      res => {
        return res;
      }
    ).catch(
      (e: any) => throwError(this.handleErrorObservable(e))
    );
    return request;
  }

  public async put(data): Promise<any> {
    let requestParam;
    requestParam = {};
    requestParam.headers = this.headers;
    if (this.isBlob) {
      requestParam.responseType = 'blob';
    }
    this.isBlob = false;
    const request = await this.http.put(this.URL, data, requestParam).toPromise().then(
      res => {
        return res;
      }
    ).catch(
      (e: any) => throwError(this.handleErrorObservable(e))
    );
    return request;
  }

  public async delete(): Promise<any> {
    let requestParam;
    requestParam = {};
    requestParam.headers = this.headers;
    if (this.isBlob) {
      requestParam.responseType = 'blob';
    }
    this.isBlob = false;
    const request = await this.http.delete(this.URL, requestParam).toPromise().then(
      res => {
        return res;
      }
    ).catch(
      (e: any) => throwError(this.handleErrorObservable(e))
    );
    return request;
  }

  public async get(): Promise<any> {
    let requestParam;
    requestParam = {};
    requestParam.headers = this.headers;
    if (this.isBlob) {
      requestParam.responseType = 'blob';
    }
    this.isBlob = false;
    const request = await this.http.get(this.URL, requestParam).toPromise().then(
      res => {
        return res;
      }
    ).catch(
      (e: any) => throwError(this.handleErrorObservable(e))
    );
    return request;
  }

  async _apiRequest(url, data, callback = null) {
    const response: any = await this.makeRequest(url, data);
    const result = JSON.parse(JSON.stringify(response));
    if (!result.is_error) {
      if(callback) {
        return callback(result);
      }
      return result;
    } else {
      return result;
    }
  }

  // To call PUT request call
  async PUT_apiRequest(url, data, callback = null) {
    this.methodType = configuration.REQUEST.PUT;
    return await this._apiRequest(url, data, callback);    
  }

  // To call POST request call
  async POST_apiRequest(url, data, callback = null) {
    this.methodType = configuration.REQUEST.POST;
    return await this._apiRequest(url, data, callback);
  }

  // To call GET request call
  async GET_apiRequest(url, callback = null) {
    this.methodType = configuration.REQUEST.GET;
    return await this._apiRequest(url, null, callback);
  }

    // To call DELETE request call
  async DELETE_apiRequest(url, callback = null) {
    this.methodType = configuration.REQUEST.DELETE;
    return await this._apiRequest(url, null, callback);
  }

  // To select all record from the collection
  async COMMON_API_getAutocompleteData(collectionName, value) {
    if(value) {
      return await this.GET_apiRequest(configuration.API_ENDPOINT.AUTOCOMPLETE + collectionName + '/' + value)
    } else {
      return { data: [] };
    }

  }

  // To select all record from the collection
  async COMMON_API_getAllRecords(collectionName, url = configuration.API_ENDPOINT.CRUD) {
    return await this.GET_apiRequest(url + collectionName)
  }

  // Get single record
  async COMMON_API_getSingleRecord(collectionName, id, url = configuration.API_ENDPOINT.CRUD) {
    return await this.GET_apiRequest(url + collectionName + '/' + id)
  }

  // Get single record
  async COMMON_API_getSingleRecordByID(collectionName, idname, id, url = configuration.API_ENDPOINT.CRUD) {
    console.log(collectionName, idname, id);
    return await this.GET_apiRequest(url + collectionName + '/' + idname + '/' + id);
  }

  // Insert new record
  async COMMON_API_insertSingleRecord(collectionName, data, url = configuration.API_ENDPOINT.CRUD) {
    return await this.POST_apiRequest(url + collectionName, data);
  }

  // Update record, id will be passed in params
  async COMMON_API_updateSingleRecord(collectionName, data, id, url = configuration.API_ENDPOINT.CRUD) {
    return await this.PUT_apiRequest(url + collectionName + '/' + id, data);
  }

  // Delete a single record
  async COMMON_API_deleteSingleRecord(collectionName, id, url = configuration.API_ENDPOINT.CRUD) {
    return await this.DELETE_apiRequest(url + collectionName + '/' + id);
  }

  // Querying records with conditions and lot more. 
  async COMMON_API_executeQuery(collectionName, query) {
    return await this.POST_apiRequest(configuration.API_ENDPOINT.CRUD_QUERY + collectionName, query);
  }

  async GET_CLIENT_SETTING(where = null) {
    let res = null;
    if(where) {
      res = await this.POST_apiRequest(configuration.API_ENDPOINT.CLIENT_GET_SETTINGS, {name: where});
    } else {
      res = await this.GET_apiRequest(configuration.API_ENDPOINT.CLIENT_GET_SETTINGS);
    }
    return this.getData(res);
  }

  getData(res) {
    if(res && !res.error && res.data) {
      return res.data
    } else {
      return null;
    }
  }

  async getImages(id, step) {
    this.URL = environment.IMAGE_API+id+"&pth=step"+step;
    const response = await this.get();
    const result = JSON.parse(JSON.stringify(response));
    if (!result.is_error) {
      return result;
    } else {
      return result;
    }
  }
}
