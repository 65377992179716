import { configuration } from "src/app/configuration";

const FormHipRadiologicalEvaluations = {
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "",
      name: "avn",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Grading of AVN',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Ficat and Arlet’s Staging",
      name: "ficat_and_arlets_staging",
      displayType: "table",
      is_primary: true,
      options: {
        header: [
          "_",
          "Stage",
          "Symtoms",
          "X-ray",
          "Scintigraphy",
        ],
        data: [
          {
            op_key: "1",
            op_value: '',
            op_value1: '0',
            op_value2: 'None',
            op_value3: 'Normal',
            op_value4: 'Reduced capture',
          },
          {
            op_key: "2",
            op_value: '',
            op_value1: '0',
            op_value2: 'None to mild',
            op_value3: 'Normal',
            op_value4: 'Reduced capture',
          },
          {
            op_key: "3",
            op_value: '',
            op_value1: '2',
            op_value2: 'Mild',
            op_value3: 'Change in density',
            op_value4: 'Increased capture',
          },
          {
            op_key: "4",
            op_value: '',
            op_value1: '2A',
            op_value2: '',
            op_value3: 'Sclerois or cysts',
            op_value4: '',
          },
          {
            op_key: "5",
            op_value: '',
            op_value1: '2B',
            op_value2: '',
            op_value3: 'Applanation (crescent sign)',
            op_value4: '',
          },
          {
            op_key: "6",
            op_value: '',
            op_value1: '3',
            op_value2: 'Mild to moderate',
            op_value3: 'Loss of sphericity',
            op_value4: 'Increased capture',
          },
          {
            op_key: "7",
            op_value: '',
            op_value1: '4',
            op_value2: 'Moderate to serve and/or acetabular changes',
            op_value3: 'Reductiuon of the articular space',
            op_value4: 'Increased capture',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "",
      name: "protrusio",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Protrusio',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "",
      name: "protrusio_value",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Grade 1',
          },
          {
            op_key: "2",
            op_value: 'Grade 2',
          },
          {
            op_key: "3",
            op_value: 'Grade 3',
          },
          {
            op_key: "4",
            op_value: 'Protrusio with superior migration',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "",
      name: "ddh",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'DDH',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Acetabuar component",
      name: "acetabuar_component",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Well fixed',
          },
          {
            op_key: "2",
            op_value: 'Loose',
          },
          {
            op_key: "3",
            op_value: 'Migrated',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Femoral component",
      name: "femoral_component",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Well fixed',
          },
          {
            op_key: "2",
            op_value: 'Loose',
          },
          {
            op_key: "3",
            op_value: 'Subsidence',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Crowe Classification",
      name: "crowe_classification",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Type 1',
            image: "assets/images/newHipFirst1.jpg",
            class: "hip_image"
          },
          {
            op_key: "2",
            op_value: 'Type 2',
            image: "assets/images/newHipFirst2.jpg",
            class: "hip_image"

          },
          {
            op_key: "3",
            op_value: 'Type 3',
            image: "assets/images/newHipFirst3.jpg",
            class: "hip_image"

          },
          {
            op_key: "4",
            op_value: 'Type 4',
            image: "assets/images/newHipFirst4.jpg",
            class: "hip_image"

          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Bone loss: Acetabulam – Paprosky Classification",
      name: "bone_loss_acetabulam",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Type 1',
      image: "assets/images/paproskyClassification1.jpg",

          },
          {
            op_key: "2",
            op_value: 'Type 2A',
      image: "assets/images/paproskyClassification2a.jpg",

          },
          {
            op_key: "3",
            op_value: 'Type 2B',
      image: "assets/images/paproskyClassification2b.jpg",

          },
          {
            op_key: "4",
            op_value: 'Type 2C',
      image: "assets/images/paproskyClassification2c.jpg",

          },  
          {
            op_key: "5",
            op_value: 'Type 3A',
      image: "assets/images/paproskyClassification3a.jpg",
            
          },
          {
            op_key: "6",
            op_value: 'Type 3B',
      image: "assets/images/paproskyClassification3b.jpg",

          },

        ],
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Bone loss: Acetabulam – Paprosky Classification",
      name: "revision_bone_loss_acetabulam",
      is_revision: true,
      image: "assets/images/paproskyClassification.jpg",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Type 1',
          },
          {
            op_key: "2",
            op_value: 'Type 2A',
          },
          {
            op_key: "3",
            op_value: 'Type 2B',
          },
          {
            op_key: "4",
            op_value: 'Type 2C',
          },
          {
            op_key: "5",
            op_value: 'Type 3A',
          },
          {
            op_key: "6",
            op_value: 'Type 3B',
          },

        ],
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Bone loss: Femur Paprosky Classification",
      name: "bone_loss_femur",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Type 1',
           image: "assets/images/femurPaproskyClassification1.jpg",

          },
          {
            op_key: "2",
            op_value: 'Type 2',
            image: "assets/images/femurPaproskyClassification2.jpg",
          },
          {
            op_key: "3",
            op_value: 'Type 3A',
            image: "assets/images/femurPaproskyClassification3a.jpg",
          },
          {
            op_key: "4",
            op_value: 'Type 3B',
            image: "assets/images/femurPaproskyClassification3b.jpg",
          },
          {
            op_key: "5",
            op_value: 'Type 4',
            image: "assets/images/femurPaproskyClassification4.jpg",
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Bone loss: Femur Paprosky Classification",
      name: "revision_bone_loss_femur",
      image: "assets/images/femurPaproskyClassification.jpg",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Type 1',
          },
          {
            op_key: "2",
            op_value: 'Type 2',
          },
          {
            op_key: "3",
            op_value: 'Type 3A',
          },
          {
            op_key: "4",
            op_value: 'Type 3B',
          },
          {
            op_key: "5",
            op_value: 'Type 4',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Femur – Morphotype - Dorr Classification",
      name: "femur_morphotype",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Type A',
      image: "assets/images/newHipSeconda.jpg",

          },
          {
            op_key: "2",
            op_value: 'Type B',
      image: "assets/images/newHipSecondb.jpg",

          },
          {
            op_key: "3",
            op_value: 'Type C',
      image: "assets/images/newHipSecondc.jpg",

          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Preop LLD",
      name: "preop_lld"
    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Osteopenia",
      name: "osteopenia",
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "CT",
      name: "availability_ct",
      offsetLabel: "Availability of",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_STRING
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "MRI",
      name: "availability_mri",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_STRING
      },

    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "CT",
      name: "revision_availability_ct",
      offsetLabel: "Availability of",
      is_revision: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_STRING
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "MRI",
      name: "revision_availability_mri",
      is_revision: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_STRING
      },

    },
  ],

};
export default FormHipRadiologicalEvaluations;
