import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import FormPreopScore from '../helpers/forms/knee-postop-score-form';
import { FormComponent } from '../common/form/form.component';
import { SharedService } from '../service/shared.service';
import { ActivatedRoute } from '@angular/router';
import { configuration } from '../configuration';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-knee-postop-score',
  templateUrl: './knee-postop-score.component.html',
  styleUrls: ['./knee-postop-score.component.css']
})
export class KneePostopScoreComponent implements OnInit {
  _formPreopScore = FormPreopScore;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  currentData = null;
  patientData = null;
  id = "";
  modal = "";
  side = 0;

  scoreJson = {
    "knee": 0,
    "womac": 0,
    "sf36": 0,
    "kujala": 0,
    "oxford": 0,
    "satisfaction": 0
  }

  scores = [JSON.parse(JSON.stringify(this.scoreJson))];
  currentIndex = 0;
  currentScore = 0;


  scoreTables = [
    { label: "knee", table: configuration.TABLE.KNEE_POST_OP_SOCIETY_SCORE },
    { label: "womac", table: configuration.TABLE.KNEE_POST_OP_WOMAC_SCORE },
    { label: "sf36", table: configuration.TABLE.KNEE_POST_OP_SFTHREESIX },
    { label: "kujala", table: configuration.TABLE.KNEE_POST_OP_KUJALA_SCORE },
    { label: "oxford", table: configuration.TABLE.KNEE_POST_OP_OXFORD_SCORE },
    { label: "satisfaction", table: configuration.TABLE.KNEE_POST_OP_SATISFACTION_SCORE },
  ]

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    this.shared.currentPage = "knee";
  }

  ngOnInit(): void {
    this.getPatientData();
  }

  async getPatientData() {
    console.log(this.router.url);
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    if (res1 && res1.data && res1.data[0]) {
      this.patientData = res1.data[0];
      this.side = this.patientData.surgery_side;
    }
    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.KNEE_POSTOP_SCORES, json);
    if (res && res.data && res.data[0]) {
      this.currentData = res.data[0];
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          formComponent[0].setAllFormFieldValue(this.currentData);
        }
      });
    }
    this.getScores();
  }

  getScores() {
    for (let t of this.scoreTables) {
      this.getScoresByTable(t);
    }
  }

  async getScoresByTable(t) {
    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(t.table, json);
    if (res && res.data && res.data[0]) {
      for(let r of res.data) {
        let index = r.index;
        if(!this.scores[index]) {
          this.scores[index] = JSON.parse(JSON.stringify(this.scoreJson));
        }
        this.scores[index][t.label] = r.score;
        console.log("score", t, r);
      }
    }
  }


  submitForm() {
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        let values = formComponent[0].getFormValue();
        values = JSON.parse(JSON.stringify(values.form.value));
        values.patient_id = this.id;
        values.side = this.side
        this.shared.isLoader = true;
        if (this.currentData) {
          await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.KNEE_POSTOP_SCORES, values, this.currentData.id);
        } else {
          await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.KNEE_POSTOP_SCORES, values);
        }
        this.shared.isLoader = false;
        window.location.href = '/dashboard';
      }
    });
  }

  changeScore(e) {
    this.currentScore = e.value;
  }

  changeScoreFinal(e) {
    this.scores[this.currentIndex][e.type] = e.value; 
  }

  openModal(key, i) {
    this.currentIndex = i; 
    this.modal = key;
    this.currentScore = this.scores[i][key] ? this.scores[i][key] : 0;
  }

  addRow() {
    this.scores.push(JSON.parse(JSON.stringify(this.scoreJson)));
  }
}
