import { configuration } from "src/app/configuration";

const FormPreopClinicalDetails = {
  fields: [
    {
      type: configuration.FORM.FIELD.DATEPICKER,
      label: "Date of Surgery",
      name: "date_of_surgery"
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Surgeon",
      name: "surgeon",
      options: {
        API: {
          type: configuration.API_TYPE.COMMON,
          model: configuration.TABLE.USER,
          where: {
            user_group_id: 3,
            user_cred_status: 1,
            add_hospital_id: 1
          }
        },
        op_key: "id",
        op_value: "name",
      }
    },
    {
      type: configuration.FORM.FIELD.NOTE,
      label: "Preoperative Clinical Details",
      name: "",
      class: {
        labelDivClass: "col-md-12 form-inside-label2"
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Operated Knee",
      name: "operated_knee",
      isDisabled: true,
      options: {
        data: configuration.OPERATED
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Bilateral",
      name: "operated_knee_bilateral",
      isDisabled: true,
      is_bilateral: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Simultaneous',
            },
            {
              op_key: "2",
              op_value: 'Staggered',
            },
          ],
      },
    },
    
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Etiology",
      name: "etiology",
      is_primary: true,
      options: {
        data: [
          { op_key: "1", op_value: "Rheumatoid" },
          { op_key: "2", op_value: "Osteoarthritis" },
          { op_key: "3", op_value: "Other inflammatory" },
          { op_key: "4", op_value: "SONK/Necrosis" },
          { op_key: "5", op_value: "Traumatic" },
          { op_key: "6", op_value: "Tumor" },
          { op_key: "7", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Primary diagnosis",
      name: "primary_diagnosis",
      is_revision: true,
      options: {
        data: [
          { op_key: "Osteoarthritis", op_value: "Osteoarthritis" },
          { op_key: "Rheumatoid", op_value: "Rheumatoid" },
          { op_key: "Traumatic", op_value: "Traumatic" },
          { op_key: "Tumor", op_value: "Tumor" },
          { op_key: "Others", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Cause of revision",
      name: "cause_of_revision",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Aseptic Loosening',
          },
          {
            op_key: "2",
            op_value: 'Instability',
          },
          {
            op_key: "3",
            op_value: 'Infection',
          },
          {
            op_key: "4",
            op_value: 'Periprosthetic',
          },
          {
            op_key: "5",
            op_value: 'Stiffness',
          },
          {
            op_key: "6",
            op_value: 'Others',
          },

        ],
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Prev. Prosthetic Type",
      name: "prev_prosthetic_yype",
      is_revision: true,
      options: {
        data: [
          { op_key: "Uni Medial", op_value: "Uni Medial" },
          { op_key: "Uni Lat", op_value: "Uni Lat" },
          { op_key: "CR TKA", op_value: "CR TKA" },
          { op_key: "PS TKA", op_value: "PS TKA" },
          { op_key: "Constrained", op_value: "Constrained" },
          { op_key: "Hinged", op_value: "Hinged" },
          { op_key: "Others", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Company",
      name: "company",
      is_revision: true,
      options: {
        data: [
          { op_key: "Zimmer", op_value: "Zimmer" },
          { op_key: "DePuy", op_value: "DePuy" },
          { op_key: "Stryker", op_value: "Stryker" },
          { op_key: "Smith & Nephew", op_value: "Smith & Nephew" },
          { op_key: "Biomet", op_value: "Biomet" },
          { op_key: "Exactech", op_value: "Exactech" },
          { op_key: "LINK", op_value: "LINK" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Design",
      name: "design",
      is_revision: true,
      options: {
        data: [
          { op_key: "1", op_value: "LPS Mobile Bearing Knee" },
          { op_key: "2", op_value: "NEXGEN LPS" },
          { op_key: "3", op_value: "NEXGEN CR" },
          { op_key: "4", op_value: "NEXGEN LPS Flex" },
          { op_key: "5", op_value: "NEXGEN CR Flex" },
          { op_key: "6", op_value: "NEXGEN Gender LPS Flex" },
          { op_key: "7", op_value: "NEXGEN Gender CR Flex" },
          { op_key: "8", op_value: "PERSONA PS" },
          { op_key: "8", op_value: "PERSONA CR" },
          { op_key: "10", op_value: "PERSONA UC" },
          { op_key: "11", op_value: "NATURAL KNEE-||" },
          { op_key: "12", op_value: "NEXGEN LCCK" },
          { op_key: "13", op_value: "NEXGEN RHK" },
          { op_key: "14", op_value: "TM Knee Shapes & Cones" },
          { op_key: "15", op_value: "ALLPOLY" },
          { op_key: "16", op_value: "Titanium Tray" },
          { op_key: "17", op_value: "CoCr Tray" },
          { op_key: "18", op_value: "Hinged Knee" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Time from Index surgery",
      name: "time_from_index_surgery",
      is_revision: true,
      options: {
        data: [
          { op_key: "< 1m", op_value: "< 1m" },
          { op_key: "< 1y", op_value: "< 1y" },
          { op_key: "1-5 y", op_value: "1-5 y" },
          { op_key: "5-10 y", op_value: "5-10 y" },
          { op_key: "> 10y", op_value: "> 10y" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Previous revision surgeries",
      name: "previous_revision_surgeries",
      is_revision: true,
      options: {
        data: [
          { op_key: "None", op_value: "None" },
          { op_key: "1", op_value: "1" },
          { op_key: "2", op_value: "2" },
          { op_key: "3", op_value: "3" },
          { op_key: "> 3", op_value: "> 3" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.DATEPICKER,
      label: "Date of previous knee surgery",
      name: "date_of_previous_knee_surgery",
      is_revision: true,
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Pain at rest',
      name: 'pain_at_rest',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Yes',
            },
            {
              op_key: "2",
              op_value: 'No',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Previous scar',
      name: 'previous_scar',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Healthy',
            },
            {
              op_key: "2",
              op_value: 'Unhealthy',
            },
            {
              op_key: "3",
              op_value: 'Adherent',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Sinus tract',
      name: 'sinus_tract',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Present',
            },
            {
              op_key: "2",
              op_value: 'Absent',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Previous flaps',
      name: 'previous_flaps',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Yes',
            },
            {
              op_key: "2",
              op_value: 'No',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Deformity",
      name: "deformity",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Absent',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Valgus",
      name: "valgus",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: 1,
            op_value: 'Correctible',
          },
          {
            op_key: 2,
            op_value: 'Partially correctible',
          },
          {
            op_key: 3,
            op_value: 'Fixed',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Varus",
      name: "varus",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: 1,
            op_value: 'Correctible',
          },
          {
            op_key: 2,
            op_value: 'Partially correctible',
          },
          {
            op_key: 3,
            op_value: 'Fixed',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Flexion",
      name: "flexion",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: [
          {
            op_key: 1,
            op_value: 'Correctible',
          },
          {
            op_key: 2,
            op_value: 'Partially correctible',
          },
          {
            op_key: 3,
            op_value: 'Fixed',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Other joint involvement",
      name: "other_joint_involvement",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Oppsite side',
          },
          {
            op_key: "2",
            op_value: 'Hip',
          },
          {
            op_key: "3",
            op_value: 'Shoulder',
          },
          {
            op_key: "4",
            op_value: 'Elbow',
          },
          {
            op_key: "5",
            op_value: 'Hand',
          },
          {
            op_key: "6",
            op_value: 'Spine',
          },

        ],
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Current Symptoms",
      name: "current_symptoms",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Pain',
          },
          {
            op_key: "2",
            op_value: 'Anterior Knee Pain',
          },
          {
            op_key: "3",
            op_value: 'Weakness',
          },
          {
            op_key: "4",
            op_value: 'Swelling',
          },
          {
            op_key: "5",
            op_value: 'Locking',
          },
          {
            op_key: "6",
            op_value: 'Numbness',
          },
          {
            op_key: "7",
            op_value: 'Loss of motion',
          },
          {
            op_key: "8",
            op_value: 'Giving way',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Current Symptoms",
      name: "revision_current_symptoms",
      is_revision: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Pain',
          },
          {
            op_key: "2",
            op_value: 'Clunk',
          },
          {
            op_key: "3",
            op_value: 'Instability',
          },
          {
            op_key: "4",
            op_value: 'Stiffness',
          },
          {
            op_key: "5",
            op_value: 'Discharging sinus',
          },
          {
            op_key: "6",
            op_value: 'Warmth',
          }         
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Onset of Symptoms",
      name: "onset_of_current_symptoms",
      is_primary: true,
      options: {
        data: [
          {
            op_key: 1,
            op_value: '> 1 month',
          },
          {
            op_key: 2,
            op_value: '> 6 months',
          },
          {
            op_key: 3,
            op_value: '> 1 year',
          },
        ]
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Specify no. of years",
      name: "specify_no_of_years",
      isDisabled: true,
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Symptoms aggravated by",
      name: "symptoms_aggravated_by",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Walking',
          },
          {
            op_key: "2",
            op_value: 'Standing',
          },
          {
            op_key: "3",
            op_value: 'Stairs',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Co morbidities",
      name: "co_morbidities",
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Diabetes',
          },
          {
            op_key: "2",
            op_value: 'Hypertension',
          },
          {
            op_key: "3",
            op_value: 'Endocrine',
          },
          {
            op_key: "4",
            op_value: 'Coronary heart disease',
          },
          {
            op_key: "5",
            op_value: 'Hepatitis',
          },
          {
            op_key: "6",
            op_value: 'HIV',
          },
          {
            op_key: "7",
            op_value: 'Lung disease',
          },
          {
            op_key: "8",
            op_value: 'Peripheral vascular disease',
          },
          {
            op_key: "9",
            op_value: 'Rheumatologic condition',
          },
          {
            op_key: "10",
            op_value: 'Parkinsons',
          },
          {
            op_key: "11",
            op_value: 'Others',
          },
          {
            op_key: "12",
            op_value: 'Chronic Kidney Disease',
          },
          {
            op_key: "13",
            op_value: 'HBsAg',
          },
          {
            op_key: "14",
            op_value: 'Type 2',
          },
        ],
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Previous knee surgeries",
      name: "previous_knee_surgeries",
      is_primary: true,
      options: {
        data: [
          {
            op_key: "1",
            op_value: 'Arthroscopy',
          },
          {
            op_key: "2",
            op_value: 'UKR',
          },
          {
            op_key: "3",
            op_value: 'Patellectomy',
          },
          {
            op_key: "4",
            op_value: 'Lateral release',
          },
          {
            op_key: "5",
            op_value: 'Meniscectomy',
          },
          {
            op_key: "6",
            op_value: 'Synovectomy',
          },
          {
            op_key: "7",
            op_value: 'Tib. Osteotomy',
          },
          {
            op_key: "8",
            op_value: 'Others',
          },
        ],
      }
    },
    // {
    //   type: configuration.FORM.FIELD.TEXT,
    //   label: "Date of Tib. Osteotomy surgery",
    //   name: "date_of_tib_osteotomy_surgery",
    // },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Steroid",
      name: "intraarticular_inj_steroid",
      offsetLabel: "Intraarticular Inj",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_NUMBER
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Visco supplement",
      name: "intraarticular_inj_visco_supplement",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options: {
        data: configuration.YES_NO_NUMBER
      }
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Flexion deformity",
      name: "flexion_deformity",
      is_primary: true,
      options: {
        data: configuration.TEMPERATURE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Hyper extension",
      name: "hyper_extension",
      is_primary: true,
      options: {
        data: configuration.TEMPERATURE
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Retro patellar tenderness",
      name: "retro_patellar_tenderness",
      options: {
        data: configuration.YES_NO
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Patellar Tracking",
      name: "patellar_tracking",
      options: {
        data: configuration.NORMAL
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Quadriceps power",
      name: "quadriceps_power",
      options: {
        data: [
          {
            op_key: 1,
            op_value: '> 3/5',
          },
          {
            op_key: 2,
            op_value: '3/5',
          },
          {
            op_key: 3,
            op_value: '< 3/5',
          },

        ],
      }
    },
  ],
  isSubmitButton: false,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: "Save",
    name: "submit",
    attr: {
      type: "submit",
      color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: []
  }
};
export default FormPreopClinicalDetails;
