import { configuration } from "src/app/configuration";

const FormHipPreopClinicalDetails = {
  fields: [
    {
      type: configuration.FORM.FIELD.DATEPICKER,
      label: "Date of Surgery",
      name: "date_surgery"
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Surgeon",
      name: "surgeon",
      options: {
        API: {
          type: configuration.API_TYPE.COMMON,
          model: configuration.TABLE.DOCTOR_LIST
        },
        op_value: "doctor_name",
        op_key: "doctor_id",
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Operated hip",
      name: "operated_hip",
      isDisabled: true,
      options: {
        data: configuration.OPERATED
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Primary diagnosis",
      name: "primary_diagnosis",
      options: {
        API: {
          URL: "common/get-options?name=hip2 Primary diagnosis",
        },
        op_key: "option_value",
        op_value: "option_name",
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      name: "primary_diagnosis_options",
      options: {
        data: [
        ],
        op_key: "option_value",
        op_value: "option_name",
      }
    },
    // {
    //   options: {
    //     data: [
    //       { op_key: "Rheumatoid", op_value: "Rheumatoid" },
    //       { op_key: "Ankylosing spondylitis", op_value: "Ankylosing spondylitis" },
    //       { op_key: "Others", op_value: "Others" },
    //     ]
    //   }
    // },
    // {
    //   options: {
    //     data: [
    //       { op_key: "Old Pyogenic", op_value: "Old Pyogenic" },
    //       { op_key: "Old TB", op_value: "Old TB" },
    //     ]
    //   }
    // },
    // {
    //   type: configuration.FORM.FIELD.SELECT,
    //   label: " ",
    //   name: ""
    // },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Cause of revision",
      name: "cause_of_revision",
      is_revision: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: ' Aseptic Loosening',
          },
          {
            op_key: "2",
            op_value: ' Acetabulum',
          },
          {
            op_key: "3",
            op_value: 'Femur',
          },
          {
            op_key: "4",
            op_value: ' Both',
          },
          {
            op_key: "5",
            op_value: 'Recurrent dislocation',
          },
          {
            op_key: "6",
            op_value: 'Poly wear',
          },
          {
            op_key: "7",
            op_value: 'Periprosthetic',
          },
          {
            op_key: "8",
            op_value: 'Infection',
          },
          {
            op_key: "9",
            op_value: 'Ceramic breakage',
          },
          {
            op_key: "10",
            op_value: 'ALVAL',
          },
          {
            op_key: "11",
            op_value: 'Unexplained Pain',
          },
          {
            op_key: "12",
            op_value: 'Others',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Prev. Prosthetic Type",
      name: "prev_prosthetic_type",
      is_revision: true,
      options: {
        data: [
          { op_key: "1", op_value: "Cemented THR" },
          { op_key: "2", op_value: "Uncemented THR" },
          { op_key: "3", op_value: "Hybrid" },
          { op_key: "4", op_value: "Reversed Hybrid" },
          { op_key: "5", op_value: "Constrained" },
          { op_key: "6", op_value: "Others" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Bearing surface",
      name: "bearing_surface",
      is_revision: true,
      options: {
        data: [
          { op_key: "1", op_value: "MoP" },
          { op_key: "2", op_value: "MoCPE" },
          { op_key: "3", op_value: "MoHCPE" },
          { op_key: "4", op_value: "MoM" },
          { op_key: "5", op_value: "CoP" },
          { op_key: "6", op_value: "Coc" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Company",
      name: "company",
      is_revision: true,
      class: {
        // offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Time from Index Surgery",
      name: "time_from_index_surgery",
      is_revision: true,
      options: {
        data: [
          { op_key: "1", op_value: "< 1m" },
          { op_key: "2", op_value: "< 1y" },
          { op_key: "3", op_value: "1-5 y" },
          { op_key: "4", op_value: "> 10y" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.SELECT,
      label: "Previous revision surgeries",
      name: "previous_revision_surgeries",
      is_revision: true,
      options: {
        data: [
          { op_key: "1", op_value: "None" },
          { op_key: "2", op_value: "1" },
          { op_key: "3", op_value: "2" },
          { op_key: "4", op_value: "3" },
          { op_key: "5", op_value: "> 3" },
        ]
      }
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Current Symptoms",
      name: "revision_current_symptoms",
      is_revision: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Pain',
          },
          {
            op_key: "2",
            op_value: 'Warmth',
          },
          {
            op_key: "3",
            op_value: 'Squeak',
          },
          {
            op_key: "4",
            op_value: 'Instability',
          },
          {
            op_key: "5",
            op_value: 'Pus discharge',
          },
          {
            op_key: "6",
            op_value: 'Trauma',
          }
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Pain at rest',
      name: 'pain_at_rest',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Yes',
            },
            {
              op_key: "2",
              op_value: 'No',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Pain at night',
      name: 'pain_at_night',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Yes',
            },
            {
              op_key: "2",
              op_value: 'No',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Co morbidities",
      name: "revision_co_morbidities",
      is_revision: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Diabetes',
          },
          {
            op_key: "2",
            op_value: 'Hypertension',
          },
          {
            op_key: "3",
            op_value: 'Endocrine',
          },
          {
            op_key: "4",
            op_value: 'Coronary heart disease',
          },
          {
            op_key: "5",
            op_value: 'Hepatitis',
          },
          {
            op_key: "6",
            op_value: 'HIV',
          },
          {
            op_key: "7",
            op_value: 'Lung disease',
          },
          {
            op_key: "8",
            op_value: 'Peripheral vascular disease',
          },
          {
            op_key: "9",
            op_value: 'Rheumatologic condition',
          },
          {
            op_key: "10",
            op_value: 'Others',
          },
          {
            op_key: "11",
            op_value: 'Chronic Kidney Disease',
          },
          {
            op_key: "12",
            op_value: 'HBsAg',
          },          
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Previous incision',
      name: 'previous_incision_first',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Healthy',
            },
            {
              op_key: "2",
              op_value: 'Unhealthy',
            },
            {
              op_key: "3",
              op_value: 'Adherent',
            }
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '',
      name: 'previous_incision_second',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Anterior',
            },
            {
              op_key: "2",
              op_value: 'Lateral',
            },
            {
              op_key: "3",
              op_value: 'Posterior',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Sinus tract',
      name: 'sinus_tract',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: 'Present',
            },
            {
              op_key: "2",
              op_value: 'Absent',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: 'Abductor power',
      name: 'abductor_power',
      is_revision: true,
      options: {
        data:
          [
            {
              op_key: "1",
              op_value: '> 3/5',
            },
            {
              op_key: "2",
              op_value: '3/5',
            },
            {
              op_key: "3",
              op_value: '< 3/5',
            },
          ],
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "ESR",
      name: "esr",
      is_revision: true,
      class: {
        // offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "CRP",
      name: "crp",
      is_revision: true,
      class: {
        // offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    // {
    //   type: configuration.FORM.FIELD.TEXT,
    //   label: "LLD",
    //   name: "lld",
    //   is_revision: true,
    //   class: {
    //     // offsetDivClass: "col-md-3",
    //     labelDivClass: "col-md-3",
    //     inputDivClass: "col-md-6",
    //   },
    // },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Vitamin D Level",
      name: "vitamin_d_level",
      is_revision: true,
      class: {
        // offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Vitamin B12 Level",
      name: "vitamin_b12_level",
      is_revision: true,
      class: {
        // offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Current Symptoms",
      name: "current_symptoms",
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Pain',
          },
          {
            op_key: "2",
            op_value: 'Limp',
          },
          {
            op_key: "3",
            op_value: 'Swelling',
          },
          {
            op_key: "4",
            op_value: 'LLD',
          },
          {
            op_key: "5",
            op_value: 'Numbness',
          },
          {
            op_key: "6",
            op_value: 'Loss of motion',
          },
          {
            op_key: "7",
            op_value: 'Back pain',
          },
          {
            op_key: "8",
            op_value: 'Knee pain',
          },
        ],
      },
    },

    {
      type: configuration.FORM.FIELD.RADIO,
      label: "Onset of Symptoms",
      name: "onset_symptoms",
      is_primary: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: '> 1 month',
          },
          {
            op_key: "2",
            op_value: '> 6 months',
          },
          {
            op_key: "3",
            op_value: '> 1 year',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "Specify no. of years",
      name: "specify_years",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Other joint involvement",
      name: "other_involvement",
      is_primary: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Opposite side',
          },
          {
            op_key: "2",
            op_value: 'knee',
          },
          {
            op_key: "3",
            op_value: 'Spine',
          },
          {
            op_key: "4",
            op_value: 'Shoulder',
          },
          {
            op_key: "5",
            op_value: 'Elbow',
          },
          {
            op_key: "6",
            op_value: 'Hand',
          },
        ],
      },
    },

    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Co morbidities",
      name: "co_morbidities",
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Diabetes',
          },
          {
            op_key: "2",
            op_value: 'Hypertension',
          },
          {
            op_key: "3",
            op_value: 'Endocrine',
          },
          {
            op_key: "4",
            op_value: 'Coronary heart disease',
          },
          {
            op_key: "5",
            op_value: 'Hepatitis',
          },
          {
            op_key: "6",
            op_value: 'HIV',
          },
          {
            op_key: "7",
            op_value: 'Lung disease',
          },
          {
            op_key: "8",
            op_value: 'Peripheral vascular disease',
          },
          {
            op_key: "9",
            op_value: 'Rheumatologic condition',
          },
          // {
          //   op_key: "10",
          //   op_value: 'Parkinson/'s',
          // },
          {
            op_key: "11",
            op_value: 'Others',
          },
          {
            op_key: "12",
            op_value: 'Chronic Kidney Disease',
          },

        ],
      },
    },

    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Previous hip surgeries",
      name: "previous_surgeries",
      is_primary: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Core decompression',
          },
          {
            op_key: "2",
            op_value: 'Unipolar',
          },
          {
            op_key: "3",
            op_value: 'Bipolar',
          },
          {
            op_key: "4",
            op_value: 'Fusion',
          },
          {
            op_key: "5",
            op_value: 'Osteotomy',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "ORIF",
      name: "orif",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Acetabular',
          },
          {
            op_key: "2",
            op_value: 'Head',
          },
          {
            op_key: "3",
            op_value: 'Neck',
          },
          {
            op_key: "4",
            op_value: 'Trochanteric',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "Deformity",
      name: "deformity",
      is_primary: true,
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Absent',
          },
          {
            op_key: "2",
            op_value: 'Flexion',
          },
          {
            op_key: "3",
            op_value: 'Abduction',
          },
          {
            op_key: "4",
            op_value: 'Adduction',
          },
          {
            op_key: "5",
            op_value: 'Int. rotation',
          },
          {
            op_key: "6",
            op_value: 'Ext. Rotation',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "",
      name: "limb_shortening",
      offsetLabel: "Limb shortening",
      is_primary: true,
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'Absent',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: "",
      name: "limb_shortening_info1"
    },
    {
      type: configuration.FORM.FIELD.CHECKBOX,
      label: "",
      name: "limb_shortening_info_status",
      class: {
        offsetDivClass: "col-md-3",
        labelDivClass: "col-md-3",
        inputDivClass: "col-md-6",
      },
      options:
      {
        data: [
          {
            op_key: "1",
            op_value: 'TRUE',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.TEXT,
      label: " ",
      name: "limb_shortening_info2"

    },
  ],

};
export default FormHipPreopClinicalDetails;
