import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormComponent } from 'src/app/common/form/form.component';
import { configuration } from 'src/app/configuration';
import FormKneePrimarySocietyScoreDeductions from 'src/app/helpers/forms/knee-primary-society-score-deductions-form';
import FormKneePrimarySocietyScoreFunction from 'src/app/helpers/forms/knee-primary-society-score-function-form';
import FormKneePrimarySocietyScorePlain from 'src/app/helpers/forms/knee-primary-society-score-plain-form';
import FormKneePrimarySocietyScoreStability from 'src/app/helpers/forms/knee-primary-society-score-stability-form';
import { ApiService } from 'src/app/service/api.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-knee-primary-society-score',
  templateUrl: './knee-primary-society-score.component.html',
  styleUrls: ['./knee-primary-society-score.component.css']
})
export class KneePrimarySocietyScoreComponent implements OnInit {
  @Input() id;
  @Input() type;
  @Input() index;  
  @Output() changeScore = new EventEmitter();
  @Output() changeScoreFinal = new EventEmitter();
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;

  _formKneePrimarySocietyScoreDeductions = JSON.parse(JSON.stringify(FormKneePrimarySocietyScoreDeductions));
  _formKneePrimarySocietyScoreFunction = JSON.parse(JSON.stringify(FormKneePrimarySocietyScoreFunction));
  _formKneePrimarySocietyScorePlain = JSON.parse(JSON.stringify(FormKneePrimarySocietyScorePlain));
  _formKneePrimarySocietyScoreStability = JSON.parse(JSON.stringify(FormKneePrimarySocietyScoreStability));
  currentData = null;

  tableName = configuration.TABLE.KNEE_PRE_OP_SOCIETY_SCORE;
  score = 0;
  side = 0;
  patientData = null;

  constructor(public _apiService: ApiService, public _shared: SharedService) { 
    this._shared.currentPage = "knee";
  }

  ngOnInit(): void {
    if(this.type == "post") {
      this.tableName = configuration.TABLE.KNEE_POST_OP_SOCIETY_SCORE
    }
    this.getData();
  }

  async getPatientData() {
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this._apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    console.log("res1",res1)
    if (res1 && res1.data && res1.data[0]) {
      this.patientData = res1.data[0];
      this.side = this.patientData.surgery_side;
    }
  }

  async getData() {
    let json = {
      where: {
        patient_id: this.id,
        index: this.index
      }
    };
    console.log("tableName",this.tableName)
    console.log("json",json)
    let res = await this._apiService.COMMON_API_executeQuery(this.tableName, json);
    console.log("res res test",res)
    if (res && res.data && res.data[0]) {
      this.currentData = res.data[0];
      let _currentData = JSON.parse(res.data[0].data);
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        for(let f of formComponent) {
          if(_currentData[f.formParam.name]) {
            f.setAllFormFieldValue(_currentData[f.formParam.name]);
          }

        }
      });
    }
  }

  async submit() {
    let data = {};
    const formComponent: any = this.formComponent.toArray();
    if(formComponent) {
      for(let f of formComponent) {
        console.log("form value",f.getFormValue());
        let formValues = f.getFormValue();
        data[formValues.formParam.name] = formValues.form.value;
      }
    }
    console.log("data",data)
    let json = {
      patient_id: this.id,
      data: JSON.stringify(data),
      score: this.score,
      index: this.index,
      side : this.side
    }
    console.log("json",json)
    this._shared.isLoader = true;
    if(this.currentData) {
      await this._apiService.COMMON_API_updateSingleRecord(this.tableName, json, this.currentData.id);
    } else {
      await this._apiService.COMMON_API_insertSingleRecord(this.tableName, json);
    }
    this._shared.isLoader = false;
    this.changeScoreFinal.emit({
      type: "knee",
      value: this.score
    });

  }

  onFormFieldOnChange(e) {
    this.score = 0;
    if(this.formComponent) {
      const formComponent: any = this.formComponent.toArray();
      if(formComponent) {
        for(let f of formComponent) {
          let form = f.getFormValue();
          let formValues = form.form.value;
          for(let f of form.formParam.fields) {
            if(f.type == "RADIO") {
              if(formValues[f.name]) {
                this.score = this.score + parseInt(formValues[f.name]);
              }
            }
          }
        }
      }
      this.changeScore.emit({
        type: "knee",
        value: this.score
      });
    }
  }

}
