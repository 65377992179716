<form [formGroup]="form">
    <div class="form-group row">
        <label *ngIf="field.class" [ngClass]="field.class.offsetDivClass">
            {{field.offsetLabel}}
        </label>
        <div [ngClass]="field.class.labelDivClass">
            <label *ngIf="field.isDisplayLabel && field.label != ''">{{field.label}} 
                <span *ngIf="isRequired()" class="text-danger">*</span>
            </label>
        </div>
        <div [ngClass]="field.class.inputDivClass">    
            <div class="input-group">
                <div class="input-group-prepend" *ngIf="field.prefix">
                    <span class="input-group-text" id="inputGroupPrepend2">
                        <ng-container *ngIf="field.prefix.class">
                            <i [class]="field.prefix.class"></i>
                        </ng-container>
                    </span>
                </div>
                <div class="input-group-prepend salutation" *ngIf="field.salutation">
                    <span class="input-group-text" id="inputGroupPrepend2">
                        <select (change)="onSelect($event.target.value)">
                            <option [selected]="field.salutation_value == -5 ? 'selected' : ''" value="-5"></option>
                            <option [selected]="field.salutation_value == 1 ? 'selected' : ''" value="1">Mr</option>
                            <option [selected]="field.salutation_value == 2 ? 'selected' : ''" value="2">Mrs</option>
                            <option [selected]="field.salutation_value == 3 ? 'selected' : ''" value="3">Ms</option>
                        </select>
                    </span>
                </div>

                <input [ngClass]="field.isControlBig ? 'form-control-lg' : ''"  [attr.disabled]="field.isDisabled ? true : null" type="text" class="form-control"
                    [value]="field.value" [formControlName]="field.name" [name]="field.name" [placeholder]="field.placeholder" />
                    <i class="form-group__bar"></i>
                <div class="input-group-append" *ngIf="field.postfix">
                    <span class="input-group-text" id="inputGroupPrepend3">
                        <ng-container *ngIf="field.postfix.class">
                            <i [class]="field.postfix.class"></i>
                        </ng-container>
                        <ng-container *ngIf="field.postfix.text">
                            {{field.postfix.text}}
                        </ng-container>
                    </span>
                </div>
            </div>
        </div>    
    </div>

    <ng-container *ngIf="field.isDisplayInlineError && isDisplayValidation && _form[field.name] && _form[field.name].errors">
        <ng-container *ngIf="_form[field.name].errors.required">
            <div class="row">
                <div [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel"></div>
                <div [ngClass]="field.class.inputDivClass">
                    <span class="color-red">
                        This field is required!
                    </span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</form>