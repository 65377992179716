import { Component, Input, OnInit, QueryList, ViewChildren, EventEmitter, Output } from '@angular/core';
import { FormComponent } from 'src/app/common/form/form.component';
import { configuration } from 'src/app/configuration';
import FormHipPrimarySatisfactionDate12MonthScore from 'src/app/helpers/forms/hip-primary-satisfaction-date-12month-score-form';
import FormHipPrimarySatisfactionDate6MonthScore from 'src/app/helpers/forms/hip-primary-satisfaction-date-6month-score-form';
import FormHipPrimarySatisfactionPatient12MonthScore from 'src/app/helpers/forms/hip-primary-satisfaction-patient-12month-score-form';
import FormHipPrimarySatisfactionPatient6MonthScore from 'src/app/helpers/forms/hip-primary-satisfaction-patient-6month-score-form';
import { ApiService } from 'src/app/service/api.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-hip-primary-satisfaction-score',
  templateUrl: './hip-primary-satisfaction-score.component.html',
  styleUrls: ['./hip-primary-satisfaction-score.component.css']
})
export class HipPrimarySatisfactionScoreComponent implements OnInit {
  canLoad = false;
  @Input() id;
  @Input() type;
  _formHipPrimarySatisfactionDate6MonthScoreArr = [];
  _formHipPrimarySatisfactionDate12MonthScoreArr = [];
  _formHipPrimarySatisfactionPatient6MonthScoreArr = [];
  _formHipPrimarySatisfactionPatient12MonthScoreArr = [];
  @Input() index;  
  @Output() changeScore = new EventEmitter();
  @Output() changeScoreFinal = new EventEmitter();
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  currentData = null;
  table = configuration.TABLE.HIP_PRE_OP_SATISFACTION_SCORE;
  score = 0;

  constructor(public _apiService: ApiService, public _shared: SharedService) {
    for(let i = 0; i < 5; i++) {
      let form = JSON.parse(JSON.stringify(FormHipPrimarySatisfactionDate6MonthScore));
      form.name = "form_6_"+i;
      this._formHipPrimarySatisfactionDate6MonthScoreArr.push(form);
    }
    for(let i = 0; i < 5; i++) {
      let form = JSON.parse(JSON.stringify(FormHipPrimarySatisfactionDate12MonthScore));
      form.name = "form_12_"+i;
      this._formHipPrimarySatisfactionDate12MonthScoreArr.push(form);
    }
    for(let i = 0; i < 2; i++) {
      let form = JSON.parse(JSON.stringify(FormHipPrimarySatisfactionPatient6MonthScore));
      form.name = "form_s_6_"+i;
      this._formHipPrimarySatisfactionPatient6MonthScoreArr.push(form);
    }
    for(let i = 0; i < 2; i++) {
      let form = JSON.parse(JSON.stringify(FormHipPrimarySatisfactionPatient12MonthScore));
      form.name = "form_s_12_"+i;
      this._formHipPrimarySatisfactionPatient12MonthScoreArr.push(form);
    }
    this.canLoad = true;
  }

  ngOnInit(): void {
    if(this.type == 'post') {
      this.table = configuration.TABLE.HIP_POST_OP_SATISFACTION_SCORE;
    }
    this.getData();
  }

  async getData() {
    let json = {
      where: {
        patient_id: this.id,
        index: this.index
      }
    };
    let res = await this._apiService.COMMON_API_executeQuery(this.table, json);
    if (res && res.data && res.data[0]) {
      this.currentData = res.data[0];
      let _currentData = JSON.parse(res.data[0].data);
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        for(let f of formComponent) {
          if(_currentData[f.formParam.name]) {
            f.setAllFormFieldValue(_currentData[f.formParam.name]);
          }
        }
      });
    }
  }

  async submit() {
    let data = {};
    const formComponent: any = this.formComponent.toArray();
    if(formComponent) {
      for(let f of formComponent) {
        let formValues = f.getFormValue();
        data[formValues.formParam.name] = formValues.form.value;
      }
    }
    let json = {
      patient_id: this.id,
      data: JSON.stringify(data),
      score: this.score,
      index: this.index
    }
    this._shared.isLoader = true;
    if(this.currentData) {
      await this._apiService.COMMON_API_updateSingleRecord(this.table, json, this.currentData.id);
    } else {
      let res = await this._apiService.COMMON_API_insertSingleRecord(this.table, json);
      if(res && res.data) {
        this.currentData = res.data;
      }
    }
    this.changeScoreFinal.emit({
      type: "satisfaction",
      value: this.score
    });
    this._shared.isLoader = false;

  }

  onFormFieldOnChange(e) {
    this.score = 0;
    if(this.formComponent) {
      const formComponent: any = this.formComponent.toArray();
      if(formComponent) {
        for(let f of formComponent) {
          let form = f.getFormValue();
          let formValues = form.form.value;
          for(let f of form.formParam.fields) {
            if(f.type == "RADIO") {
              if(formValues[f.name]) {
                this.score = this.score + parseInt(formValues[f.name]);
              }
            }
          }
        }
      }
      this.changeScore.emit({
        type: "satisfaction",
        value: this.score
      });
    }
  }

}
