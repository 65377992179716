import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { configuration } from 'src/app/configuration';
import BranchForm from 'src/app/helpers/forms/branch';
import { ApiService } from 'src/app/service/api.service';
import { SharedService } from 'src/app/service/shared.service';
import { FormComponent } from '../../common/form/form.component';

@Component({
  selector: 'app-hospital-branch',
  templateUrl: './hospital-branch.component.html',
  styleUrls: ['./hospital-branch.component.css']
})
export class HospitalBranchComponent implements OnInit {

  _branchForm = BranchForm
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  id = "";
  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    console.log(this.id)
  }

  ngOnInit(): void {
    this.getHospitalID();
  }

  getHospitalID() {
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        if (this.id) {
          formComponent[0].setFormFieldValue("add_hospital_id", this.id);
        }
      }
    })
  }

  async onSubmit() {
    let formComponent = this.formComponent.toArray();
    if (formComponent && formComponent[0]) {
      let values = formComponent[0].getFormValue();
      values = JSON.parse(JSON.stringify(values.form.value));
      this.shared.isLoader = true;
      console.log(values)
      let res = await this.apiService.POST_apiRequest(configuration.API_ENDPOINT.CREATE_ADMIN_BRANCH, values);
      // await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.HOSPITAL, values);
      this.shared.isLoader = false;
      window.location.href = "/hospital-branch-list/"+this.id;
    }
  }

}
