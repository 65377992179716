import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { configuration } from 'src/app/configuration';
import { SharedService } from 'src/app/service/shared.service';
import { SelectComponent } from '../select/select.component';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'app-special-opd-charge',
  templateUrl: './special-opd-charge.component.html',
  styleUrls: ['./special-opd-charge.component.css']
})
export class SpecialOpdChargeComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  fieldValue = null;
  @ViewChildren(SelectComponent) fieldselectOneComponent: QueryList<SelectComponent>;
  @ViewChildren(TextComponent) fieldtextComponent: QueryList<TextComponent>;
  fieldText;
  fieldSelect;
  fieldTextClone;
  fieldSelectClone;
  specialOPDCharages = [];
  data = [];

  constructor(public formBuilder: FormBuilder, public _shared: SharedService) { }

  ngOnInit() {
    this.fieldText = JSON.parse(JSON.stringify(this.field));
    this.fieldSelect = JSON.parse(JSON.stringify(this.field));
    this.fieldValue = JSON.parse(JSON.stringify(this.fieldSelect));
    this.fieldSelect.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
    this.fieldText.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
    this.fieldValue.class = {
      labelDivClass: "",
      inputDivClass: "col-md-12"
    }
    this.fieldText.type = configuration.FORM.FIELD.TEXT;
    this.fieldText.name = this.fieldText.name + "_value";
    this.fieldText.isDisplayLabel =  false;
    this.fieldSelect.isDisplayLabel =  false;
    this.fieldSelect.options = {};
    this.fieldSelect.options.op_key = 'client_master_special_opd_charge_id';
    this.fieldSelect.options.op_value = 'special_opd_charge_name';
    this.form = this.formBuilder.group({});
    this.fieldValue.isDisplayLabel = false;
    this.fieldSelect.isDisplayLabel = false;
    this.fieldSelectClone = JSON.parse(JSON.stringify(this.fieldSelect))
    this.fieldTextClone = JSON.parse(JSON.stringify(this.fieldText))
    this.specialOPDCharages.push({
      fieldSelect: this.fieldSelect,
      fieldText: this.fieldText,
    })
    this.data.push({
      id: 0,
      value: 0
    })
    
  }

  // Set Select box options 
  setFormFieldOptions(name, value, attrname) {
    console.log( this.fieldSelect);
    this.fieldSelect.options[attrname] = value;
    this.fieldSelectClone.options[attrname] = value;
  }

  addNewRecord() {
    this.specialOPDCharages.push({
      fieldSelect: this.fieldSelectClone,
      fieldText: this.fieldTextClone,
    });
    this.data.push({
      id: 0,
      value: 0
    });
  }

  removeNewRecord(i) {
    this.specialOPDCharages.splice(i,1);
  }

  setFormFieldValue(name, value) {
    if(typeof value.id_proof_id != "undefined") {
      // this.field.value = value.id_proof_id;
      // this.form.controls[this.field.name].setValue(value.id_proof_id);
      const fieldselectComponent:any = this.fieldselectOneComponent.toArray();
      if(fieldselectComponent && fieldselectComponent[0]) {
        fieldselectComponent[0].setFormFieldValue(this.field.name, value.id_proof_id);
      }
    }
    if(typeof value.id_proof_value != "undefined") {
      // this.field.value = value.id_proof_value;
      // this.form.controls[this.field.name+"_value"].setValue(value.id_proof_value);
      const fieldtextComponent:any = this.fieldtextComponent.toArray();
      if(fieldtextComponent && fieldtextComponent[0]) {
        fieldtextComponent[0].setFormFieldValue(this.field.name+"_value", value.id_proof_value);
      }
    }

  }

  onSelectOPDCharge(e,i) {
    if(e && e.field && e.field.name == "special_opd_charge") {
      let val = e.field.value;
      if(e.field.options && e.field.options.data) {
        for(let op of e.field.options.data) {
          if(val == op[e.field.options.op_key]) {
            op.special_opd_charge_amount = this._shared.integerValue(op.special_opd_charge_amount);
            const fieldtextComponent:any = this.fieldtextComponent.toArray();
            if(fieldtextComponent && fieldtextComponent[i]) {
              fieldtextComponent[i].setFormFieldValue(this.fieldText.name, op.special_opd_charge_amount);
              this.data[i].id = val;
              this.data[i].value = op.special_opd_charge_amount;
              this.field.value = this.data;
              this.onValueChange.emit({
                field: this.field,
                form: this.form,
                control: this.form.controls[this.field.name],
              });
            }
          }
        }
      }
    }
  }

  onChangeOPDCharge(e,i) {
    let val = e.field.value;
    this.data[i].value = val;
    this.field.value = this.data;
    this.onValueChange.emit({
      field: this.field,
      form: this.form,
      control: this.form.controls[this.field.name],
    });
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

}
