import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { configuration } from 'src/app/configuration';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {
  @Input() field;
  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  isDisplayValidation = false;
  constructor(public formBuilder: FormBuilder, public _apiService: ApiService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
    if(this.field.validation && this.field.validation.required) {
      this.form.controls[this.field.name].setValidators(Validators.required);
    }
    this.form.controls[this.field.name].valueChanges.subscribe(val => {
      this.field.value = val;
      this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
    });
    if(typeof this.field.options == "undefined") {
      this.field.options = {
        data: [],
        op_key: "op_key",
        op_value: "op_value"
      }
    } else {
      this.field.options.op_key = this.field.options.op_key ? this.field.options.op_key : "op_key";
      this.field.options.op_value = this.field.options.op_value ? this.field.options.op_value : "op_value";
    }
    this.loadOptionsData();
  }

  isRequired() {
    return (this.field.validation && this.field.validation.required);
  }
  
  isFieldValid() {
    this.isDisplayValidation = true;
    if(this.form.controls[this.field.name].errors) {
      return {
        [this.field.name]: this.form.controls[this.field.name].errors
      };
    }
    return null;
  }

  get _form () {
    return this.form.controls;
  }

  async loadOptionsData() {
    if(this.field.options && this.field.options.API) {
      if(this.field.options.API.type == configuration.API_TYPE.COMMON) {
        if(this.field.options.API.where) {
          if(this.field.options.API.where.add_hospital_id) {
            this.field.options.API.where.add_hospital_id = parseInt(configuration.getLocalStorageValue(configuration.LOCAL_STORAGE.HOSPITAL_ID));
          }
          const crudData = await this._apiService.COMMON_API_executeQuery(this.field.options.API.model, {
            where: this.field.options.API.where
          });
          if(crudData && !crudData.is_error && crudData.data) {
            this.field.options.data = crudData.data;
          }
        } else {
          const crudData = await this._apiService.COMMON_API_getAllRecords(this.field.options.API.model);
          if(crudData && !crudData.is_error && crudData.data) {
            this.field.options.data = crudData.data;
          }
        }

      } else {
        console.log(this.field.options.API);
        const crudData = await this._apiService.GET_apiRequest(this.field.options.API.URL);
        if(crudData && !crudData.is_error && crudData.data) {
          this.field.options.data = crudData.data;
          this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
        }
      }
    }
  }

  setFormFieldValue(name, value) {
    // console.log("value", value);
    this.field.value = value;
    this.form.controls[this.field.name].setValue(value);
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

  // Set Select box options 
  setFormFieldOptions(name, value, attrname) {
    this.field.options[attrname] = value;
  }

  getValue(d, field) {
    if(field.options.op_parent) {
      return d[field.options.op_parent][field.options.op_value];
    } else {
      return d[field.options.op_value];
    }

  }
}
