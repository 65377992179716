<div class="row" *ngIf="patientData">
  <div class="col-12">
    <div class="alert alert-info" style="font-size: 16px; ">
      {{ (patientData.patient_type == 0 ? "Knee" : (patientData.patient_type == 1 ? "Hip" : ""))}}
      {{ (patientData.patient_sub_type == 0 ? "Primary" : (patientData.patient_sub_type == 1 ? "Revision" :
      ""))}}
    </div>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td class="bold" width="20%">
            Name
          </td>
          <td colspan="3" width="80%">
            {{patientData.first_name}} {{patientData.last_name}}
          </td>
        </tr>
        <tr>
          <td class="bold" width="20%">
            Age
          </td>
          <td width="30%">
            {{patientData.age}}
          </td>
          <td class="bold" width="20%">
            Sex
          </td>
          <td width="30%">
            {{ (patientData.gender == 1 ? "Male" : (patientData.gender == 2 ? "Female" : ""))}}
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
<ul class="nav nav-tabs mb-3">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="name == 'demography' ? 'active' : ''"
      [href]="'/knee-demography/'+id">Demography </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="name == 'knee-preop-clinical' ? 'active' : ''"
      [href]="'/knee-preop-clinical/'+id">Preoperative Clinical Details </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="name == 'knee-radiological' ? 'active' : ''"
      [href]="'/knee-radiological/'+id">Radiological Evaluations</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="name == 'knee-intraoperative' ? 'active' : ''"
      [href]="'/knee-intraoperative/'+id">Intraoperative Details</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="name == 'knee-postop' ? 'active' : ''" [href]="'/knee-postop/'+id">Postoperative Radiographic Assessment</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="name == 'knee-postop-score' ? 'active' : ''" [href]="'/knee-postop-score/'+id">Postoperative Knee Score</a>
  </li>
</ul>