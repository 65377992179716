import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { configuration } from 'src/app/configuration';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {
  @Input() field;
  @Output() onFileChange = new EventEmitter();
  form: FormGroup;
  constructor(public formBuilder: FormBuilder, public _shared: SharedService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
    
    this.field.isMultiple = typeof this.field.isMultiple == "undefined" ? false : this.field.isMultiple;

    if(this.field.file) {
      this.field.file.fileObj = typeof this.field.file.fileObj == "undefined" ? null : this.field.file.fileObj;
      this.field.file.fileObjResult = typeof this.field.file.fileObjResult == "undefined" ? null : this.field.file.fileObjResult;
      this.field.file.fileURL = typeof this.field.file.fileURL == "undefined" ? null : this.field.file.fileURL;
      this.field.file.fileUploadError = typeof this.field.file.fileUploadError == "undefined" ? null : this.field.file.fileUploadError;
      this.field.file.isDelete = typeof this.field.file.isDelete == "undefined" ? false : this.field.file.isDelete;
    } else {
      this.field.file = {
        fileObj: null,
        fileObjResult: null,
        fileURL: '',
        fileUploadError: '',
        isDelete: false,
        isDeleteMultiple: [],
        multipleFileObj: [],
        multipleFileResult: [],
        multipleFileURL: [],
      }
    }
    this.field.file.multipleFileURL = typeof this.field.multipleFile == "undefined" ? [] : this.field.multipleFile;

    
  }

  setFormFieldValue(name, value) {
    this.field.file.fileURL = value;
  }

  onChangeMedia(event, file) {
    if(this.field.isMultiple) {
      let allPromises = [];
      for(let f of file) {
        allPromises.push(new Promise(async (resolve, reject) => {
          const oFReader = new FileReader();
          let ext = f.name.substr(f.name.lastIndexOf(".")+1);
          ext = ext.toLowerCase();
          this.field.file.multipleFileObj.push(f);
          oFReader.readAsDataURL(f);
          const self = this;
          oFReader.onload = function (oFREvent: any) {
            self.field.file.multipleFileResult.push(oFREvent.target.result);
            resolve(true);
          };
        }));
      }
      if(allPromises.length > 0) {
        const self = this;
        return Promise.all(allPromises)
        .then((result) => {
          self.onFileChange.emit({ field: self.field, form: self.form, control: self.form.controls[self.field.name]});
        });
      }
    } else {
      const oFReader = new FileReader();
      let ext = file[0].name.substr(file[0].name.lastIndexOf(".")+1);
      ext = ext.toLowerCase();
      this.field.file.fileObj = file[0];
      console.log(file[0]);
      this.field.file.ext = ext;
      this.field.file.isPreview = configuration.previewFileExtensions.indexOf(ext) >= 0;
      oFReader.readAsDataURL(file[0]);
      const self = this;
      oFReader.onload = function (oFREvent: any) {
        self.field.file.fileObjResult = oFREvent.target.result;      
        self.onFileChange.emit({ field: self.field, form: self.form, control: self.form.controls[self.field.name]});
      };
    }

  }

  removeFile() {
    if(this.field.file.fileURL) {
      this.field.file.fileURL = null;
      this.field.file.isDelete = true;
    }
    this.field.file.fileObj = null;
    this.field.file.fileObjResult = null;
  }

  removeFileMultipleURL(i) {
    this.field.file.isDeleteMultiple.push(this.field.file.multipleFileURL[i].pcxdi_id);
    this.field.file.multipleFileURL.splice(i, 1);
  }

  removeFileMultiple(i) {
    this.field.file.multipleFileResult.splice(i, 1);
    this.field.file.multipleFileObj.splice(i, 1);
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }
}
