import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-knee-primary-date-satisfaction-score',
  templateUrl: './knee-primary-date-satisfaction-score.component.html',
  styleUrls: ['./knee-primary-date-satisfaction-score.component.css']
})
export class KneePrimaryDateSatisfactionScoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
