<form [formGroup]="form">
    <div class="form-group row timeboxes">
        <label [ngClass]="field.class.labelDivClass" *ngIf="field.isDisplayLabel">{{field.label}}</label>
        <div [ngClass]="field.class.inputDivClass">
            <div class="startTimeBox">
                <div style="width: 220px; float: left;" class="form-group">
                    <mat-form-field appearance="fill">
                        <mat-label>Start Time</mat-label>
                        <mat-select [formControlName]="field.name+'_startTime'" (selectionChange)="changeEndTime($event)">
                            <ng-container *ngFor="let ap of amPM">
                                <ng-container *ngFor="let d of timeHour">
                                    <ng-container *ngFor="let m of timeMin">
                                        <mat-option [selected]="startTime == d+'_'+m+'_'+ap" [value]="d+'_'+m+'_'+ap">{{d + ":" + m + " " + ap}}</mat-option>
                                        <!-- <option [selected]="startTime == d+'_'+m+'_'+ap" [value]="d+'_'+m+'_'+ap">{{d + ":" + m + " " + ap}}</option> -->
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <!-- <select class="form-control" [formControlName]="field.name+'_h'" (change)="changeEndTime($event)">
                        <option [selected]="!field.value" value="">Start Time</option>
                        <ng-container *ngFor="let ap of amPM">
                            <ng-container *ngFor="let d of timeHour">
                                <ng-container *ngFor="let m of timeMin">
                                    <option [selected]="startTime == d+'_'+m+'_'+ap" [value]="d+'_'+m+'_'+ap">{{d + ":" + m + " " + ap}}</option>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </select> -->
                </div>
                <div class="to_div">
                    to
                </div>
                <div style="width: 220px; float: left;" class="form-group">
                    <mat-form-field appearance="fill">
                        <mat-label>End Time</mat-label>
                        <mat-select [formControlName]="field.name+'_endTime'">
                            <ng-container *ngFor="let ap of amPM">
                                <ng-container *ngFor="let d of timeHour">
                                    <ng-container *ngFor="let m of timeMin">
                                        <mat-option [selected]="endTime == d+'_'+m+'_'+ap" [value]="d+'_'+m+'_'+ap">{{d + ":" + m + " " + ap}}</mat-option>
                                        <!-- <option [selected]="startTime == d+'_'+m+'_'+ap" [value]="d+'_'+m+'_'+ap">{{d + ":" + m + " " + ap}}</option> -->
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <!-- <select class="form-control" [formControlName]="field.name+'_m'">
                        <option [selected]="!field.value" value="">End Time</option>
                        <ng-container *ngFor="let ap of amPM">
                            <ng-container *ngFor="let d of timeHour">
                                <ng-container *ngFor="let m of timeMin">
                                    <option [selected]="endTime == d+'_'+m+'_'+ap" [value]="d+'_'+m+'_'+ap">
                                        {{d + ":" + m + " " + ap}}</option>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </select> -->
                </div>
                <!-- <div style="width: 70px; float: left;">
                    <select class="form-control" [formControlName]="field.name+'_h'">
                        <option [selected]="!field.value" value="">H</option>
                        <ng-container *ngFor="let d of timeHour">
                            <option [selected]="field.value == d" [value]="d">{{d}}</option>                            
                        </ng-container>
                    </select>
                </div>
                <div style="width: 70px; float: left;">
                    <select class="form-control" [formControlName]="field.name+'_m'">
                        <option value="">M</option>
                        <ng-container *ngFor="let d of timeMin">
                            <option [selected]="field.value == d" [value]="d">{{d}}</option>
                        </ng-container>
                    </select>
                </div>
                <div style="width: 75px; float: left;">
                    <select class="form-control" [formControlName]="field.name+'_a'">
                        <option value="" selected>AM</option>
                        <option value="" selected>PM</option>

                    </select>
                </div> -->
            </div>
        </div>
    </div>
</form>