import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import FormIntraoperative from '../helpers/forms/knee-intraoperative-form';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormPreopClinicalDetails from '../helpers/forms/knee-preop-clinical-details-form';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-knee-intraoperative',
  templateUrl: './knee-intraoperative.component.html',
  styleUrls: ['./knee-intraoperative.component.css']
})
export class KneeIntraoperativeComponent implements OnInit {
  _formIntraoperative = FormIntraoperative;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  id = "";
  currentData = null;
  patientData = null;
  modal = "";
  side = 0;

  arrayColumns = ["anaesthesia", "mcl", "lcl", "varus", "valgus", "flexion", "defect_reconstruction", "component_stability", "mechanical", "pharmacological"];
  stringArrColumns = ["mechanical"]

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    this.shared.currentPage = "knee";
  }


  ngOnInit(): void {
    this.getPatientData();
  }

  async getPatientData() {
    console.log(this.router.url);
    console.log(this.router.url);
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    // console.log("res1",res1)
    if (res1 && res1.data && res1.data[0]) {
      this.patientData = res1.data[0];
      this.side = this.patientData.surgery_side;
    }

    let json = {
      where: {
        patient_id: this.id
      }
    };
    let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.KNEE_INTRAOP, json);
    console.log("res res",res)
    if (res && res.data && res.data[0]) {
      
      for(let c of res.data) {
        if(c.side == this.patientData.surgery_side) {
          this.currentData = c;
        }
      }      
      if(!this.currentData) {
        this.currentData = res.data[0];
      }

      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          console.log("JSON.parse(this.currentData.data)", JSON.parse(this.currentData.data))
          let cData = JSON.parse(this.currentData.data);
          console.log("typeof", typeof cData[""]);
          if(typeof cData[""] != "undefined") {
            cData["vca_radio"] = cData[""];
          }
          let newCData:any = {};
          for(let tdKey of Object.keys(cData)) {
            if(this.arrayColumns.indexOf(tdKey) >= 0) {
              try {
                  let arrC = cData[tdKey]; 
                  if(typeof arrC != "object") {
                    arrC = JSON.parse(cData[tdKey]);
                  }
                  if(typeof arrC != "object") {
                    arrC = [arrC]
                  }
                  if(arrC.length != 0) {
                    newCData[tdKey] = arrC;
                  }
              } catch (e) {
                console.log("error", tdKey, cData[tdKey], e);
              }          
             } else {
              if(cData[tdKey]) {
                newCData[tdKey] = cData[tdKey];
              }
            }
          }
          
          console.log("typeof", newCData);

          formComponent[0].setAllFormFieldValue(newCData);
          console.log("additional_information",newCData.additional_information)
          // formComponent[0].setFormFieldValue("additional_information", cData.additional_information[0]);
          this.shared.showHideRevisionFields(formComponent[0], this.patientData);
        }
      });
    }
  }

  async onFormFieldOnChange(e) {
    if(e.field.name == "company") {
      console.log("changeform", e.field.name, e);
      const crudData = await this.apiService.GET_apiRequest(configuration.API_ENDPOINT.GET_COMPANY_DESIGN+e.field.value);
      if(crudData && !crudData.is_error && crudData.data) {
        console.log("changeform",  crudData.data);
        setTimeout(async () => {
          let formComponent = this.formComponent.toArray();
          if (formComponent && formComponent[0]) {
            formComponent[0].setFormFieldOptions("design", crudData.data);
          }
        });
      }
    }

  }

  submitForm() {
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        console.log("formComponent[0]",formComponent[0].form.value)
        let values = formComponent[0].getFormValue();
        values = JSON.parse(JSON.stringify(values.form.value));
        this.shared.isLoader = true;
        let json = {
          patient_id: this.id,
          data: JSON.stringify(values),
          side : this.side
        }
        console.log("json",json)
        if (this.currentData) {
          await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.KNEE_INTRAOP, json, this.currentData.id);
        } else {
          let res =await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.KNEE_INTRAOP, json);
        }
        this.shared.isLoader = false;
        window.location.href = '/knee-postop/' + this.id;
      }
    });
  }

}
