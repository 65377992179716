import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import FormHipPreopRadiographic from '../helpers/forms/hip-postop-radiographic-form';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormPreopClinicalDetails from '../helpers/forms/knee-preop-clinical-details-form';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-hip-postop-radiographic',
  templateUrl: './hip-postop-radiographic.component.html',
  styleUrls: ['./hip-postop-radiographic.component.css']
})
export class HipPostopRadiographicComponent implements OnInit {
  _formHipPreopRadiographic = FormHipPreopRadiographic;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  id = "";
  currentData = null;
  modal = "";
  patientData = null;

  constructor(private router: ActivatedRoute, private apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    this.shared.currentPage = "hip";
  }

  ngOnInit(): void {
    this.getPatientData();
  }

  async getPatientData() {
    let json1 = {
      where: {
        id: this.id
      }
    };
    let res1 = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json1);
    if (res1 && res1.data && res1.data[0]) {
      this.patientData = res1.data[0];

      console.log(this.router.url);
      let json = {
        where: {
          patient_id: this.id
        }
      };
      let res = await this.apiService.COMMON_API_executeQuery(configuration.TABLE.HIP_POSTOP, json);
      if (res && res.data && res.data[0]) {
        this.currentData = res.data[0];
        setTimeout(async () => {
          let formComponent = this.formComponent.toArray();
          if (formComponent && formComponent[0]) {
            formComponent[0].setAllFormFieldValue(this.currentData);
          }
        });
      }
    }
  }

  submitForm() {
    setTimeout(async () => {
      let formComponent = this.formComponent.toArray();
      if (formComponent && formComponent[0]) {
        let values = formComponent[0].getFormValue();
        values = JSON.parse(JSON.stringify(values.form.value));
        values.patient_id = this.id;
        this.shared.isLoader = true;
        if (this.currentData) {
          await this.apiService.COMMON_API_updateSingleRecord(configuration.TABLE.HIP_POSTOP, values, this.currentData.id);
        } else {
          await this.apiService.COMMON_API_insertSingleRecord(configuration.TABLE.HIP_POSTOP, values);
        }
        this.shared.isLoader = false;
        window.location.href = '/hip-postop-score/' + this.id;
      }
    });
  }
}
