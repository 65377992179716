import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '../common/form/form.component';
import { configuration } from '../configuration';
import FormNewPatient from '../helpers/forms/new-patient-form';
import { ApiService } from '../service/api.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-hip-demography',
  templateUrl: './hip-demography.component.html',
  styleUrls: ['./hip-demography.component.css']
})
export class HipDemographyComponent implements OnInit {
  _formDemography = FormNewPatient;
  @ViewChildren(FormComponent) formComponent: QueryList<FormComponent>;
  id = "";
  currentData = null;

  constructor(private router: ActivatedRoute, private _apiService: ApiService, public shared: SharedService) {
    this.id = this.router.snapshot.params.id;
    this.shared.currentPage = "hip";
  }


  ngOnInit(): void {
    this.getPatientData();
  }

  async getPatientData() {
    console.log(this.router.url);
    let json = {
      where: {
        id: this.id
      }
    };
    let res = await this._apiService.COMMON_API_executeQuery(configuration.TABLE.PATIENT, json);
    if (res && res.data && res.data[0]) {
      this.currentData = res.data[0];
      setTimeout(async () => {
        let formComponent = this.formComponent.toArray();
        if (formComponent && formComponent[0]) {
          formComponent[0].setAllFormFieldValue(this.currentData);
        }
      });
    }
  }

  submitForm() {
    setTimeout(async () => {
      const formComponent: any = this.formComponent.toArray();
      if(formComponent && formComponent[0]) {
        let errors = formComponent[0].runValidation();
        if(errors.length == 0) {
          console.log("formComponent", formComponent[0]);
          let formValues = formComponent[0].getFormValue();
          if (formValues.form.value) {
            let values = JSON.parse(JSON.stringify(formValues.form.value));
            let nullColumns = ["dob", "age", "height", "weight"]
            for (let n of nullColumns) {
              if (!values[n]) {
                delete values[n];
              }
            }
            let res = await this._apiService.COMMON_API_updateSingleRecord(configuration.TABLE.PATIENT, values, this.id);
          }
          console.log(formValues.form.value);
        }
      }
    });
  }

}
