import { configuration } from 'src/app/configuration';

const FormKneePrimarySatisfactionPatient12MonthScore = {
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '6 Months',
      name: 'six_month',
      options: {
        data: configuration.SATISFACTION_PATIENT
    },
    value: 0  
    },
 

  ],
  isSubmitButton: true,
  isDisplayInlineError: true,
  submitButton: {
    type: configuration.FORM.FIELD.BUTTON,
    label: '',
    name: 'submit',
    attr: {
      type: 'submit',
      // color: configuration.FORM.BUTTON.COLOR.PRIMARY,
    },
    buttons: [],
  },
};
export default FormKneePrimarySatisfactionPatient12MonthScore; 
  
