<form [formGroup]="form">
    <div class="row">
        <div [ngClass]="field.class.labelDivClass">
            <label>{{field.label}}</label>
        </div>
        <div [ngClass]="field.class.inputDivClass">
            <div class="row" *ngFor="let d of specialOPDCharages; let i = index">
                <div class="col-md-5">
                    <app-select (onValueChange)="onSelectOPDCharge($event,i)" #fieldselectOneComponent [field]="d.fieldSelect"></app-select>
                </div>
                <div class="col-md-5">
                    <app-text (onValueChange)="onChangeOPDCharge($event,i)" #fieldtextComponent [field]="d.fieldText"></app-text>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="addNewRecord()">Add new</button>
                    <button class="btn btn-primary ml-1" *ngIf="i != 0" (click)="removeNewRecord(i)"><i class="fa fa-remove"></i></button>
                </div>
            </div>
        </div>
    </div>
</form>