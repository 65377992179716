import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { TableRowFunctionLoaderComponent } from '../table-row-function-loader.component';
import { TableRowFunctionDirective } from '../table-row-function.directive';
import { TableRowFunctionService } from '../table-row-function.service';

@Component({
  selector: 'app-table-row-function',
  templateUrl: './table-row-function.component.html',
  styleUrls: ['./table-row-function.component.css']
})
export class TableRowFunctionComponent implements OnInit {
  @Input() h;
  @Input() data;
  @ViewChild(TableRowFunctionDirective, {static: true}) tableRowFunctionLoader: TableRowFunctionDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, public _TableRowFunctionService: TableRowFunctionService) { 

  }

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent() {
      const componentData = this._TableRowFunctionService.getComponent(this.h.component);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentData.component);
      const viewContainerRef = this.tableRowFunctionLoader.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent<TableRowFunctionLoaderComponent>(componentFactory);
      componentRef.instance.h = this.h;
      componentRef.instance.data = this.data;
  }

}
