import { configuration } from 'src/app/configuration';

const FormKneePrimaryOxfordScore = {
  fieldClass: {
    labelDivClass: "col-md-6",
    inputDivClass: "col-md-6",
    inputDivClass1: "col-md-12",
    inputDivClass2: "col-md-12",
    mainDivClass: "col-md-12"
  },
  fields: [
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '1. How would you describe the pain you usually have in your knee?',
      name: 'how_would_you_describe_the_pain_you_usually_have_in_your_knee',
      attr: {
        isInline: false
      },
      options: {
        data: [
          {
            op_key: 1,
            op_value: 'None',
          },
          {
            op_key: 2,
            op_value: 'Very mild',
          },
          {
            op_key: 3,
            op_value: 'Mild',
          },
          {
            op_key: 4,
            op_value: 'Moderate',
          },
          {
            op_key: 5,
            op_value: 'Severe',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '2. Have you had any trouble washing and drying yourself (all over) because of your knee?',
      name: 'have_you_had_any_trouble_washing_and_drying_yourself_because_of_your_knee',
      attr: {
        isInline: false
      },
      options: {
        data: configuration.TROUBLE
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '3. Have you had any trouble getting in and out of the car or using public transport because of your knee? (With or without a stick)',
      name: 'have_you_had_any_trouble_getting_in_and_out_of_the_car_or_using_public_transport_because_of_your_knee',
      attr: {
        isInline: false
      },
      options: {
        data: configuration.TROUBLE
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '4. For how long are you able to walk before the pain in your knee becomes severe? (With or without a stick)',
      name: 'for_how_long_are_you_able_to_walk_before_the_pain_in_your_knee_becomes_severe',
      attr: {
        isInline: false
      },
      options: {
        data: [
          {
            op_key: 1,
            op_value: 'No pain > 60 min',
          },
          {
            op_key: 2,
            op_value: '16 - 60 minutes',
          },
          {
            op_key: 3,
            op_value: '5 - 15 minutes',
          },
          {
            op_key: 4,
            op_value: 'Around the house only',
          },
          {
            op_key: 5,
            op_value: 'Not at all - severe on walking',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '5. After a meal (sat at a table), how painful has it been for you to stand up from a chair because of your knee?',
      name: 'after_a_meal_how_painful_has_it_been_for_you_to_stand_up_from_a_chair_because_of_your_knee',
      attr: {
        isInline: false
      },
      options: {
        data: [
          {
            op_key: 1,
            op_value: 'Not at all painful',
          },
          {
            op_key: 2,
            op_value: 'Slightly painful',
          },
          {
            op_key: 3,
            op_value: 'Moderately pain',
          },
          {
            op_key: 4,
            op_value: 'Very painful',
          },
          {
            op_key: 5,
            op_value: 'Unbearable',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '6. Have you been limping when walking, because of your knee?',
      name: 'have_you_been_limping_when_walking_because_of_your_knee',
      attr: {
        isInline: false
      },
      options: {
        data: configuration.RARELY
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '7. Could you kneel down and get up again afterwards?',
      name: 'could_you_kneel_down_and_get_up_again_afterwards',
      attr: {
        isInline: false
      },
      options: {
        data: configuration.DIFFICULTY
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '8. Are you troubled by pain in your knee at night in bed?',
      name: 'are_you_troubled_by_pain_in_your_knee_at_night_in_bed',
      attr: {
        isInline: false
      },
      options: {
        data: [
          {
            op_key: 1,
            op_value: 'Not at all',
          },
          {
            op_key: 2,
            op_value: 'Only one or two nights',
          },
          {
            op_key: 3,
            op_value: 'Some nights',
          },
          {
            op_key: 4,
            op_value: 'Most nights',
          },
          {
            op_key: 5,
            op_value: 'Every night',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '9. How much has pain from your knee interfered with your usual work? (including housework)',
      name: 'how_much_has_pain_from_your_knee_interfered_with_your_usual_work',
      attr: {
        isInline: false
      },
      options: {
        data: [
          {
            op_key: 1,
            op_value: 'Not at all',
          },
          {
            op_key: 2,
            op_value: 'A little bit',
          },
          {
            op_key: 3,
            op_value: 'Moderately',
          },
          {
            op_key: 4,
            op_value: 'Greatly',
          },
          {
            op_key: 5,
            op_value: 'Totally',
          },
        ],
      },
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '10. Have you felt that your knee might suddenly ?give away? or let you down?',
      name: 'have_you_felt_that_your_knee_might_suddenly_give_away_or_let_you_down',
      attr: {
        isInline: false
      },
      options: {
        data: configuration.RARELY
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '11. Could you do household shopping on your own?',
      name: 'could_you_do_household_shopping_on_your_own',
      attr: {
        isInline: false
      },
      options: {
        data: configuration.DIFFICULTY
      },
      value: 0
    },
    {
      type: configuration.FORM.FIELD.RADIO,
      label: '12. Could you walk down a flight of stairs?',
      name: 'could_you_walk_down_a_flight_of_stairs',
      attr: {
        isInline: false
      },
      options: {
        data: configuration.DIFFICULTY
      },
      value: 0
    },
  ],
  // isSubmitButton: true,
  // isDisplayInlineError: true,
  // submitButton: {
  //   type: configuration.FORM.FIELD.BUTTON,
  //   label: 'Submit',
  //   name: 'submit',
  //   attr: {
  //     type: 'submit',
  //     color: configuration.FORM.BUTTON.COLOR.PRIMARY,
  //   },
  //   buttons: [],
  // },
};
export default FormKneePrimaryOxfordScore;

