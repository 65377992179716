import { Component, OnInit } from '@angular/core';
import OptionTable from 'src/app/helpers/tables/option-table';
import AddOption from 'src/app/helpers/forms/add-options';

@Component({
  selector: 'app-primary-hip-options',
  templateUrl: './primary-hip-options.component.html',
  styleUrls: ['./primary-hip-options.component.css']
})
export class PrimaryHipOptionsComponent implements OnInit {

  constructor() { }
  formArray = [
    { label: "Primary Diagnosis", form: OptionTable},
    { label: "AVN", form: OptionTable },
    { label: "Inflammatory Arthritis", form: OptionTable },
    { label: "Post infective Pathalogy", form: OptionTable },
    { label: "Co morbidities", form: OptionTable },
    { label: "Previous hip surgeries", form: OptionTable },
    { label: "ORIF", form: OptionTable },
    { label: "Deformity", form: OptionTable },
    { label: "Anaesthesia", form: OptionTable },
    { label: "Approach", form: OptionTable },
    { label: "Antibiotic", form: OptionTable },
    { label: "Bearing surface", form: OptionTable },
    { label: "Femoral Antibiotic", form: OptionTable },
    { label: "Mechanical", form: OptionTable },
    { label: "Pharmacological", form: OptionTable },

  ]
  _addOption = AddOption
  ngOnInit(): void {
  }

}
