import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent implements OnInit {
  @Input() field;
  @Input() formParam;

  @Output() onValueChange = new EventEmitter();
  form: FormGroup;
  tdKeys = [];
  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
    // console.log("Radio", this.field.value);
    this.form.addControl(
      this.field.name,
      new FormControl(this.field.value)
    );
    if(typeof this.field.attr != "undefined") {
      this.field.attr.isInline = 
        typeof this.field.attr.isInline != "undefined" ? this.field.attr.isInline : true;      
    } else {
      this.field.attr = {
        isInline: true
      }
    }
    if(typeof this.field.options == "undefined") {
      this.field.options = {
        data: [],
        op_key: "op_key",
        op_value: "op_value"
      }
    } else {
      this.field.options.op_key = this.field.options.op_key ? this.field.options.op_key : "op_key";
      this.field.options.op_value = this.field.options.op_value ? this.field.options.op_value : "op_value";
    }
    this.tdKeys = [];
    if(this.field.displayType == 'table' && this.field.options && this.field.options.data && this.field.options.data[0]) {
      for(let tdKey of Object.keys(this.field.options.data[0])) {
        if(tdKey != "op_key" && tdKey != "op_value") {
          this.tdKeys.push(tdKey);
        }
      }
    }
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
  }

  setFormFieldValue(name, value) {
    // console.log("radioname", name, value);
    if(value != null) {
      this.field.value = value;
      this.form.controls[this.field.name].setValue(value);
    }

  }
  
  onRadioClick(e, options, option) {
    this.field.value = option[options.op_key];
    this.onValueChange.emit({ field: this.field, form: this.form, control: this.form.controls[this.field.name]});      
  }

  setFormFieldOptions(name, value, attrname = "data") {
    this.field.options[attrname] = value;
  }

  setFormFieldAttr(name, attr, value) {
    this.field[attr] = value;
  }

}
